/** Generic pagination info for RM APIs */
export interface PaginationInfo {
  per_page: number;
  page: number;
  previous: string | null;
  self: string;
  next: string | null;
  count?: number;
}

export interface ResponseBody<T> {
  data: T;
}

/** Generic paginated response body from the RM API */
export interface PaginatedResponseBody<T> extends ResponseBody<T> {
  paging: PaginationInfo;
}

/** Generic RM API error response body */
export interface ErrorResponseBody {
  message: string;
  errors?: string | string[];
}

/** Common pagination parameters for RM API */
export type PaginationParams = {
  page: number;
  perPage: number;
};

/** Pagination params when using react query hook (eg. useInfiniteQuery) */
export type ReactQueryPaginationParams = {
  pageParam: number;
  perPage: number;
};

/** Common filtering parameters for RM API */
export type FilterParams = {
  filterField?: number;
  filterList?: number;
};

/** Smartsheet destination for advanced reports */
export type AdvancedReportTarget = 'workspace' | 'folder';

export enum AdvancedReportStatus {
  Success = 'success',
  SOSCopySucceeded = 'sos_copy_succeeded',
  RefreshNeeded = 'refresh_needed',
  Updating = 'updating',
  ReportTooLarge = 'report_too_large',
  TooManyCells = 'too_many_cells',
  TooManyColumns = 'too_many_columns',
  TooManyRows = 'too_many_rows',
  SOSCopyFailed = 'sos_copy_failed',
  DSWorkflowNotCreated = 'ds_workflow_not_created',
  DSWorkflowRefreshFailed = 'ds_workflow_refresh_failed',
  DSWorkflowRefreshFailedColumnNameChange = 'ds_workflow_refresh_failed_column_name_change',
  ReportNoData = 'report_has_no_data',
  DSWorkflowFailedGridNotFound = 'ds_workflow_failed_grid_not_found',
  S3UploadFailed = 's3_upload_failed',
  S3UploadSucceeded = 's3_upload_succeeded',
  RefreshFailed = 'refresh_failed',
  GridUpdateFailed = 'grid_update_failed',
  UnknownError = 'unknown_error',
}

export interface AdvancedReport {
  id: number;
  organization_id: number;
  creator_id: number;
  column_mappings: unknown;
  saved_report_tag_id: number;
  smar_ds_workflow_id: unknown;
  smar_sheet_id: unknown;
  smar_report_id: string;
  smar_folder_id: unknown;
  auto_refresh: boolean;
  last_status: AdvancedReportStatus | null;
  last_refreshed_time: string;
  created_at: string;
  updated_at: string;
  result_url: string | null;
  result_url_last_refreshed: string | null;
  report_params: unknown;
}

export interface SavedView {
  id: string;
  name: string;
  namespace: string;
  value: string;
}

export interface ProjectImportPostResponse {
  message?: string;
  error?: string;
  status: unknown;
}

export const REPORT_REFRESH_STATUSES: `${AdvancedReportStatus}`[] =
  Object.values(AdvancedReportStatus);

export type ReportRefreshStatus = typeof REPORT_REFRESH_STATUSES[number];

export type ReportRefreshSuccessStatus = Extract<
  ReportRefreshStatus,
  'success' | 'sos_copy_succeeded'
>;
export type ReportRefreshLoadingStatus = Extract<
  ReportRefreshStatus,
  'updating'
>;
export type ReportRefreshWarningStatus = Extract<
  ReportRefreshStatus,
  'refresh_needed'
>;
export type ReportRefreshErrorStatus = Exclude<
  ReportRefreshStatus,
  | ReportRefreshSuccessStatus
  | ReportRefreshLoadingStatus
  | ReportRefreshWarningStatus
>;

export enum SchedulePivotType {
  CAPACITY = 'capacity',
  PEOPLE = 'people',
  PROJECTS = 'projects',
}
