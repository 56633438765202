export const baseURL = (hostname: string) => {
  let domain = '.com';
  if (hostname.includes('.eu')) {
    domain = '.eu';
  }
  if (hostname.includes('.smart.ninja')) {
    return `https://app.${hostname.split('.')[1]}.smart.ninja`;
  }

  if (hostname.includes('test.')) {
    return `https://app.test.smartsheet${domain}`;
  }

  if (hostname.includes('smargovstaging.')) {
    return `https://app.smargovstaging${domain}`;
  }

  if (hostname.includes('smartsheetgov.')) {
    return `https://app.smartsheetgov${domain}`;
  }

  return `https://app.smartsheet${domain}`;
};

export const csrfTokenTag = document.getElementsByName(
  'csrf-token'
)[0] as HTMLMetaElement;
export const csrfToken = csrfTokenTag?.content;

export const buildPayload = ({
  method,
  payload,
}: {
  method: string;
  payload?: unknown;
}) =>
  ({
    credentials: 'include',
    method,
    headers: {
      // eslint-disable-next-line quote-props
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
      'smar-xsrf-token': window.clientState?.sessionKey || undefined,
    },
    body: JSON.stringify(payload),
  } as RequestInit);
