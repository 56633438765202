{
  "err_activating_sso_auth": "Error al activar la autenticación de SSO",
  "err_company_name_length": "El nombre de la empresa debe tener menos de 255 caracteres.",
  "err_duplicate_value": "El valor ya existe.",
  "err_email_length": "El correo electrónico debe tener menos de 255 caracteres.",
  "err_failed_retrieve_initial_state_from_local_storage": "Se produjo un error al recuperar el estado inicial del almacenamiento local.",
  "err_fetching_filters": "Se produjo un error al obtener estas opciones de filtro.",
  "err_first_name_contains_url": "El nombre no puede contener una URL.",
  "err_first_name_length": "El nombre debe tener menos de 255 caracteres.",
  "err_global_modules_invalid_input": "La entrada no es válida.  Verifique que haya definido un nombre de módulo y que haya al menos una acción marcada.",
  "err_invalid_email": "Ingrese una dirección de correo electrónico válida.",
  "err_last_name_contains_url": "El apellido no puede contener una URL.",
  "err_last_name_length": "El apellido debe tener menos de 255 caracteres.",
  "err_no_status_for_status_panel": "Su equipo no definió ninguna opción de estado. Si desea comenzar a usar el estado, un administrador puede agregar opciones de estado en Configuración de la cuenta.",
  "err_password_length_40_chars": "Debe tener entre 8 y 40 caracteres.",
  "err_required": "Requerido",
  "err_unable_to_load_filter_list": "No se pudo cargar la lista de filtros",
  "err_unknown_error": "Error desconocido",
  "err_updating_smartsheet_auth": "Se produjo un error al actualizar la autenticación de Smartsheet.",
  "err_whoops_something_did_not_work_as_expected": "¡Oh, no! Algo no salió según lo previsto.",
  "lbl_about_fifteen_seconds": "aproximadamente 15 segundos",
  "lbl_about_thirty_seconds": "aproximadamente 30 segundos",
  "lbl_accept": "Aceptar",
  "lbl_account_switcher": "selector de cuentas",
  "lbl_activate": "Activar",
  "lbl_activated_auth_status": "Activada (cuenta creada con correo electrónico únicamente)",
  "lbl_active": "Activo",
  "lbl_activity": "Actividad",
  "lbl_actual_hours": "Horas reales",
  "lbl_add": "Agregar",
  "lbl_add_group_label_filter": "Agregar filtro de {{groupLabel}}",
  "lbl_assign_to_search_user": "Asignar a '{{searchInput}}'",
  "lbl_add_lc_members": "Agregar miembros",
  "lbl_add_members": "Agregar miembros",
  "lbl_add_person": "Agregar persona",
  "lbl_add_tasklist": "Agregar lista de tareas",
  "lbl_add_team_member": "Agregar miembro del equipo",
  "lbl_add_update_people": "Agregar/actualizar personas",
  "lbl_add_work_item": "Agregar elemento de trabajo",
  "lbl_added": "Agregado",
  "lbl_all": "Todo",
  "lbl_all_project_types": "Todos los tipos de proyectos",
  "lbl_allocation_heatmap": "Mapa de calor de las asignaciones",
  "lbl_allocation_percent": "Porcentaje de la asignación",
  "lbl_amounts": "Cantidades",
  "lbl_amounts_and_days": "Cantidades y días",
  "lbl_amounts_and_days_filter_display_name": "Tiempo y tarifas: Cantidades y días",
  "lbl_amounts_and_hours": "Cantidades y horas",
  "lbl_apply_changes": "Aplicar cambios",
  "lbl_approval_access_id": "Identificación de acceso de aprobación",
  "lbl_approval_status": "Estado de aprobación",
  "lbl_approvals": "Aprobaciones",
  "lbl_approvals_card_header_project_phase_client": "Proyecto: Fase - Cliente",
  "lbl_approve_and_lock": "Aprobar y bloquear",
  "lbl_approved": "Aprobado(a)",
  "lbl_approved_and_locked": "Aprobado y bloqueado",
  "lbl_approved_by": "Aprobada por",
  "lbl_approver": "Responsable de aprobación",
  "lbl_approver_view": "Vista del responsable de aprobación",
  "lbl_approvers": "Responsables de aprobación",
  "lbl_april": "Abril",
  "lbl_archive": "Archivar",
  "lbl_archive_projects": "Archivar proyectos",
  "lbl_archived": "Archivado",
  "lbl_archived_label": "etiqueta de archivado",
  "lbl_archived_on_date": "Archivado el {{archiveDate}}",
  "lbl_archiving": "Archivando",
  "lbl_assign": "Asignar",
  "lbl_assign_group_label": "Asignar {{groupLabel}}",
  "lbl_assign_team_member": "Asignar miembro del equipo",
  "lbl_assign_to": "Asignar a",
  "lbl_assignment": "Asignación",
  "lbl_assignment_dates_colon": "Fechas de asignación:",
  "lbl_assignment_details": "Detalles de la asignación",
  "lbl_assignment_name": "Nombre de la asignación",
  "lbl_assignment_status": "Estado de la asignación",
  "lbl_audit_log": "Registro de auditoría",
  "lbl_august": "Agosto",
  "lbl_authentication": "Autenticación",
  "lbl_author": "Autor",
  "lbl_automatic": "automático",
  "lbl_automatic_capitalize": "Automático",
  "lbl_availability": "Disponibilidad",
  "lbl_availability_sort_options": "Opciones de orden de disponibilidad",
  "lbl_available_hours": "Horas disponibles",
  "lbl_back": "Atrás",
  "lbl_back_to_account_settings": "Volver a la configuración de la cuenta",
  "lbl_back_to_list_of_requests": "Volver a la lista de solicitudes",
  "lbl_based_on": "Basado en",
  "lbl_bill_rate": "Tarifa de facturación",
  "lbl_billable": "Facturable",
  "lbl_bracket_lc_none": "[ninguno]",
  "lbl_bracket_leave": "[salir]",
  "lbl_bracket_no_approver_assigned": "[No se asignó un responsable de aprobación]",
  "lbl_bracket_non_phase_specific": "[No específico para una fase]",
  "lbl_bracket_none": "[Ninguno]",
  "lbl_brief": "Resumen",
  "lbl_budget": "Presupuesto",
  "lbl_budget_amounts_filter_display_name": "Presupuestos: Cantidades",
  "lbl_budget_days_filter_display_name": "Presupuestos: Días",
  "lbl_budget_hours_filter_display_name": "Presupuestos: Horas",
  "lbl_budget_report": "Informe de presupuesto",
  "lbl_budgets": "Presupuestos",
  "lbl_calculating": "calculando",
  "lbl_cancel": "Cancelar",
  "lbl_cancelled": "cancelado",
  "lbl_capacity": "Capacidad",
  "lbl_category": "Categoría",
  "lbl_cell_phone": "Teléfono celular",
  "lbl_certificate_details": "Detalles del certificado",
  "lbl_change_to_unmanaged_confirm": "Este usuario ya no podrá hacer un seguimiento del tiempo y los gastos, ni aprobarlos, y ya no se mostrará en la vista de capacidad ni en los informes.",
  "lbl_change_to_managed": "Cambiar a Gestionado",
  "lbl_change_to_unmanaged": "Cambiar a no administrado",
  "lbl_choose_file": "Elegir el archivo",
  "lbl_client": "Cliente",
  "lbl_client_name": "Nombre del cliente",
  "lbl_close": "Cerrar",
  "lbl_company_name": "Nombre de la empresa",
  "lbl_complete": "Completar",
  "lbl_confirm": "Confirmar",
  "lbl_confirmed": "Confirmado",
  "lbl_contact_info": "Información de contacto",
  "lbl_contact_support": "Contacte a Soporte",
  "lbl_contractor": "Contratista",
  "lbl_copy": "Copiar",
  "lbl_count_of_total": "{{count}} de {{total}}",
  "lbl_create": "Crear",
  "lbl_create_new_request": "*Crear nueva solicitud",
  "lbl_current": "Actual",
  "lbl_current_work": "Trabajo actual",
  "lbl_custom_date_range": "Intervalo de fechas personalizado",
  "lbl_custom_field": "Campo personalizado",
  "lbl_date": "Fecha",
  "lbl_date_string_at_time_string": "{{date}} a las {{time}}",
  "lbl_date_string": "Cadena de fecha",
  "lbl_dates": "Fechas",
  "lbl_dates_for_submitted_time": "Fechas del tiempo enviado",
  "lbl_day_friday": "Viernes",
  "lbl_day_monday": "Lunes",
  "lbl_day_saturday": "Sábado",
  "lbl_day_sunday": "Domingo",
  "lbl_day_thursday": "Jueves",
  "lbl_day_tuesday": "Martes",
  "lbl_day_wednesday": "Miércoles",
  "lbl_days": "Días",
  "lbl_deactivated": "Desactivado",
  "lbl_december": "Diciembre",
  "lbl_delete": "Eliminar",
  "lbl_delete_adv_report_modal": "¿Desea eliminar el informe?",
  "lbl_delete_saved_report_modal": "¿Desea eliminar el informe guardado?",
  "lbl_description": "Descripción",
  "lbl_difference": "Diferencia",
  "lbl_difference_from_past_scheduled": "Diferencia con el programado en el pasado",
  "lbl_disabled": "Deshabilitado",
  "lbl_discipline": "Disciplina",
  "lbl_display_colon": "Visualización:",
  "lbl_done": "Listo",
  "lbl_duration": "Duración",
  "lbl_duration_working_days": "Duración (días laborables)",
  "lbl_edit_profile": "Editar perfil",
  "lbl_edit_project_information": "Editar información del proyecto",
  "lbl_edit_projects": "Editar proyectos",
  "lbl_email": "Correo electrónico",
  "lbl_email_must_be_less_than_255_characters.": "El correo electrónico debe tener menos de 255 caracteres.",
  "lbl_employee_number": "Número de empleado",
  "lbl_enabled": "habilitado",
  "lbl_end": "Fin",
  "lbl_end_date": "Fecha de finalización",
  "lbl_end_lc_date": "Fecha de finalización",
  "lbl_ends_at": "Finaliza el",
  "lbl_enter_a_name": "Introduzca un nombre",
  "lbl_enter_rejection_reason": "Ingresar motivo del rechazo",
  "lbl_entry_type": "Tipo de entrada",
  "lbl_everyone": "Todos",
  "lbl_everything": "Todo",
  "lbl_expense": "Gasto",
  "lbl_expenses": "Gastos",
  "lbl_export": "Exportar",
  "lbl_export_people_list": "Exportar lista de personas",
  "lbl_export_report_to_csv": "Exportar este informe a un archivo CSV",
  "lbl_export_time_entries_for_quickBooks": "Exportar entradas de tiempo para QuickBooks",
  "lbl_export_underlying_data_as_csv": "Exportar datos subyacentes como archivo CSV",
  "lbl_failed": "Error",
  "lbl_february": "Febrero",
  "lbl_fees": "Tarifas",
  "lbl_filter": "Filtro",
  "lbl_filtered": "Filtrado",
  "lbl_filters": "Filtros",
  "lbl_first_day_of_work": "Primer día de trabajo",
  "lbl_first_group_by": "Primero, agrupar por",
  "lbl_first_name": "Nombre",
  "lbl_first_name_lc": "Nombre",
  "lbl_friday_abbr_uc": "V",
  "lbl_future": "Futuro",
  "lbl_future_scheduled": "futuras programadas",
  "lbl_global_modules": "Módulos globales",
  "lbl_grid_view": "Vista de cuadrícula",
  "lbl_hours": "Horas",
  "lbl_hours_filter_display_name": "Tiempo y tarifas: Horas",
  "lbl_hours_per_day": "Horas por día",
  "lbl_idp_metadata_url": "URL de metadatos de IdP",
  "lbl_iframe_view_content_require": "Para ver este contenido se requiere una licencia de Resource Management de Smartsheet.",
  "lbl_in_the_office": "En la oficina",
  "lbl_incurred": "incurridas",
  "lbl_internal": "Interno",
  "lbl_invited": "Invitado",
  "lbl_january": "Enero",
  "lbl_july": "Julio",
  "lbl_june": "Junio",
  "lbl_just_now": "Ahora",
  "lbl_last_and_next_ninety_days": "Último y próximos 90 días",
  "lbl_last_day_of_work": "Último día de trabajo",
  "lbl_last_month": "Mes anterior",
  "lbl_last_name": "Apellido",
  "lbl_last_name_lc": "Apellido",
  "lbl_last_quarter": "Último trimestre",
  "lbl_last_thirty_days": "Últimos 30 días",
  "lbl_last_week": "La semana pasada",
  "lbl_learn_lc_more": "Más información",
  "lbl_learn_more": "Más información",
  "lbl_leave": "Licencia",
  "lbl_leave_type": "Tipo de licencia",
  "lbl_licensed": "Con licencia",
  "lbl_licensed_users": "Usuarios con licencia",
  "lbl_linked_smartsheet_project": "Proyecto de Smartsheet vinculado",
  "lbl_loading": "Cargando",
  "lbl_loading_ellipsis": "Cargando...",
  "lbl_loading_project_ellipsis": "Cargando proyecto…",
  "lbl_location": "Ubicación",
  "lbl_log_requested": "Registro solicitado",
  "lbl_make_active": "Activar",
  "lbl_make_licensed_user": "Crear usuario con licencia",
  "lbl_manage_members": "Gestionar a los miembros",
  "lbl_managed_resource": "Recursos administrados",
  "lbl_manual": "manual",
  "lbl_manual_capitalize": "Manual",
  "lbl_march": "Marzo",
  "lbl_matching_people": "Personas coincidentes",
  "lbl_matching_person": "persona coincidente",
  "lbl_matching_placeholders": "Marcadores de posiciones coincidentes",
  "lbl_matching_project": "proyecto coincidente",
  "lbl_matching_projects": "proyectos coincidentes",
  "lbl_may": "Mayo",
  "lbl_medium": "Medio",
  "lbl_members": "Miembros",
  "lbl_mobile": "Móvil",
  "lbl_module": "Módulo:",
  "lbl_module_name": "Nombre del módulo",
  "lbl_monday_abbr_uc": "L",
  "lbl_month": "Mes",
  "lbl_more_assignments": "más asignaciones",
  "lbl_must_be_between_8–40_characters.": "Debe tener entre 8 y 40 caracteres.",
  "lbl_my_expenses": "Mis gastos",
  "lbl_my_projects": "Mis proyectos",
  "lbl_my_time": "Mi tiempo",
  "lbl_my_time_and_expenses": "Mi tiempo y mis gastos",
  "lbl_my_views": "Mis vistas",
  "lbl_new_project": "Nuevo proyecto",
  "lbl_next_ninety_days": "Próximos 90 días",
  "lbl_next_sixty_days": "Próximos 60 días",
  "lbl_next_thirty_days": "Próximos 30 días",
  "lbl_next_week": "Siguiente semana",
  "lbl_no_assignments": "Sin asignaciones",
  "lbl_no_description_provided": "No se proporcionó una descripción.",
  "lbl_no_imports_found": "No se encontraron importaciones",
  "lbl_non_billable": "No facturable",
  "lbl_none": "Ninguno",
  "lbl_non_matching": "No coincide",
  "lbl_not_using_smar": "No está usando la autenticación de Smartsheet",
  "lbl_not_using_sso": "No está usando SSO",
  "lbl_not_yet_invited": "Aún no recibió invitación",
  "lbl_notes": "Notas",
  "lbl_november": "Noviembre",
  "lbl_october": "Octubre",
  "lbl_office": "Oficina",
  "lbl_office_phone": "Teléfono de la oficina",
  "lbl_office_status": "Estado de presencia",
  "lbl_on_the_road": "En carretera",
  "lbl_or_select_a_specific_phase": "O seleccionar una fase específica",
  "lbl_or_select_phases": "O seleccionar la(s) fase(s)",
  "lbl_or_select_specific_phases": "O seleccionar fase(s) específica(s)",
  "lbl_other_projects": "Otros proyectos",
  "lbl_out_of_office": "Fuera de la oficina",
  "lbl_out_sick": "En licencia médica",
  "lbl_past": "Pasado",
  "lbl_pending": "Pendiente",
  "lbl_pending_approval": "Aprobación pendiente",
  "lbl_people": "Personas",
  "lbl_people_filters": "Filtros de personas",
  "lbl_people_scheduler": "Programador de personas",
  "lbl_people_tags": "Etiquetas de personas",
  "lbl_percentage": "Porcentaje",
  "lbl_persistent_navigation": "Navegación persistente",
  "lbl_person": "Persona",
  "lbl_phase": "Fase",
  "lbl_phase_dates": "Fechas de la fase",
  "lbl_phase_dates_colon": "Fechas de la fase:",
  "lbl_phase_four": "Fase cuatro",
  "lbl_phase_lc_name": "Nombre de la fase",
  "lbl_phase_name": "Nombre de la fase",
  "lbl_phase_name_long_enough_to_wrap": "Nombre de la fase con longitud suficiente para ajustarse automáticamente",
  "lbl_phase_no_assignments_worklist": "Esta fase no tiene asignaciones, pero aún aparecerá en su lista de trabajos, ya que tiene entradas de tiempo.",
  "lbl_phase_one": "Fase uno",
  "lbl_phase_status": "Estado de la fase",
  "lbl_phase_three": "Fase tres",
  "lbl_phase_two": "Fase dos",
  "lbl_phases_alert_add": "Las fases solo se pueden agregar desde su <0>hoja conectada</0>.",
  "lbl_phases_dates_edit": "Las fechas de las fases solo se pueden editar cambiando las fechas de sus asignaciones o desde su <0>hoja conectada</0>.",
  "lbl_phases_names_edit": "Los nombres de fases solo se pueden editar desde su <0>hoja conectada</0>.",
  "lbl_phases_delete": "Las fechas de las fases solo se pueden eliminar eliminando todas sus asignaciones o desde su <0>hoja conectada</0>.",
  "lbl_placeholder": "Marcador de posición",
  "lbl_placeholders": "Marcadores de posición",
  "lbl_please_sign_in": "Inicie sesión para continuar.",
  "lbl_plus_allocation": "+ Asignación",
  "lbl_portfolio_editor": "Editor de carteras",
  "lbl_portfolio_reporter": "Lector de informes de cartera",
  "lbl_portfolio_viewer": "Observador de carteras",
  "lbl_post": "Publicar",
  "lbl_project": "Proyecto",
  "lbl_project_access": "Acceso al proyecto",
  "lbl_project_code": "Código del proyecto",
  "lbl_project_dates": "Fechas del proyecto",
  "lbl_project_dates_colon": "Fechas del proyecto:",
  "lbl_project_description": "Descripción del proyecto",
  "lbl_project_duration": "Duración del proyecto",
  "lbl_project_editor": "Editor de proyectos",
  "lbl_project_filters": "Filtros de proyecto",
  "lbl_project_members": "Miembros del proyecto",
  "lbl_project_name": "Nombre del proyecto",
  "lbl_project_owner": "Propietario del proyecto",
  "lbl_project_owners": "Propietarios del proyecto",
  "lbl_project_portfolio": "Cartera de proyectos",
  "lbl_project_properties": "Propiedades del proyecto",
  "lbl_project_settings": "Configuración del proyecto",
  "lbl_project_state": "Estado del proyecto",
  "lbl_project_tags": "Etiquetas del proyecto",
  "lbl_project_title": "Título del proyecto",
  "lbl_project_type": "Tipo de proyecto",
  "lbl_projects": "Proyectos",
  "lbl_purchase_more": "comprar más",
  "lbl_recents": "Recientes",
  "lbl_recent_requests": "Solicitudes recientes",
  "lbl_refresh_status": "Actualizar estado",
  "lbl_refresh_type": "Actualizar tipo",
  "lbl_refreshing_data": "Actualizando datos...",
  "lbl_reject": "Rechazar",
  "lbl_rejected": "Rechazado",
  "lbl_remaining": "Restante",
  "lbl_remove": "Quitar",
  "lbl_report_data_refresh_status_error": "Error al actualizar los datos el {{date}} a las {{time}}",
  "lbl_report_data_refresh_status_error_please_try_again": "Se produjo un error al crear su informe avanzado. Inténtelo de nuevo. Si este problema continúa, contacte a soporte.",
  "lbl_report_data_refresh_status_loading": "Actualización del informe avanzado",
  "lbl_report_data_refresh_status_success": "Última actualización el {{date}} a las {{time}}",
  "lbl_report_data_refresh_status_warning_auto": "Los cambios se sincronizarán con la próxima actualización de datos",
  "lbl_report_data_refresh_status_warning_manual": "Actualizar informe avanzado para sincronizar los cambios",
  "lbl_report_name_colon": "Nombre del informe:",
  "lbl_report_title_by_criteria": "{{report}} por {{criteria}}",
  "lbl_reports": "Informes",
  "lbl_request_an_audit_log": "Solicitar un registro de auditoría",
  "lbl_request_audit_log": "Solicitar registro de auditoría",
  "lbl_requester": "Solicitante",
  "lbl_resend_invitation": "Reenviar invitación",
  "lbl_reset": "Restablecer",
  "lbl_reset_filters": "Restablecer filtros",
  "lbl_reset_password": "Restablecer contraseña",
  "lbl_reset_password_for": "Restablecer contraseña para",
  "lbl_reset_report": "Restablecer informe",
  "lbl_resource-request_test_page": "Página de prueba de solicitudes de recursos",
  "lbl_resource_management_by_smartsheet": "Resource Management de Smartsheet",
  "lbl_resourcing_admin": "Administrador de recursos",
  "lbl_retry": "Reintentar",
  "lbl_cannot_connect_to_smar": "No se pudo conectar el proyecto a Smartsheet.",
  "lbl_connecting_to_smar": "Conectando a Smartsheet",
  "lbl_close_banner": "Cerrar banner",
  "lbl_role": "Función",
  "lbl_saturday_abbr_uc": "S",
  "lbl_save": "Guardar",
  "lbl_save_changes": "Guardar cambios",
  "lbl_save_current_view": "Guardar vista actual",
  "lbl_save_this_report_to_your_personal_page": "Guardar este informe en su página personal",
  "lbl_saving": "Guardando",
  "lbl_schedule": "Programar",
  "lbl_scheduled": "Programado",
  "lbl_search": "Buscar",
  "lbl_search_ellipsis": "Buscar...",
  "lbl_select_date_range_to_see_entries": "Seleccionar un intervalo de fechas para ver todas las entradas dentro de ese plazo",
  "lbl_select_people_to_add_to_project": "Seleccione a las personas que le gustaría agregar a este proyecto.",
  "lbl_select_phases": "Seleccionar fase(s)",
  "lbl_select_project": "Seleccionar proyecto",
  "lbl_select_project_or_phase": "Seleccionar proyecto o fase",
  "lbl_select_project_owner": "Seleccionar propietario del proyecto",
  "lbl_select_team_member": "Seleccionar miembro del equipo:",
  "lbl_select_the_entire_project": "Seleccionar todo el proyecto",
  "lbl_send": "Enviar",
  "lbl_send_invitation": "Enviar invitación",
  "lbl_september": "Septiembre",
  "lbl_set_a_status": "Establecer un estado",
  "lbl_set_allocation": "Establecer asignación:",
  "lbl_set_to_today": "Establecer en hoy",
  "lbl_share": "Compartir",
  "lbl_shared_views": "Vistas compartidas",
  "lbl_show": "Mostrar",
  "lbl_show_no_phases": "Mostrar: Sin fases",
  "lbl_sign_out_slo_target_url": "URL de destino para cerrar sesión (SLO)",
  "lbl_signin_sso_target_url": "URL de destino para iniciar sesión (SSO)",
  "lbl_signup_password": "Contraseña (8 caracteres o más)",
  "lbl_skipped": "Omitido",
  "lbl_skipped_colon": "Omitidos: {{number}}",
  "lbl_small": "Pequeño",
  "lbl_something_went_wrong_ellipsis": "Se produjo un error.",
  "lbl_sort_by": "Ordenar por",
  "lbl_sso_for_resource_management": "SSO para Resource Management",
  "lbl_start": "Inicio",
  "lbl_start_date": "Fecha de inicio",
  "lbl_start_lc_date": "Fecha de inicio",
  "lbl_starts_at": "Comienza el",
  "lbl_status": "Estado",
  "lbl_status_cancelled": "CANCELADO",
  "lbl_status_failed": "ERROR",
  "lbl_status_message": "Mensaje de estado",
  "lbl_status_queued": "EN COLA",
  "lbl_status_running": "EN EJECUCIÓN",
  "lbl_status_succeeded": "CORRECTO",
  "lbl_submitted_time_for_approval": "Tiempo enviado para la aprobación",
  "lbl_sunday_abbr_uc": "D",
  "lbl_switch_to_another_account": "Cambiar a otra cuenta:",
  "lbl_tag": "Etiqueta",
  "lbl_tags": "Etiquetas",
  "lbl_target_utilization": "Utilización de objetivos",
  "lbl_tasks": "Tareas",
  "lbl_team_member": "Miembro del equipo",
  "lbl_team_member_type": "Tipo de miembro del equipo",
  "lbl_tentative": "Tentativo",
  "lbl_terms": "condiciones",
  "lbl_the_selected_project_was": "El proyecto seleccionado ha sido ",
  "lbl_then_group_by": "Luego, agrupar por",
  "lbl_these_results_only_include_projects_that_you_have_permission_to_see": "Estos resultados solo incluyen los proyectos en los que tiene permiso de visualización.",
  "lbl_this_month": "Este mes",
  "lbl_this_quarter": "Este trimestre",
  "lbl_this_week": "Esta semana",
  "lbl_this_year": "Este año",
  "lbl_thursday_abbr_uc": "J",
  "lbl_time": "Tiempo",
  "lbl_time_and_expenses": "Tiempo y gastos",
  "lbl_time_and_fee_categories": "Categorías de tiempo y tarifa",
  "lbl_time_and_fees": "Tiempo y tarifas",
  "lbl_time_and_fees_report": "Informe de tiempo y tarifas",
  "lbl_time_approval_dashboard": "Panel de aprobación de tiempo",
  "lbl_time_budget_unit_of_measurement": "Unidad de medida para el presupuesto de tiempo",
  "lbl_time_fees_amounts_and_hours_filter_display_name": "Tiempo y tarifas: Cantidades y horas",
  "lbl_time_fees_amounts_display_name": "Tiempo y tarifas: Cantidades",
  "lbl_time_fees_days_filter_display_name": "Tiempo y tarifas: Días",
  "lbl_time_frame": "Intervalo de tiempo",
  "lbl_time_tracking": "Seguimiento del tiempo",
  "lbl_today": "Hoy",
  "lbl_tomorrow": "Mañana",
  "lbl_total": "Total",
  "lbl_tuesday_abbr_uc": "M",
  "lbl_type": "Tipo",
  "lbl_unarchive": "Desarchivar",
  "lbl_unarchive_project": "Desarchivar proyecto",
  "lbl_unassigned": "Sin asignar",
  "lbl_unconfirmed": "Sin confirmar",
  "lbl_unmanaged": "No administrado",
  "lbl_unmanaged_resource": "Recurso no administrado",
  "lbl_unmanaged_resources": "Recursos no administrados",
  "lbl_unlock": "Desbloquear",
  "lbl_upcoming": "Próximamente",
  "lbl_update": "Actualizar",
  "lbl_update_status": "Actualizar estado: ",
  "lbl_updated": "Actualizado",
  "lbl_updating": "Actualizando",
  "lbl_utilization": "Utilización",
  "lbl_utilization_target": "Objetivo de utilización",
  "lbl_vacation": "Vacaciones",
  "lbl_values": "Valores",
  "lbl_view": "Ver",
  "lbl_view_resourcing_schedule": "Ver cronograma de recursos",
  "lbl_wednesday_abbr_uc": "M",
  "lbl_week": "Semana",
  "lbl_work": "Trabajo",
  "lbl_work_email": "Correo electrónico laboral",
  "lbl_work_item": "Elemento de trabajo",
  "lbl_work_status": "Estado laboral",
  "lbl_working_from_home": "Trabajo remoto",
  "lbl_worklist": "Lista de trabajo",
  "lbl_your_projects_includes_the_following_properties": "Sus proyectos: incluye las siguientes propiedades",
  "msg_account_owner_change_attempt": "Cambie el propietario de la cuenta en la configuración de la cuenta antes de archivar este usuario.",
  "msg_activated_smartsheet_auth": "Se activará la autenticación de Smartsheet. Este método de autenticación entra en vigencia de inmediato y no se puede desactivar",
  "msg_add_assignment_to_project": "Agregar asignación al proyecto",
  "msg_add_notes_placeholder": "Agregar notas, detalles, enlaces, etc.",
  "msg_add_phase_to_project": "Agregar fase al proyecto",
  "msg_add_project_description": "Agregar descripción del proyecto",
  "msg_add_save_current_view": "Agregar Guardar vista actual",
  "msg_add_team_member_to_project": "Agregar miembro del equipo al proyecto",
  "msg_app_project_members_disabled_tooltip": "Estos proyectos le pertenecen o se le asignaron.",
  "msg_app_project_members_enabled_tooltip": "Estos proyectos le pertenecen o se le asignaron, o usted es miembro de ellos.",
  "msg_audit_log_info": "Importante: Un registro de auditoría brinda un panorama general preciso en el momento en que se solicita y puede quedar desactualizado si se produce algún cambio en las entradas de tiempo o gastos desde el momento en que se generó el registro. Para obtener la información más actualizada, solicite un nuevo registro.",
  "msg_auto_provision_checkbox": "Cuando está activada la función Aprovisionamiento automático de usuarios, les asignará automáticamente a los usuarios las licencias y los niveles de permiso seleccionados. Esto también les permite a los usuarios evitar el proceso de invitación por correo electrónico.",
  "msg_bracket_search_to_add_filters": "[Buscar para agregar filtros]",
  "msg_cannot_archive_account_owner": "No puede archivar al propietario de la cuenta.",
  "msg_cert_requirement": "Use un archivo codificado en PEM con una extensión CER, CRT o PEM.",
  "msg_cert_upload_complete": "Carga de certificado completa",
  "msg_checkbox_list_aria": "Active o desactive la marca para seleccionar varios elementos o haga clic en el botón de selección para elegir solo uno",
  "msg_choose_permission_type": "Seleccionar tipo de permiso de usuario",
  "msg_choose_property_to_sort_by": "Seleccione qué propiedad tomar como base para ordenar los elementos.",
  "msg_close": "Cerrar",
  "msg_close_and_redirect_to_profile_page": "Cerrar y redireccionar a la página de perfil",
  "msg_count_matches_found": "{{count}} coincidencias encontradas",
  "msg_copy_url_to_share_report": "Copie la siguiente URL para compartir este informe:",
  "msg_customize_report_prompt": "Personalice su informe con las opciones que aparecen en la izquierda. Cuando esté listo, seleccione una de las siguientes opciones.",
  "msg_delete_adv_report_modal": "Esta acción hará que dejen de actualizarse los datos del informe, pero no eliminará el informe subyacente ni la hoja de origen en el espacio de trabajo de destino.",
  "msg_delete_assignment": "Eliminar asignación",
  "msg_delete_saved_report": "Eliminar informe guardado",
  "msg_delete_saved_report_modal": "Esta acción no se puede deshacer, pero puede crear un nuevo informe con las mismas opciones.",
  "msg_delete_view": "Eliminar vista",
  "msg_delete_work_item_warning": "¿Está seguro de que desea eliminar este elemento de trabajo de forma permanente? Se eliminarán todas las tareas y asignaciones asociadas. Esta acción no se puede deshacer.",
  "msg_edit_allocation": "Editar asignación",
  "msg_edit_end_date": "Editar fecha de finalización",
  "msg_edit_start_date": "Editar fecha de inicio",
  "msg_edit_status": "Editar estado",
  "msg_edit_team_member": "Editar miembro del equipo",
  "msg_edit_work_item": "Editar elemento de trabajo",
  "msg_enable_bulk_editing_requirements": "Para habilitar la edición masiva, asegúrese de que el filtro Propietario del proyecto está configurado con su nombre y que el filtro Estado del proyecto está configurado como Activo.",
  "msg_enable_module_for_existing_organizations": "Habilitar el módulo para las organizaciones existentes",
  "msg_enable_module_for_new_organizations": "Habilitar el módulo para las organizaciones nuevas",
  "msg_filter_is_locked_to_the_projects_you_have_permission_to_report_on": "Este filtro solo muestra los proyectos en los que tiene permiso para realizar un informe.",
  "msg_filter_is_locked_to_the_projects_you_own_or_are_assigned_to": "Este filtro solo muestra los proyectos que posee o que se le asignaron.",
  "msg_grid_view_aria": "Vista de cuadrícula: Active esta opción para seleccionar la vista de cuadrícula.",
  "msg_hold_on_loading_ellipsis": "Espere, se está cargando…",
  "msg_infinite_loader_aria_label": "Active o desactive la marca para seleccionar varios elementos o haga clic en el botón de selección para elegir solo uno",
  "msg_invalid_cert": "El certificado que seleccionó no es un tipo de archivo válido.",
  "msg_list_view_and_project_field_selector": "Vista de lista y selector de campos del proyecto: Active esta opción para seleccionar la vista de lista y seleccionar los campos del proyecto que estarán disponibles.",
  "msg_loading_approval_status": "Cargando estado de aprobación...",
  "msg_loading_page": "Cargando página",
  "msg_loading_project_timeline_ellipsis": "Cargando línea de tiempo del proyecto…",
  "msg_loading_project_users_ellipsis": "Cargando usuarios del proyecto…",
  "msg_make_bulk_project_archive_warning": "Si se archivan estos proyectos, finalizarán las asignaciones en curso y se eliminarán las futuras. Esta acción no se puede deshacer.",
  "msg_make_bulk_project_edit_confirmation": "¿Confirma que desea realizar este cambio?",
  "msg_make_bulk_project_edit_warning": "Esta acción puede demorar algunos minutos y no se puede deshacer.",
  "msg_manage_project_membership_and_project_access_levels_based_on_their_account_permissions": "Administre las membresías del grupo y los niveles de acceso al proyecto. Los niveles de acceso son exclusivos de este proyecto. Algunas personas pueden realizar ediciones sin una membresía según los <1>permisos de su cuenta</1>.",
  "msg_need_a_minute_ellipsis": "¡Oh, no! Necesitamos un minuto…",
  "msg_new_work_item_title": "Título del nuevo elemento de trabajo",
  "msg_no_assignments": "Sin asignaciones",
  "msg_no_matches_found": "No se hallaron coincidencias",
  "msg_no_people_assigned_to_project": "No hay personas asignadas a este proyecto.",
  "msg_no_phases_to_display": "No hay fases para mostrar",
  "msg_no_projects_match_your_criteria": "No hay proyectos que coincidan con sus criterios.",
  "msg_no_propertyies_to_display": "No hay propiedades para mostrar",
  "msg_no_recent_requests": "No hay solicitudes recientes",
  "msg_no_results_to_display": "No hay resultados que mostrar",
  "msg_no_work_for_this_project": "No hay un trabajo actual para este proyecto.",
  "msg_not_available": "no disponible",
  "msg_one_match_found": "Se encontró 1 coincidencia",
  "msg_owners_of_projects_permission_to_report_on": "Los propietarios de los proyectos en los que tiene permiso para realizar un informe.",
  "msg_people_field_selector": "Selector del campo de personas: Active esta opción para seleccionar los campos de datos que estarán disponibles.",
  "msg_people_settings_info": "Los usuarios con licencia pueden seguir el tiempo y las tareas en asignaciones programadas y, opcionalmente, se los puede invitar a iniciar sesión. Los recursos administrados se pueden programar en los proyectos, pero no pueden hacer un seguimiento del tiempo y no tienen acceso al inicio de sesión.",
  "msg_people_list_licensed_tooltip": "Según los permisos, los usuarios con licencia pueden hacer un seguimiento del tiempo y aprobarlo, planificar proyectos, gestionar la dotación de personal, editar perfiles de usuario y generar informes.",
  "msg_people_list_managed_tooltip": "Actualice los recursos administrados para obtener capacidades de seguimiento del tiempo.",
  "msg_people_list_unmanaged_tooltip": "Actualice los recursos no administrados para incluirlos en la vista de capacidad y en los informes.",
  "msg_people_list_archived_tooltip": "Las asignaciones de usuarios archivados permanecen en la página del proyecto para volver a asignarlas a otra persona o agregar un marcador de posición.",
  "msg_phase_picker_copy": "Copiar al proyecto o a la fase",
  "msg_print_brief_page": "Imprimir página de resumen",
  "msg_profile_banner": "Banner de perfil",
  "msg_profile_information": "Información de perfil",
  "msg_profile_navigation": "Navegación del perfil",
  "msg_profile_navigation_menu": "Menú de navegación del perfil",
  "msg_project_members_tooltip": "Estos proyectos le pertenecen o se le asignaron, o usted es miembro de ellos.",
  "msg_project_navigation": "Navegación del proyecto",
  "msg_project_members_description": "La membresía del proyecto es única para este proyecto. Algunos usuarios pueden acceder a este proyecto sin ser miembros en función de sus <0>permisos de cuenta</0>.",
  "msg_reassign_search_placeholder": "Buscar nombre, disciplina, etc.",
  "msg_refine_search_to_see_more_users": "Restringir la búsqueda para ver más usuarios que coincidan",
  "msg_rejection_reason_required": "Se requiere un motivo de rechazo.",
  "msg_remove_archived_project_state_to_enable_editing": "Elimine “Archivado” del filtro Estado del proyecto para habilitar la edición.",
  "msg_remove_assignment_confirmation": "¿Confirma que desea eliminar esta asignación?",
  "msg_report_data_refresh_err_detail_cell_limit_exceeded": "No pudimos actualizar los datos de su informe porque contiene 3000 celdas más que el límite. Reduzca el tamaño de su informe ajustando las opciones del panel izquierdo. <1>Más información.</1>",
  "msg_report_data_refresh_err_detail_generic": "Se produjo un error al actualizar los datos de su informe avanzado. Cree un nuevo informe avanzado.",
  "msg_report_data_refresh_err_detail_row_limit_exceeded": "No pudimos actualizar los datos de su informe porque contiene 300 filas más que el límite. Reduzca el tamaño de su informe ajustando las opciones del panel izquierdo. <1>Más información.</1>",
  "msg_report_data_refresh_err_detail_sheet_access": "Se produjo un error al actualizar los datos de su informe avanzado. Actualice los permisos de la hoja o cree un nuevo informe avanzado.",
  "msg_report_is_too_big_to_load": "Lamentablemente, el informe es demasiado grande y no se puede cargar.",
  "msg_save_report_reports_page": "Guardar este informe en la página Informes",
  "msg_search_contacts": "Busque contactos",
  "msg_settings_people_manage_info": "Administre el <1>acceso al inicio de sesión</1> y los <3>permisos</3> para las personas de su cuenta.<br /><5>Contáctenos aquí</5> para realizar cambios en su suscripción.",
  "msg_share_report": "Copie la siguiente URL con el botón Copiar para compartir este informe:",
  "msg_sheet_collaborators": "Los colaboradores de hojas en proyectos vinculados no se muestran aquí. Para actualizar el uso compartido de hojas o agregar colaboradores de hojas, adminístrelos en Smartsheet. <0>Más información</0>",
  "msg_signup_loading_transition": "Estamos configurando la cuenta de prueba gratuita.",
  "msg_sort_by_last_name": "Ordenar por: apellido",
  "msg_specify_budget_in_unit": "Especificar este presupuesto en {{curUnit}}",
  "msg_sso_configuration_info": "La configuración automática recomendada simplifica la configuración de SSO y es compatible con varios certificados de manera predeterminada. La configuración manual solo admite un certificado a la vez.",
  "msg_sso_enabled": "El SSO está activado en su cuenta",
  "msg_sso_info": "Usar el inicio de sesión único (SSO) para la gestión de contraseñas y el aprovisionamiento de usuarios permite que la compañía ejerza un mayor control en el acceso a Resource Management, lo cual garantiza que las personas adecuadas inicien sesión en el servicio de manera segura.",
  "msg_sso_login_failure": "No pudimos iniciar sesión en Resource Management de Smartsheet.",
  "msg_sso_not_configured": "El SSO no está configurado en su cuenta",
  "msg_sso_unconfigurable": "No es posible configurar el SSO directamente en Resource Management porque su organización está usando la autenticación de Smartsheet en este momento. La configuración del SSO puede gestionarse en el Centro de Administración de Smartsheet.",
  "msg_terms": "Condiciones",
  "msg_thanks": "¡Gracias!",
  "msg_there_are_no_approvals_matching_your_current_filters": "No hay aprobaciones que coincidan con los filtros actuales.",
  "msg_to_inactive_project": "a proyecto inactivo",
  "msg_transfer_project_ownership_to_remove_user": "{{member.name}} es el propietario del proyecto y no se puede eliminar. Transfiera la propiedad en la pestaña Configuración del proyecto.",
  "msg_type_and_press_enter_to_add_task": "Escriba y presione Intro para agregar la tarea",
  "msg_cant_edit_remove_unmanaged_resource": "{{member.name}} es un recurso no administrado. Actualícelo en Configuración de la cuenta para editarlo o eliminarlo de este proyecto.",
  "msg_cant_add_unmanaged_resource": "{{member.name}} es un recurso no administrado. Actualícelo en Configuración de la cuenta para agregarlo a este proyecto.",
  "msg_upload_failed": "Error en la carga",
  "msg_upload_succeeded": "Carga correcta",
  "msg_user_default_login_tooltip": "Esta persona todavía no inició sesión con SSO. Para cambiar, pídale que cierre sesión y vuelva a iniciar sesión con SSO.",
  "msg_user_smar_login_tooltip": "Esta persona todavía no inició sesión con la autenticación de Smartsheet. Para cambiar, pídale que cierre sesión y vuelva a iniciar sesión con la autenticación de Smartsheet.",
  "msg_wait_while_load_assignments": "Espere mientras cargamos las asignaciones",
  "msg_welcome_to_resource_management_by_smartsheet": "Le damos la bienvenida a Resource Management de Smartsheet.",
  "msg_work_item_description_placeholder": "Describa el trabajo, la asignación, etc.",
  "msg_you_dont_own_these_projects_and_theyre_not_assigned_to_you": "Estos proyectos no le pertenecen ni se le asignaron.",
  "msg_saved_form_already_exists": "ya existe. ¿Le gustaría reemplazarlo?",
  "msg_you_do_not_have_admin_access": "Asegúrese de tener permiso de administrador en el espacio de trabajo que está buscando. Puede solicitar acceso o crear un nuevo espacio de trabajo de destino en <0>Smartsheet</0>.",
  "msg_no_matching_results": "No hay resultados que coincidan",
  "msg_report_filter_list": "Se encuentra en la lista Filtro de informes. Seleccione los datos que desea incluir en su informe.",
  "msg_report_filter_show_group": "Se encuentra en el grupo Mostrar filtro de informes. Seleccione las opciones de cada categoría que quiera mostrar; para ello, elija todas o ninguna, marque o desmarque las casillas para seleccionar varias opciones o active un botón de unión para elegir una sola.",
  "lbl_getting_information": "Obteniendo información",
  "lbl_actions": "Acciones",
  "lbl_delete_phase": "¿Eliminar la etapa",
  "lbl_chars_left": "restante",
  "lbl_set_to": "Establecido en",
  "msg_no_ppl_match": "No hay personas que coincidan con su criterio",
  "lbl_user_info_summary": "Resumen de información del usuario",
  "lbl_saved_reports": "Informes guardados",
  "lbl_no_active_assignments": "No hay asignaciones activas.",
  "msg_entries_not_shown": "No se muestran las entradas para los proyectos de los cuales no es propietario",
  "msg_provide_note": "Escriba una nota sobre esta aprobación. Es obligatorio para el rechazo",
  "msg_taking_longer_than_expected": "La acción está tardando más de lo previsto. Espere un momento",
  "msg_want_to_make_adjustments": "¿Desea hacer ajustes o acelerar las cosas? Puede seguir usando los filtros para refinar o limitar el informe.",
  "msg_calculating_time_entries": "Calculando ~{{timeEntriesCount}} entradas de tiempo.",
  "lbl_just_a_few_seconds": "solo unos segundos",
  "lbl_less_than_a_min": "menos de un minuto",
  "lbl_about_a_min": "alrededor de un minuto",
  "msg_add_users_intro": "Utilice esta página para agregar personas nuevas o actualizar las personas existentes de su cuenta.",
  "msg_add_projects_intro": "Utilice esta página para agregar proyectos nuevos o actualizar los proyectos existentes de su cuenta.",
  "msg_more_details_can_be_found": "Puede encontrar más información en nuestro <0>tema de ayuda</0>. ¿Tiene preguntas? <1>Comuníquese</1> con nuestro equipo de soporte.",
  "lbl_step_1_prepare_your_data": "1. Preparar los datos",
  "lbl_step_1_content_users": "Nota: No edite la columna Id. de usuario; se utiliza para vincular actualizaciones con personas existentes.",
  "lbl_step_1_content_projects": "Nota: No edite la columna Id. de proyecto; se utiliza para vincular actualizaciones con proyectos existentes.",
  "lbl_step_1_contents_create_users": "Utilice esta plantilla para agregar nuevas personas a su cuenta.",
  "lbl_step_1_contents_create_projects": "Utilice esta plantilla para agregar nuevos proyectos a su cuenta.",
  "lbl_step_1_contents_edit_users": "Exporte una lista de todas las personas de su organización para actualizar sus atributos.",
  "lbl_step_1_contents_edit_projects": "Exporte una lista de todos los proyectos de su organización para actualizar sus atributos.",
  "lbl_people_lc": "personas",
  "lbl_projects_lc": "proyectos",
  "lbl_add_update_projects": "Agregar/actualizar proyectos",
  "lbl_download_template": "Descargar plantilla",
  "lbl_update_existing": "Actualizar existente",
  "lbl_export_project_list": "Exportar lista de proyectos",
  "lbl_step_2_upload_data_users": "2. Cargar la lista de personas actualizada",
  "lbl_step_2_upload_data_projects": "2. Cargar la lista de proyectos actualizada",
  "msg_select_csv_file": "Seleccione y cargue su archivo CSV actualizado para agregar o actualizar {{importType}}. Recibirá una confirmación por correo electrónico cuando se haya completado el proceso.",
  "lbl_send_invitation_new_licensed_users": "Envíe una invitación a usuarios con licencia nuevos.",
  "lbl_upload": "Cargar",
  "lbl_processing": "Procesando...",
  "lbl_add_new": "Agregar {{importType}} nuevo",
  "msg_import_in_progress": "Importación en curso",
  "msg_errors_during_import": "Errores detectados durante la importación",
  "lbl_import_complete": "Importación completa",
  "lbl_added_colon": "Agregados: {{number}}",
  "lbl_updated_colon": "Actualizados: {{number}}",
  "lbl_failed_colon": "Con error: {{number}}",
  "lbl_download_error_report_csv": "Descargar informe de errores en formato CSV",
  "msg_working_on_import_background": "Estamos trabajando en esta importación en segundo plano. Recibirá un correo electrónico cuando la importación se haya completado. Puede encontrar un enlace a esta página en la parte inferior de la página <0>{{pageTitle}}</0>.",
  "msg_rows_with_errors": "No se agregaron ni actualizaron las filas con errores. Puede descargar un informe CSV con estos errores para corregirlos y volver a intentarlo. Puede haber más errores; verifique todos los datos antes de volver a cargar el archivo. <0>Obtenga más información</0> sobre la importación y actualización de {{importType}}.",
  "msg_completed_processing": "Terminamos de procesar el {{importType}} que agregó y actualizó recientemente en su organización.",
  "lbl_imports_last_30_days": "Importaciones de los últimos 30 días",
  "lbl_characters": "caracteres ",
  "lbl_character": "carácter ",
  "lbl_remaining_lc": "restantes",
  "msg_no_items_match_criteria": "No hay elementos que coincidan con su criterio",
  "lbl_future_scheduled_lc": "cantidad futura programada",
  "lbl_loading_details": "Cargando detalles",
  "lbl_try_again": "Vuelva a intentarlo",
  "err_must_not_be_blank": "No debe estar en blanco",
  "err_cannot_be_more_than_255_char": "No puede tener más de 255 caracteres",
  "lbl_view_name": "Nombre de la vista",
  "lbl_project_timelines": "Cronogramas del proyecto",
  "msg_shorten_time_frame": "Acorte el intervalo de tiempo o seleccione menos personas o proyectos en el filtro. Luego, intente volver a cargar el informe. O bien, pida que le envíen un correo electrónico con un enlace al archivo CSV de este informe.",
  "msg_underlying_data_requested": "Datos subyacentes solicitados. Le enviaremos un correo electrónico cuando esté listo para la descarga. Esto puede demorar un poco.",
  "msg_no_report_values": "No hay valores para ese informe.",
  "msg_report_only_includes_data": "Este informe solo incluye datos para los que tiene permiso de visualización.",
  "msg_we_have_logged_error": "Registramos el error y lo analizaremos. Mientras tanto, pruebe empezar de nuevo:",
  "msg_budgets_report_includes_all": "Este informe incluye todos los datos de cantidades incurridas, futuras programadas y presupuestadas para la duración de los proyectos que tienen fechas superpuestas.",
  "lbl_run_report": "Ejecutar informe",
  "lbl_request_underlying_data": "Solicitar datos subyacentes",
  "lbl_selected": "Seleccionado",
  "lbl_selected_lc": "seleccionado",
  "lbl_project_lc": "proyecto",
  "msg_change_client_name": "Esto cambiará el cliente para",
  "msg_change_project_type": "Esto cambiará el tipo de proyecto para",
  "msg_change_project_owner": "Esto cambiará el propietario del proyecto para",
  "msg_change_tags": "Esto cambiará las etiquetas para",
  "msg_change_custom_fields": "Esto cambiará los campos personalizados para",
  "msg_this_will_archive_the": "Esto archivará el",
  "msg_this_will_add_selected_tags": "Esto agregará las etiquetas seleccionadas",
  "msg_this_will_remove_selected_tags": "Esto eliminará las etiquetas seleccionadas",
  "msg_updating_client": "Actualizando cliente para",
  "msg_updating_project_owner": "Actualizando propietario de proyecto para",
  "msg_describe_work_item_placeholder": "Describa el trabajo, resultado o entregable",
  "msg_show_hide_phase_items": "Mostrar/ocultar los elementos de trabajo de la fase {{phaseName}}",
  "msg_edit_phase": "Editar fase {{phaseName}}",
  "msg_show_hide_project_items": "Mostrar/ocultar los elementos de trabajo del proyecto {{projectName}}",
  "msg_edit_project": "Editar proyecto {{projectName}}",
  "lbl_hours_lc": "horas",
  "lbl_hours_per_day_abbr": "h/d",
  "lbl_new": "Nuevo",
  "lbl_add_team_member_to": "Agregar miembro del equipo a {{parentLabel}}",
  "lbl_add_work_item_to": "Agregar elemento de trabajo a {{parentLabel}}",
  "lbl_available_hours_lc": "horas disponibles",
  "lbl_remaining_or_budget_forecast": "restantes o {{budgetForecastPercent}}",
  "lbl_forecasted_over_or_under_budget": "presupuesto previsto de {{overUnder}} o {{budgetForecastPercent}}",
  "lbl_under_lc": "por debajo de",
  "lbl_over_lc": "por encima de",
  "lbl_expense_budget_lc": "presupuesto de gastos",
  "lbl_project_budget_lc": "presupuesto del proyecto",
  "lbl_expensed_lc": "cantidad gastada",
  "lbl_incurred_lc": "cantidad incurrida",
  "lbl_add_description": "Agregar descripción",
  "lbl_automatic_configuration": "Configuración automática",
  "lbl_recommended_paren": "(Recomendado)",
  "lbl_manual_configuration": "Configuración manual",
  "lbl_manage_sheet_sharing": "Administrar uso compartido de hojas",
  "lbl_cert_must_be_PEM": "El certificado tiene que estar codificado en PEM (es decir, archivos pem, .cert, .crt, .cer).",
  "msg_auto_provision_auth_users": "Usuarios autenticados por aprovisionamiento automático que no están en la cuenta (creados solo con correo electrónico)",
  "lbl_selected_phases": "Usar fases seleccionadas",
  "lbl_no_display_props": "No hay propiedades para mostrar",
  "msg_approval_audit_error": "Se produjo un error inesperado. Si el problema persiste, contacte a Soporte con los detalles del caso a ",
  "msg_download_error": "Se produjo un error al intentar descargar el archivo: ",
  "lbl_audit_log_req": "Registro de auditoría solicitado",
  "msg_audit_log_req": "Aparecerá un enlace de descarga en la tabla de solicitudes recientes a continuación. Esto puede demorar unos minutos.",
  "lbl_audit_log_expense": "Registros de auditoría de gastos",
  "lbl_download": "Descargar",
  "lbl_proj_singular": "el proyecto fue",
  "lbl_proj_plural": "los proyectos fueron",
  "lbl_zero_proj": "0 proyectos",
  "lbl_success_lc": "correcto",
  "lbl_important": "<1>Importante:</1>",
  "lbl_edit_properties": "Editar propiedades",
  "lbl_error": "Error",
  "lbl_change": "Cambiar",
  "lbl_select_new": "Seleccionar nuevo",
  "lbl_approver_project": "Responsable de aprobación: ",
  "lbl_successfully": " correctamente.",
  "msg_update": "Actualización completa",
  "lbl_of": "de",
  "msg_update_failed": "No se pudo actualizar.",
  "msg_update_lc": "se actualizó correctamente.",
  "lbl_edit_desc": "Editar descripción",
  "lbl_no_select": "no hay nada seleccionado",
  "lbl_current_filter_select": " : Configurado actualmente como ",
  "msg_getting_ready": "Preparando…",
  "msg_retry_update": "Haga clic en Reintentar para intentar solucionar las actualizaciones con errores.",
  "msg_error_support": "Si continúa viendo este error, contacte a Soporte. ",
  "lbl_curr_dropdown_val": "El valor desplegable actual es ",
  "msg_cancel_view": "Cancelar y volver a la lista de vistas",
  "msg_updating_tags": "Actualizando etiquetas para",
  "msg_updatings_proj_type": "Actualizando tipo de proyecto para",
  "msg_start_your_trial_for_free": "Comience su prueba gratuita de {{trialDuration}} días",
  "msg_create_free_account": "Crear mi cuenta gratuita",
  "msg_I_agree_to_terms_and_conditions": "Acepto las <0>Condiciones</0> y comprendo que Resource Management de Smartsheet procesará mi información personal de acuerdo con el Aviso de privacidad (<1>Política de privacidad</1>).",
  "msg_something_went_wrong_signup": "¡Vaya! Surgió algún error al intentar registrarlo. Contáctenos mediante nuestra <0>página de Soporte</0>",
  "msg_edit_phase_settings": "Editar configuración de la fase",
  "msg_edit_project_settings": "Editar la configuración del proyecto",
  "msg_shift_phase_dates": "Cambiar las fechas de la fase",
  "msg_shift_project_dates": "Cambiar las fechas del proyecto",
  "lbl_utilization_lc": "utilización",
  "lbl_target_utilization_lc": "utilización de objetivos",
  "msg_organization_using_smar_auth_modifification_info": "Actualmente, su organización está utilizando la autenticación de Smartsheet para iniciar sesión en Resource Management. Para modificar esta configuración, <1>contacte a Soporte</1>",
  "lbl_user_auto_provisioning": "Aprovisionamiento automático de usuarios",
  "lbl_edit": "Editar",
  "lbl_permission_level": "Nivel de permiso",
  "msg_activate_smar_auth_info": "Active esta opción para permitir que los usuarios inicien sesión con su cuenta de Smartsheet. <1>Más información</1>.",
  "lbl_activate_smar_auth": "Activar autenticación de Smartsheet",
  "msg_please_link_rm_account_with_smar": "Para permitir la autenticación de Smartsheet, <1>vincule su cuenta de Resource Management con su cuenta de Smartsheet.</1>",
  "lbl_setup_sso": "Configurar SSO",
  "lbl_smartsheet_authentication": "Autenticación de Smartsheet",
  "lbl_edit_sso": "Editar configuración de SSO",
  "msg_disabled_filter_info": "Esta opción no está disponible para los informes avanzados. Cree un <0>nuevo informe</0> para acceder a esta opción.",
  "msg_people_in_org_have_logged_in_using_sso": "Las personas de la organización ya iniciaron sesión con SSO. Editar la configuración de SSO de forma incorrecta podría impedir que los usuarios inicien sesión. Para actualizar la configuración de SSO de manera segura, <1>contáctenos</1> para recibir soporte técnico.",
  "err_issue_enabling_sso": "Ocurrió un problema al habilitar la autenticación de SSO: {{error}}. Si este problema persiste, contacte a Soporte.",
  "err_activating_smar_auth": "Error al activar la autenticación de Smartsheet",
  "err_issue_updating_smar_auth": "Ocurrió un problema al actualizar la autenticación de Smartsheet: {{error}}. Si este problema persiste, contacte a Soporte.",
  "err_issue_enabling_smar_auth": "Ocurrió un problema al habilitar la autenticación de Smartsheet: {{error}}. Si este problema persiste, contacte a Soporte.",
  "msg_report_unmanaged_resources_admin": "Para incluir recursos no administrados en este informe, actualícelos en <0>Configuración de la cuenta</0>.",
  "msg_report_unmanaged_resources": "Para incluir recursos no administrados en este informe, solicite al administrador que los actualice en Configuración de la cuenta.",
  "lbl_adjust_refresh_settings": "Ajustar la configuración de actualización de datos",
  "lbl_view_advanced_report": "Ver informe avanzado",
  "lbl_save_and_refresh_data": "Guardar cambios y actualizar datos",
  "lbl_refresh_data": "Actualizar datos",
  "lbl_delete_report": "Eliminar informe",
  "lbl_toggle_reports_control_menu": "Alternar el menú de control de informes",
  "lbl_advanced_report": "Informe avanzado",
  "lbl_create_new_advanced_report": "Crear nuevo informe avanzado",
  "msg_open_the_advanced_report_in_smartsheet": "Abrir el informe avanzado en Smartsheet",
  "lbl_advanced_report_edit_modal_title": "Configuración de actualización de datos",
  "lbl_advanced_report_modal_title": "Crear informe avanzado",
  "msg_advanced_report_modal_description": "Envíe sus datos a una hoja de origen y a un informe de Smartsheet. Puede actualizar el informe y ajustar la configuración desde la página Informes.",
  "lbl_advanced_report_modal_name_field": "Nombre",
  "lbl_advanced_report_modal_destination_field": "Destino",
  "lbl_advanced_report_modal_data_refresh_field": "Configuración de actualización de datos",
  "lbl_advanced_report_modal_data_refresh_auto_name": "Automática",
  "lbl_advanced_report_modal_data_refresh_auto_description": "Los datos del informe avanzado se actualizarán cada 24 horas",
  "lbl_advanced_report_modal_data_refresh_manual_name": "Manual",
  "lbl_advanced_report_modal_data_refresh_manual_description": "Los datos deben actualizarse de forma manual desde la página Informes",
  "lbl_advanced_report_modal_close_button": "Cerrar",
  "lbl_advanced_report_modal_create_button": "Crear",
  "msg_advanced_report_modal_need_lws": "Solicite al administrador de asignaciones de recursos que active la autenticación de Smartsheet para usar esta función.",
  "lbl_destination_dropdown_select_workspace": "Seleccionar un espacio de trabajo",
  "lbl_destination_dropdown_search_for_workspace": "Buscar un espacio de trabajo",
  "msg_advanced_report_ready_title": "¡Su informe avanzado está listo!",
  "msg_advanced_report_ready": "Siempre puede acceder a su informe avanzado y ver el estado de este desde la página Informes",
  "lbl_advanced_report_view_btn": "Ver informe avanzado",
  "msg_advanced_report_creating_title": "Crear su informe avanzado",
  "msg_advanced_report_creating": "Esto puede demorar unos minutos. Puede obtener una vista previa del informe con datos de ejemplo ahora y sus datos reales los reemplazarán una vez que estén listos.",
  "lbl_advanced_report_preview_btn": "Vista previa del informe…",
  "msg_advanced_report_creation_footer": "Puede cerrar con seguridad. Puede regresar más tarde para encontrarlo en sus informes guardados o en el espacio de trabajo seleccionado.",
  "lbl_advanced_report_creation_footer_link": "Explore consejos sobre informes avanzados.",
  "lbl_create_advanced_report": "Crear informe avanzado",
  "lbl_edit_settings": "Editar configuración del informe",
  "err_create_report_failed_generic": "Se produjo un error al crear su informe. Inténtelo de nuevo.",
  "err_edit_report_failed_generic": "Se produjo un error al editar su informe. Inténtelo de nuevo.",
  "err_create_report_failed_invalid_name": "El nombre del informe no debe contener caracteres especiales ni emojis",
  "msg_session_expired_title": "Su sesión caducó",
  "msg_session_expired_description": "Su sesión en esta ventana ya no es válida. Haga clic en el siguiente botón para actualizar la página. Es posible que deba volver a iniciar sesión.",
  "lbl_refresh_page": "Actualizar página",
  "msg_refresh_advanced_report": "Actualice los datos desde el menú para aplicar sus filtros al informe avanzado. También se actualizará su informe de RM guardado.",
  "msg_advanced_report_too_many_cells": "No se pudo actualizar este informe porque tiene 500 000 celdas o más. Ajuste la configuración en el panel de la izquierda para reducir la cantidad de celdas. <0>Más información</0>",
  "msg_advanced_report_too_many_columns": "No se pudo actualizar este informe porque tiene 400 columnas o más. Ajuste la configuración en el panel de la izquierda para reducir la cantidad de columnas. <0>Más información</0>",
  "msg_advanced_report_too_many_rows": "No se pudo actualizar este informe porque tiene 20 000 filas o más. Ajuste la configuración en el panel de la izquierda para reducir la cantidad de filas. <0>Más información</0>",
  "msg_advanced_report_auto_refresh_disabled": "Si la configuración de actualización de este informe está establecida como automática, se cambiará a manual para evitar que se produzcan más actualizaciones fallidas.",
  "lbl_link_to_smartsheet": "Enlace a Smartsheet",
  "msg_link_to_smartsheet_tooltip": "Solicite al administrador de asignaciones de recursos que active la autenticación de Smartsheet para usar esta función. <0>Más información</0>",
  "msg_new_link_to_smartsheet": "Cree una nueva hoja vinculada con todos los datos de su proyecto de Resource Management.",
  "msg_connect_to_smartsheet": "Al conectar su proyecto, se creará una nueva hoja conectada con los datos de su proyecto existente para que pueda aprovechar las potentes funciones de gestión de proyectos de Smartsheet. <0>Más información</0>",
  "lbl_connect": "Conectar",
  "lbl_add_phase_col": "Agregar columna de fase",
  "err_connect_sheet_to_proj_error": "No se pudo conectar el proyecto. Vuelva a intentarlo más tarde.",
  "msg_project_has_phases": "Su proyecto ya tiene fases. Se agregarán a la nueva hoja conectada.",
  "msg_adding_phases": "Agregar una columna de fase le permitirá dividir su proyecto en diferentes fases o etapas. <0>Más información</0>",
  "msg_connecting_proj_to_sheet": "Conectar su proyecto a una hoja",
  "msg_be_patient": "Tenga paciencia, esto puede demorar unos minutos.",
  "msg_safe_to_close": "Puede cerrar con seguridad. Puede volver en cualquier momento para comprobar el estado de la conexión del proyecto.",
  "lbl_open_sheet": "Abrir hoja",
  "lbl_sheet_connected": "¡Su hoja conectada está lista!",
  "msg_sheet_connected": "Siempre puede encontrar un enlace a la hoja conectada en el resumen del proyecto.",
  "msg_enforce_sign_in_with_smartsheet_title": "Ahora puede iniciar sesión con Smartsheet",
  "msg_enforce_sign_in_with_smartsheet_heading": "Se actualizó el método de inicio de sesión de su organización.",
  "msg_enforce_sign_in_with_smartsheet_text": "Ahora puede iniciar sesión con la autenticación de Smartsheet, una forma sencilla de acceder a Resource Management en Smartsheet y a nuevas características, como los informes avanzados. <0>Más información</0>",
  "msg_enforce_sign_in_with_smartsheet_create_account_text": "Comience con su correo electrónico o <0>cree una cuenta de Smartsheet</0> si aún no tiene una.",
  "msg_enforce_sign_in_with_smartsheet_skip_for_now": "Omitir por ahora",
  "msg_enforce_sign_in_with_smartsheet_sign_in_with_smartsheet": "Iniciar sesión con Smartsheet"
}