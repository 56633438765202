{
  "err_activating_sso_auth": "Ошибка при попытке активировать аутентификацию с помощью службы единого входа",
  "err_company_name_length": "Название компании должно содержать не более 255 символов.",
  "err_duplicate_value": "Такое значение уже существует",
  "err_email_length": "Адрес эл. почты должен содержать не более 255 символов.",
  "err_failed_retrieve_initial_state_from_local_storage": "Не удалось извлечь исходное состояние из localStorage",
  "err_fetching_filters": "При попытке выбрать эти параметры фильтра возникла проблема.",
  "err_first_name_contains_url": "Имя не должно содержать URL-адрес.",
  "err_first_name_length": "Имя должно содержать не более 255 символов.",
  "err_global_modules_invalid_input": "Введены недопустимые данные.  Проверьте, задано ли название модуля и выбрано ли по крайней мере одно действие.",
  "err_invalid_email": "Введите действительный адрес электронной почты",
  "err_last_name_contains_url": "Фамилия не должна содержать URL-адрес.",
  "err_last_name_length": "Фамилия должна содержать не более 255 символов.",
  "err_no_status_for_status_panel": "У ваших сотрудников отсутствуют параметры статуса. Если вы хотите воспользоваться этой функцией, попросите администратора добавить параметры статуса в настройках учётной записи.",
  "err_password_length_40_chars": "Необходимо ввести от 8 до 40 символов.",
  "err_required": "Обязательно",
  "err_unable_to_load_filter_list": "Не удалось загрузить список фильтров",
  "err_unknown_error": "Неизвестная ошибка",
  "err_updating_smartsheet_auth": "При попытке обновить проверку подлинности Smartsheet произошла ошибка",
  "err_whoops_something_did_not_work_as_expected": "К сожалению, что-то пошло не так.",
  "lbl_about_fifteen_seconds": "примерно 15 секунд",
  "lbl_about_thirty_seconds": "примерно 30 секунд",
  "lbl_accept": "Принять",
  "lbl_account_switcher": "переключатель учётных записей",
  "lbl_activate": "Активировать",
  "lbl_activated_auth_status": "Активирована (при создании учётной записи использовался только адрес электронной почты)",
  "lbl_active": "Активная",
  "lbl_activity": "Действие",
  "lbl_actual_hours": "Фактически затраченные часы",
  "lbl_add": "Добавить",
  "lbl_add_group_label_filter": "Добавить фильтр {{groupLabel}}",
  "lbl_assign_to_search_user": "Назначить \"{{searchInput}}\"",
  "lbl_add_lc_members": "Добавить участников",
  "lbl_add_members": "Добавить участников",
  "lbl_add_person": "Добавить пользователя",
  "lbl_add_tasklist": "Добавить список задач",
  "lbl_add_team_member": "Добавить участника команды",
  "lbl_add_update_people": "Добавить или обновить пользователей",
  "lbl_add_work_item": "Добавить рабочий элемент",
  "lbl_added": "Добавлено",
  "lbl_all": "Все",
  "lbl_all_project_types": "Все типы проектов",
  "lbl_allocation_heatmap": "Тепловая карта распределения ресурсов",
  "lbl_allocation_percent": "Процент распределения",
  "lbl_amounts": "Суммы",
  "lbl_amounts_and_days": "Суммы и дни",
  "lbl_amounts_and_days_filter_display_name": "Время и оплата: суммы и дни",
  "lbl_amounts_and_hours": "Суммы и часы",
  "lbl_apply_changes": "Применить изменения",
  "lbl_approval_access_id": "Код доступа для утверждения",
  "lbl_approval_status": "Состояние утверждения",
  "lbl_approvals": "Утверждения",
  "lbl_approvals_card_header_project_phase_client": "Проект: этап — клиент",
  "lbl_approve_and_lock": "Утвердить и заблокировать",
  "lbl_approved": "Одобрено",
  "lbl_approved_and_locked": "Утверждено и заблокировано",
  "lbl_approved_by": "Одобрено пользователем",
  "lbl_approver": "Ответственный за утверждение",
  "lbl_approver_view": "Представление ответственного за утверждение",
  "lbl_approvers": "Ответственные за утверждение",
  "lbl_april": "Апрель",
  "lbl_archive": "Архивировать",
  "lbl_archive_projects": "Архивировать проекты",
  "lbl_archived": "Архивировано",
  "lbl_archived_label": "archived-label",
  "lbl_archived_on_date": "Архивировано {{archiveDate}}",
  "lbl_archiving": "Архивация",
  "lbl_assign": "Назначить",
  "lbl_assign_group_label": "Назначить {{groupLabel}}",
  "lbl_assign_team_member": "Назначить участника команды",
  "lbl_assign_to": "Исполнитель",
  "lbl_assignment": "Назначение",
  "lbl_assignment_dates_colon": "Даты назначения:",
  "lbl_assignment_details": "Сведения о назначении",
  "lbl_assignment_name": "Название назначения",
  "lbl_assignment_status": "Статус назначения",
  "lbl_audit_log": "Журнал аудита",
  "lbl_august": "Август",
  "lbl_authentication": "Проверка подлинности",
  "lbl_author": "Автор",
  "lbl_automatic": "в автоматическом режиме",
  "lbl_automatic_capitalize": "Авто",
  "lbl_availability": "Доступность",
  "lbl_availability_sort_options": "Параметры сортировки доступности",
  "lbl_available_hours": "Доступное время",
  "lbl_back": "Назад",
  "lbl_back_to_account_settings": "Назад к параметрам учётной записи",
  "lbl_back_to_list_of_requests": "Назад к списку запросов",
  "lbl_based_on": "На основании",
  "lbl_bill_rate": "Ставка",
  "lbl_billable": "Оплачиваемые часы",
  "lbl_bracket_lc_none": "[нет]",
  "lbl_bracket_leave": "[отпуск]",
  "lbl_bracket_no_approver_assigned": "[Ответственный за утверждение не назначен]",
  "lbl_bracket_non_phase_specific": "[Не относится к конкретному этапу]",
  "lbl_bracket_none": "[Нет]",
  "lbl_brief": "Краткая версия",
  "lbl_budget": "Бюджет",
  "lbl_budget_amounts_filter_display_name": "Бюджеты: суммы",
  "lbl_budget_days_filter_display_name": "Бюджеты: дни",
  "lbl_budget_hours_filter_display_name": "Бюджеты: часы",
  "lbl_budget_report": "Отчёт по бюджету",
  "lbl_budgets": "Бюджеты",
  "lbl_calculating": "Идёт обработка",
  "lbl_cancel": "Отмена",
  "lbl_cancelled": "отменено",
  "lbl_capacity": "Ёмкость",
  "lbl_category": "Категория",
  "lbl_cell_phone": "Номер мобильного телефона",
  "lbl_certificate_details": "Сведения о сертификате",
  "lbl_change_to_unmanaged_confirm": "Этот пользователь больше не сможет отслеживать и утверждать время и расходы и больше не будет отображаться в представлении ресурсов и отчётах.",
  "lbl_change_to_managed": "Изменить тип на управляемый",
  "lbl_change_to_unmanaged": "Изменить тип на неуправляемый",
  "lbl_choose_file": "Выберите файл",
  "lbl_client": "Клиент",
  "lbl_client_name": "Имя клиента",
  "lbl_close": "Закрыть",
  "lbl_company_name": "Название компании",
  "lbl_complete": "Завершено",
  "lbl_confirm": "Подтвердить",
  "lbl_confirmed": "Подтверждено",
  "lbl_contact_info": "Контактные данные",
  "lbl_contact_support": "Обратиться в службу поддержки",
  "lbl_contractor": "Подрядчик",
  "lbl_copy": "Копировать",
  "lbl_count_of_total": "{{count}} из {{total}}",
  "lbl_create": "Создать",
  "lbl_create_new_request": "* Создать запрос",
  "lbl_current": "Текущий",
  "lbl_current_work": "Текущая работа",
  "lbl_custom_date_range": "Настраиваемый диапазон дат",
  "lbl_custom_field": "Настраиваемое поле",
  "lbl_date": "Дата",
  "lbl_date_string_at_time_string": "{{date}} в {{time}}",
  "lbl_date_string": "Строка даты",
  "lbl_dates": "Даты",
  "lbl_dates_for_submitted_time": "Даты для внесённого времени",
  "lbl_day_friday": "Пятница",
  "lbl_day_monday": "Понедельник",
  "lbl_day_saturday": "Суббота",
  "lbl_day_sunday": "Воскресенье",
  "lbl_day_thursday": "Четверг",
  "lbl_day_tuesday": "Вторник",
  "lbl_day_wednesday": "Среда",
  "lbl_days": "Дни",
  "lbl_deactivated": "Деактивирована",
  "lbl_december": "Декабрь",
  "lbl_delete": "Удалить",
  "lbl_delete_adv_report_modal": "Удалить отчёт?",
  "lbl_delete_saved_report_modal": "Удалить сохранённый отчёт?",
  "lbl_description": "Описание",
  "lbl_difference": "Отличия",
  "lbl_difference_from_past_scheduled": "Отличия от расписания за прошедшее время",
  "lbl_disabled": "отключено",
  "lbl_discipline": "Вид деятельности",
  "lbl_display_colon": "Отображение:",
  "lbl_done": "Готово",
  "lbl_duration": "Длительность",
  "lbl_duration_working_days": "Продолжительность (рабочие дни)",
  "lbl_edit_profile": "Изменить профиль",
  "lbl_edit_project_information": "Изменить информацию о проекте",
  "lbl_edit_projects": "Изменить проекты",
  "lbl_email": "Электронная почта",
  "lbl_email_must_be_less_than_255_characters.": "Адрес эл. почты должен содержать не более 255 символов.",
  "lbl_employee_number": "Номер сотрудника",
  "lbl_enabled": "включено",
  "lbl_end": "Завершение",
  "lbl_end_date": "Дата окончания",
  "lbl_end_lc_date": "Дата окончания",
  "lbl_ends_at": "Завершается",
  "lbl_enter_a_name": "Укажите название",
  "lbl_enter_rejection_reason": "Укажите причину отклонения",
  "lbl_entry_type": "Тип записи",
  "lbl_everyone": "Все",
  "lbl_everything": "Всё",
  "lbl_expense": "Расходы",
  "lbl_expenses": "Расходы",
  "lbl_export": "Экспорт",
  "lbl_export_people_list": "Экспортировать список пользователей",
  "lbl_export_report_to_csv": "Экспортировать отчёт в CSV",
  "lbl_export_time_entries_for_quickBooks": "Экспортировать значения времени в формат QuickBooks",
  "lbl_export_underlying_data_as_csv": "Экспортировать исходные данные в формате CSV",
  "lbl_failed": "Сбой",
  "lbl_february": "Февраль",
  "lbl_fees": "Оплата",
  "lbl_filter": "Фильтр",
  "lbl_filtered": "Отфильтровано",
  "lbl_filters": "Фильтры",
  "lbl_first_day_of_work": "Первый рабочий день",
  "lbl_first_group_by": "Сначала группировать по",
  "lbl_first_name": "Имя",
  "lbl_first_name_lc": "Имя",
  "lbl_friday_abbr_uc": "Пт",
  "lbl_future": "На будущее",
  "lbl_future_scheduled": "Запланировано на будущее",
  "lbl_global_modules": "Глобальные модули",
  "lbl_grid_view": "Представление сетки",
  "lbl_hours": "Часы",
  "lbl_hours_filter_display_name": "Время и оплата: часы",
  "lbl_hours_per_day": "Часов в день",
  "lbl_idp_metadata_url": "URL-адрес метаданных поставщика удостоверений",
  "lbl_iframe_view_content_require": "Для просмотра этого контента нужна лицензия Smartsheet Resource Management.",
  "lbl_in_the_office": "В офисе",
  "lbl_incurred": "Потрачено",
  "lbl_internal": "Внутренний",
  "lbl_invited": "Приглашён",
  "lbl_january": "Январь",
  "lbl_july": "Июль",
  "lbl_june": "Июнь",
  "lbl_just_now": "Только что",
  "lbl_last_and_next_ninety_days": "Предыдущие и следующие 90 дней",
  "lbl_last_day_of_work": "Последний рабочий день",
  "lbl_last_month": "Прошлый месяц",
  "lbl_last_name": "Фамилия",
  "lbl_last_name_lc": "Фамилия",
  "lbl_last_quarter": "Прошлый квартал",
  "lbl_last_thirty_days": "Последние 30 дней",
  "lbl_last_week": "Прошлая неделя",
  "lbl_learn_lc_more": "Узнайте больше",
  "lbl_learn_more": "Узнайте больше",
  "lbl_leave": "Отпуск",
  "lbl_leave_type": "Тип отпуска",
  "lbl_licensed": "С лицензией",
  "lbl_licensed_users": "Лицензированные пользователи",
  "lbl_linked_smartsheet_project": "Связанный проект Smartsheet",
  "lbl_loading": "Загрузка",
  "lbl_loading_ellipsis": "Загрузка…",
  "lbl_loading_project_ellipsis": "Загрузка проекта...",
  "lbl_location": "Мнстоположение",
  "lbl_log_requested": "Журнал запрошен",
  "lbl_make_active": "Активировать",
  "lbl_make_licensed_user": "Сделать лицензированным пользователем",
  "lbl_manage_members": "Управление участниками",
  "lbl_managed_resource": "Управляемые ресурсы",
  "lbl_manual": "вручную",
  "lbl_manual_capitalize": "Вручную",
  "lbl_march": "Март",
  "lbl_matching_people": "Соответствующие критериям пользователи",
  "lbl_matching_person": "соответствующий критериям пользователь",
  "lbl_matching_placeholders": "Совпадающие заполнители",
  "lbl_matching_project": "соответствующий критериям проект",
  "lbl_matching_projects": "соответствующие критериям проекты",
  "lbl_may": "Май",
  "lbl_medium": "Средний",
  "lbl_members": "Участники",
  "lbl_mobile": "Мобильный доступ",
  "lbl_module": "Модуль:",
  "lbl_module_name": "Название модуля",
  "lbl_monday_abbr_uc": "Пн",
  "lbl_month": "Месяц",
  "lbl_more_assignments": "следующих назначений",
  "lbl_must_be_between_8–40_characters.": "Необходимо ввести от 8 до 40 символов.",
  "lbl_my_expenses": "Мои расходы",
  "lbl_my_projects": "Мои проекты",
  "lbl_my_time": "Моё время",
  "lbl_my_time_and_expenses": "Мои расходы и время",
  "lbl_my_views": "Мои представления",
  "lbl_new_project": "Новый проект",
  "lbl_next_ninety_days": "Следующие 90 дней",
  "lbl_next_sixty_days": "Следующие 60 дней",
  "lbl_next_thirty_days": "Следующие 30 дней",
  "lbl_next_week": "Следующая неделя",
  "lbl_no_assignments": "Назначений нет",
  "lbl_no_description_provided": "Описание отсутствует.",
  "lbl_no_imports_found": "Импортированные элементы отсутствуют",
  "lbl_non_billable": "Неоплачиваемые часы",
  "lbl_none": "Нет",
  "lbl_non_matching": "Несовпадающие значения",
  "lbl_not_using_smar": "Без использования проверки подлинности Smartsheet",
  "lbl_not_using_sso": "Без использования службы единого входа",
  "lbl_not_yet_invited": "Не приглашённые",
  "lbl_notes": "Примечания",
  "lbl_november": "Ноябрь",
  "lbl_october": "Октябрь",
  "lbl_office": "Офис",
  "lbl_office_phone": "Рабочий телефон",
  "lbl_office_status": "Статус офиса",
  "lbl_on_the_road": "В пути",
  "lbl_or_select_a_specific_phase": "Или выберите нужный этап",
  "lbl_or_select_phases": "Или выберите этап(-ы)",
  "lbl_or_select_specific_phases": "Или выбрать нужные этапы",
  "lbl_other_projects": "Другие проекты",
  "lbl_out_of_office": "Не в офисе",
  "lbl_out_sick": "На больничном",
  "lbl_past": "Предыдущие",
  "lbl_pending": "Ожидание",
  "lbl_pending_approval": "Ожидает утверждения",
  "lbl_people": "Пользователи",
  "lbl_people_filters": "Фильтры пользователей",
  "lbl_people_scheduler": "Расписание пользователей",
  "lbl_people_tags": "Теги пользователей",
  "lbl_percentage": "Проценты",
  "lbl_persistent_navigation": "Постоянная навигация",
  "lbl_person": "Пользователь",
  "lbl_phase": "Этап",
  "lbl_phase_dates": "Даты этапа",
  "lbl_phase_dates_colon": "Даты этапа:",
  "lbl_phase_four": "Четвёртый этап",
  "lbl_phase_lc_name": "Название этапа",
  "lbl_phase_name": "Название этапа",
  "lbl_phase_name_long_enough_to_wrap": "Название этапа, достаточно длинное для переноса по словам",
  "lbl_phase_no_assignments_worklist": "На этом этапе нет заданий, но он всё равно будет отображаться в вашем рабочем списке, поскольку в нём есть записи времени.",
  "lbl_phase_one": "Первый этап",
  "lbl_phase_status": "Статус этапа",
  "lbl_phase_three": "Третий этап",
  "lbl_phase_two": "Второй этап",
  "lbl_phases_alert_add": "Этапы можно добавлять, только используя <0>подключённую таблицу</0>.",
  "lbl_phases_dates_edit": "Даты этапов можно менять только путём изменения дат их назначений или используя <0>подключённую таблицу</0>.",
  "lbl_phases_names_edit": "Названия этапов можно изменять, только используя </0>подключённую таблицу</0>.",
  "lbl_phases_delete": "Этапы можно удалять, только удалив все их назначения или используя </0>подключённую таблицу</0>.",
  "lbl_placeholder": "Заполнитель",
  "lbl_placeholders": "Заполнители",
  "lbl_please_sign_in": "Войдите, чтобы продолжить",
  "lbl_plus_allocation": "+ Выделено",
  "lbl_portfolio_editor": "Редактор портфеля",
  "lbl_portfolio_reporter": "Создатель отчёта по портфелю",
  "lbl_portfolio_viewer": "Наблюдатель портфеля",
  "lbl_post": "Опубликовать",
  "lbl_project": "Проект",
  "lbl_project_access": "Доступ к проекту",
  "lbl_project_code": "Код проекта",
  "lbl_project_dates": "Даты проекта",
  "lbl_project_dates_colon": "Даты проекта:",
  "lbl_project_description": "Описание проекта",
  "lbl_project_duration": "Длительность проекта",
  "lbl_project_editor": "Редактор проекта",
  "lbl_project_filters": "Фильтры проекта",
  "lbl_project_members": "Участники проекта",
  "lbl_project_name": "Название проекта",
  "lbl_project_owner": "Владелец проекта",
  "lbl_project_owners": "Владельцы проекта",
  "lbl_project_portfolio": "Портфель проектов",
  "lbl_project_properties": "Свойства проекта",
  "lbl_project_settings": "Параметры проекта",
  "lbl_project_state": "Состояние проекта",
  "lbl_project_tags": "Теги проекта",
  "lbl_project_title": "Название проекта",
  "lbl_project_type": "Тип проекта",
  "lbl_projects": "Проекты",
  "lbl_purchase_more": "приобрести ещё",
  "lbl_recents": "Недавние",
  "lbl_recent_requests": "Недавние запросы",
  "lbl_refresh_status": "Обновить статус",
  "lbl_refresh_type": "Обновить тип",
  "lbl_refreshing_data": "Обновление данных...",
  "lbl_reject": "Отклонить",
  "lbl_rejected": "Отклонено",
  "lbl_remaining": "Осталось",
  "lbl_remove": "Удалить",
  "lbl_report_data_refresh_status_error": "Не удалось обновить данные {{date}} в {{time}}",
  "lbl_report_data_refresh_status_error_please_try_again": "При создании расширенного отчёта произошла ошибка. Повторите попытку. Если проблема будет возникать снова, обратитесь в службу поддержки.",
  "lbl_report_data_refresh_status_loading": "Обновление расширенного отчёта",
  "lbl_report_data_refresh_status_success": "Последнее обновление {{date}} в {{time}}",
  "lbl_report_data_refresh_status_warning_auto": "Изменения будут синхронизированы при следующем обновлении данных.",
  "lbl_report_data_refresh_status_warning_manual": "Обновите расширенный отчёт, чтобы синхронизировать изменения.",
  "lbl_report_name_colon": "Имя отчёта:",
  "lbl_report_title_by_criteria": "{{report}}, автор: {{criteria}}",
  "lbl_reports": "Отчёты",
  "lbl_request_an_audit_log": "Запросить журнал аудита",
  "lbl_request_audit_log": "Запросить журнал аудита",
  "lbl_requester": "Создатель запроса",
  "lbl_resend_invitation": "Отправить приглашение повторно",
  "lbl_reset": "Сброс",
  "lbl_reset_filters": "Сбросить фильтры",
  "lbl_reset_password": "Сбросить пароль",
  "lbl_reset_password_for": "Сбросить пароль для",
  "lbl_reset_report": "Сбросить параметры отчёта",
  "lbl_resource-request_test_page": "Страница для тестирования запросов к ресурсам",
  "lbl_resource_management_by_smartsheet": "Smartsheet Resource Management",
  "lbl_resourcing_admin": "Администратор распределения ресурсов",
  "lbl_retry": "Повторить",
  "lbl_cannot_connect_to_smar": "К сожалению, нам не удалось подключить проект к Smartsheet.",
  "lbl_connecting_to_smar": "Идёт подключение к Smartsheet",
  "lbl_close_banner": "Закрыть баннер",
  "lbl_role": "Роль",
  "lbl_saturday_abbr_uc": "Сб",
  "lbl_save": "Сохранить",
  "lbl_save_changes": "Сохранить изменения",
  "lbl_save_current_view": "Сохранить текущее представление",
  "lbl_save_this_report_to_your_personal_page": "Сохранить отчёт на личной странице",
  "lbl_saving": "Сохранение…",
  "lbl_schedule": "Расписание",
  "lbl_scheduled": "Запланировано",
  "lbl_search": "Поиск",
  "lbl_search_ellipsis": "Поиск…",
  "lbl_select_date_range_to_see_entries": "Выберите диапазон дат, чтобы показать все записи за этот промежуток времени",
  "lbl_select_people_to_add_to_project": "Выберите пользователей, которых необходимо добавить в этот проект.",
  "lbl_select_phases": "Выбрать этап(-ы)",
  "lbl_select_project": "Выбрать проект",
  "lbl_select_project_or_phase": "Выбрать проект или этап",
  "lbl_select_project_owner": "Выбрать владельца проекта",
  "lbl_select_team_member": "Выбрать участника команды:",
  "lbl_select_the_entire_project": "Выбрать весь проект",
  "lbl_send": "Отправить",
  "lbl_send_invitation": "Отправить приглашение",
  "lbl_september": "Сентябрь",
  "lbl_set_a_status": "Установить статус",
  "lbl_set_allocation": "Задать распределение:",
  "lbl_set_to_today": "Задать значение \"Сегодня\"",
  "lbl_share": "Предоставить доступ",
  "lbl_shared_views": "Представления с общим доступом",
  "lbl_show": "Показать",
  "lbl_show_no_phases": "Отображение: нет этапов",
  "lbl_sign_out_slo_target_url": "Целевой URL-адрес для выхода (SLO)",
  "lbl_signin_sso_target_url": "Целевой URL-адрес для входа (единый вход)",
  "lbl_signup_password": "Пароль (не менее 8 символов)",
  "lbl_skipped": "Пропущено",
  "lbl_skipped_colon": "Пропущено: {{number}}",
  "lbl_small": "Малый",
  "lbl_something_went_wrong_ellipsis": "Произошла ошибка.",
  "lbl_sort_by": "Сортировать по",
  "lbl_sso_for_resource_management": "Единый вход для Resource Management",
  "lbl_start": "Начало",
  "lbl_start_date": "Дата начала",
  "lbl_start_lc_date": "Дата начала",
  "lbl_starts_at": "Начинается",
  "lbl_status": "Статус",
  "lbl_status_cancelled": "ОТМЕНЕНО",
  "lbl_status_failed": "СБОЙ",
  "lbl_status_message": "Сообщение о статусе",
  "lbl_status_queued": "ДОБАВЛЕНО В ОЧЕРЕДЬ",
  "lbl_status_running": "ВЫПОЛНЯЕТСЯ",
  "lbl_status_succeeded": "УСПЕШНО",
  "lbl_submitted_time_for_approval": "Время на утверждение",
  "lbl_sunday_abbr_uc": "Вс",
  "lbl_switch_to_another_account": "Переключиться на другую учётную запись:",
  "lbl_tag": "Тег",
  "lbl_tags": "Теги",
  "lbl_target_utilization": "Целевое использование",
  "lbl_tasks": "Задачи",
  "lbl_team_member": "Участник команды",
  "lbl_team_member_type": "Категория сотрудника",
  "lbl_tentative": "Предварительный",
  "lbl_terms": "условия",
  "lbl_the_selected_project_was": "Выбранный проект был ",
  "lbl_then_group_by": "Затем группировать по",
  "lbl_these_results_only_include_projects_that_you_have_permission_to_see": "Результаты содержат только те проекты, на просмотр которых у вас есть разрешение.",
  "lbl_this_month": "Этот месяц",
  "lbl_this_quarter": "Этот квартал",
  "lbl_this_week": "Эта неделя",
  "lbl_this_year": "Этот год",
  "lbl_thursday_abbr_uc": "Чт",
  "lbl_time": "Время",
  "lbl_time_and_expenses": "Время и расходы",
  "lbl_time_and_fee_categories": "Категории времени и оплаты",
  "lbl_time_and_fees": "Время и оплата",
  "lbl_time_and_fees_report": "Отчёт \"Время и оплата\"",
  "lbl_time_approval_dashboard": "Панель мониторинга для утверждения времени",
  "lbl_time_budget_unit_of_measurement": "Единицы измерения временного бюджета",
  "lbl_time_fees_amounts_and_hours_filter_display_name": "Время и оплата: суммы и часы",
  "lbl_time_fees_amounts_display_name": "Время и оплата: суммы",
  "lbl_time_fees_days_filter_display_name": "Время и оплата: дни",
  "lbl_time_frame": "Временной интервал",
  "lbl_time_tracking": "Учёт времени",
  "lbl_today": "Сегодня",
  "lbl_tomorrow": "Завтра",
  "lbl_total": "Всего",
  "lbl_tuesday_abbr_uc": "Вт",
  "lbl_type": "Тип",
  "lbl_unarchive": "Отменить архивацию",
  "lbl_unarchive_project": "Отменить архивацию проекта",
  "lbl_unassigned": "Назначение отменено",
  "lbl_unconfirmed": "Неподтверждённые",
  "lbl_unmanaged": "Неуправляемый",
  "lbl_unmanaged_resource": "Неуправляемый ресурс",
  "lbl_unmanaged_resources": "Неуправляемые ресурсы",
  "lbl_unlock": "Разблокировать",
  "lbl_upcoming": "Предстоящий",
  "lbl_update": "Обновить",
  "lbl_update_status": "Обновить статус: ",
  "lbl_updated": "Обновлено",
  "lbl_updating": "Обновление",
  "lbl_utilization": "Использование",
  "lbl_utilization_target": "Целевой показатель использования",
  "lbl_vacation": "Отпуск",
  "lbl_values": "Значения",
  "lbl_view": "Просмотр",
  "lbl_view_resourcing_schedule": "Просмотреть расписание ресурсов",
  "lbl_wednesday_abbr_uc": "Ср",
  "lbl_week": "Неделя",
  "lbl_work": "Рабочий",
  "lbl_work_email": "Рабочий адрес электронной почты",
  "lbl_work_item": "Рабочий элемент",
  "lbl_work_status": "Рабочий статус",
  "lbl_working_from_home": "Работает из дома",
  "lbl_worklist": "Список рабочих задач",
  "lbl_your_projects_includes_the_following_properties": "Ваши проекты: включает следующие параметры",
  "msg_account_owner_change_attempt": "Прежде чем архивировать этого пользователя, измените владельца учётной записи в параметрах учётной записи.",
  "msg_activated_smartsheet_auth": "Будет активирована проверка подлинности Smartsheet. Этот способ аутентификации вступает в силу немедленно и не может быть деактивирован",
  "msg_add_assignment_to_project": "Добавить назначение на проект",
  "msg_add_notes_placeholder": "Добавить заметки, дополнительные сведения, ссылки и т. д.",
  "msg_add_phase_to_project": "Добавить этап проекта",
  "msg_add_project_description": "Добавить описание проекта",
  "msg_add_save_current_view": "Добавить \"Сохранить текущее представление\"",
  "msg_add_team_member_to_project": "Добавить участника команды на проект",
  "msg_app_project_members_disabled_tooltip": "Это проекты, владельцем которых вы являетесь или которые были назначены вам.",
  "msg_app_project_members_enabled_tooltip": "Это проекты, владельцем или участником которых вы являетесь или которые были назначены вам.",
  "msg_audit_log_info": "Важно! В журнале аудита содержится точный снимок на момент запроса. Если с момента генерации запрошенного журнала в записи о времени и расходах были внесены изменения, такой снимок может устареть. Чтобы получить актуальную информацию, запросите новый журнал выше.",
  "msg_auto_provision_checkbox": "После активации функция автоматической подготовки пользователей назначает пользователям лицензии и выбранный уровень разрешений. Кроме того, она позволяет пользователям обходить процесс приглашения по электронной почте.",
  "msg_bracket_search_to_add_filters": "[Выполните поиск, чтобы добавить фильтры]",
  "msg_cannot_archive_account_owner": "Архивировать владельца учётной записи нельзя",
  "msg_cert_requirement": "Используйте файл с расширением CER, CRT или PEM в PEM-формате.",
  "msg_cert_upload_complete": "Отправка сертификата завершена",
  "msg_checkbox_list_aria": "Нажмите \"Снять флажок\", чтобы выбрать несколько элементов, или нажмите \"Выбрать\", чтобы выбрать только один элемент",
  "msg_choose_permission_type": "Выберите тип разрешений пользователя",
  "msg_choose_property_to_sort_by": "Выберите свойство, по которому необходимо отсортировать элементы.",
  "msg_close": "Закрыть",
  "msg_close_and_redirect_to_profile_page": "Закрыть и перейти на страницу профиля",
  "msg_count_matches_found": "Найдено совпадений: {{count}}",
  "msg_copy_url_to_share_report": "Скопируйте указанный ниже URL-адрес, чтобы предоставить доступ к отчёту:",
  "msg_customize_report_prompt": "Настроить отчёт можно с помощью инструментов на панели слева. Закончив работу, выберите один из вариантов ниже.",
  "msg_delete_adv_report_modal": "Это действие остановит обновление данных отчёта, но исходный отчёт и исходная таблица в целевой рабочей области не будут удалены.",
  "msg_delete_assignment": "Удалить назначение",
  "msg_delete_saved_report": "Удалить сохранённый отчёт",
  "msg_delete_saved_report_modal": "Это действие нельзя отменить, но можно создать новый отчёт с теми же параметрами.",
  "msg_delete_view": "Удалить представление",
  "msg_delete_work_item_warning": "Вы действительно хотите окончательно удалить этот рабочий элемент? Все связанные с ним задачи и назначения также будут удалены. Это действие необратимо.",
  "msg_edit_allocation": "Изменить распределение",
  "msg_edit_end_date": "Изменить дату окончания",
  "msg_edit_start_date": "Изменить дату начала",
  "msg_edit_status": "Изменить статус",
  "msg_edit_team_member": "Изменить участника команды",
  "msg_edit_work_item": "Изменить рабочий элемент",
  "msg_enable_bulk_editing_requirements": "Чтобы активировать пакетное изменение, укажите себя в фильтре \"Владелец проекта\", а в фильтре \"Состояние проекта\" выберите значение \"Активен\".",
  "msg_enable_module_for_existing_organizations": "Включить модуль для существующих организаций",
  "msg_enable_module_for_new_organizations": "Включить модуль для новых организаций",
  "msg_filter_is_locked_to_the_projects_you_have_permission_to_report_on": "Этот фильтр заблокирован. Вы можете работать только с проектами, по которым вам разрешено создавать отчёты.",
  "msg_filter_is_locked_to_the_projects_you_own_or_are_assigned_to": "Этот фильтр заблокирован. Вы можете работать только с проектами, владельцем которых являетесь или на которые были назначены.",
  "msg_grid_view_aria": "Представление сетки. Активируйте, чтобы выбрать представление сетки.",
  "msg_hold_on_loading_ellipsis": "Подождите, идёт загрузка...",
  "msg_infinite_loader_aria_label": "Нажмите \"Снять флажок\", чтобы выбрать несколько элементов, или нажмите \"Выбрать\", чтобы выбрать только один элемент",
  "msg_invalid_cert": "Тип файла, содержащего выбранный сертификат, недействителен.",
  "msg_list_view_and_project_field_selector": "Представление списка и поля проекта. Активируйте, чтобы выбрать представление списка, а затем выбрать доступные поля проекта.",
  "msg_loading_approval_status": "Загружается статус утверждения...",
  "msg_loading_page": "Загрузка страницы",
  "msg_loading_project_timeline_ellipsis": "Загрузка временной шкалы проекта...",
  "msg_loading_project_users_ellipsis": "Загрузка пользователей проекта...",
  "msg_make_bulk_project_archive_warning": "При архивации этих проектов все текущие назначения будут завершены, а будущие — удалены. Это действие необратимо.",
  "msg_make_bulk_project_edit_confirmation": "Вы подтверждаете это изменение?",
  "msg_make_bulk_project_edit_warning": "Выполнение операции может занять несколько минут. Отменить её нельзя.",
  "msg_manage_project_membership_and_project_access_levels_based_on_their_account_permissions": "Работа с пользователями, состоящими в проекте, и настройка прав доступа к проекту. Настройки прав доступа применяются только к этому проекту. Некоторые пользователи, не состоящие в проекте, могут изменять его, поскольку имеют соответствующие <1>разрешения на уровне учётной записи</1>.",
  "msg_need_a_minute_ellipsis": "К сожалению, Подождите...",
  "msg_new_work_item_title": "Название нового рабочего элемента",
  "msg_no_assignments": "Назначений нет",
  "msg_no_matches_found": "Совпадений не найдено",
  "msg_no_people_assigned_to_project": "Нет сотрудников, назначенных на этот проект.",
  "msg_no_phases_to_display": "Нет этапов для отображения",
  "msg_no_projects_match_your_criteria": "Нет проектов, соответствующих указанным критериям.",
  "msg_no_propertyies_to_display": "Нет параметров для отображения",
  "msg_no_recent_requests": "Нет недавних запросов",
  "msg_no_results_to_display": "Результатов для отображения нет",
  "msg_no_work_for_this_project": "Нет текущей работы для этого проекта.",
  "msg_not_available": "недоступно",
  "msg_one_match_found": "Найдено 1 совпадение",
  "msg_owners_of_projects_permission_to_report_on": "Владельцы проектов, по которым вы можете создавать отчёты.",
  "msg_people_field_selector": "Поле \"Пользователи\". Активируйте, чтобы выбрать доступные данные.",
  "msg_people_settings_info": "Лицензированные пользователи могут вести учёт времени и задач по запланированным назначениям. Кроме того, их можно приглашать выполнить вход. Управляемые ресурсы можно включать в расписание выполнения проектов, однако они не могут вести учёт времени и не имеют доступа для входа.",
  "msg_people_list_licensed_tooltip": "В зависимости от полученных разрешений лицензированные пользователи могут отслеживать и утверждать время, планировать проекты, управлять персоналом, редактировать профили пользователей и создавать отчёты.",
  "msg_people_list_managed_tooltip": "Повысьте уровень управляемых ресурсов, чтобы они могли вести учёт рабочего времени.",
  "msg_people_list_unmanaged_tooltip": "Повысьте уровень неуправляемых ресурсов, чтобы они отображались в представлении ресурсов и отчётах.",
  "msg_people_list_archived_tooltip": "Назначения архивированных пользователей остаются на странице проекта, чтобы их можно было передать другому сотруднику или заполнителю.",
  "msg_phase_picker_copy": "Копировать проект или этап",
  "msg_print_brief_page": "Печать сводки",
  "msg_profile_banner": "Баннер профиля",
  "msg_profile_information": "Информация профиля",
  "msg_profile_navigation": "Навигация профиля",
  "msg_profile_navigation_menu": "Меню навигации профиля",
  "msg_project_members_tooltip": "Это проекты, владельцем или участником которых вы являетесь или которые были назначены вам.",
  "msg_project_navigation": "Навигация проекта",
  "msg_project_members_description": "Список пользователей, состоящих в проекте, применяется только к этому проекту. В зависимости от <0>разрешений своей учётной записи</0> некоторые пользователи могут получить доступ к проекту, не входя в такой список.",
  "msg_reassign_search_placeholder": "Поиск по именам, видам деятельности и т. д.",
  "msg_refine_search_to_see_more_users": "Попробуйте изменить параметры поиска, чтобы показать больше соответствующих пользователей",
  "msg_rejection_reason_required": "Необходимо указать причину отклонения.",
  "msg_remove_archived_project_state_to_enable_editing": "Удалите значение \"Архивирован\" из условий фильтра \"Состояние проекта\", чтобы внести изменения.",
  "msg_remove_assignment_confirmation": "Вы действительно хотите удалить это назначение?",
  "msg_report_data_refresh_err_detail_cell_limit_exceeded": "Не удалось обновить данные вашего отчёта, поскольку он содержит на 3000 ячеек больше установленного лимита. Уменьшите размер отчёта, настроив параметры на левой панели. <1>Подробнее.</1>",
  "msg_report_data_refresh_failed_due_to_column_name_change": "У одного или нескольких столбцов в вашем рабочем процессе было изменено имя, или они были удалены. Обновите сопоставление столбцов и повторите попытку.",
  "msg_report_data_refresh_failed_sheet_deleted": "При обновлении расширенного отчёта произошла ошибка, так как связанная с ним таблица была удалена. Восстановите таблицу или создайте новый расширенный отчёт.",
  "msg_report_data_refresh_err_detail_generic": "При обновлении данных расширенного отчёта произошла ошибка. Создайте новый расширенный отчёт.",
  "msg_report_data_refresh_err_detail_row_limit_exceeded": "Не удалось обновить данные вашего отчёта, поскольку он содержит на 300 строк больше установленного лимита. Уменьшите размер отчёта, настроив параметры на левой панели. <1>Подробнее.</1>",
  "msg_report_data_refresh_err_report_no_data": "В отчёте нет данных. Перезапустите отчёт, изменив параметры на левой панели.",
  "msg_report_data_refresh_err_report_no_data_tooltip": "Внимание! Расширенный отчёт не был обновлён, так как последнее обновление не дало результатов.",
  "msg_report_data_refresh_err_detail_sheet_access": "При обновлении данных расширенного отчёта произошла ошибка. Обновите разрешения таблицы или создайте новый расширенный отчёт.",
  "msg_report_is_too_big_to_load": "Этот отчёт невозможно загрузить из-за его размера",
  "msg_save_report_reports_page": "Сохранить отчёт на странице \"Отчёты\"",
  "msg_search_contacts": "Поиск контактов",
  "msg_settings_people_manage_info": "Вы можете настроить <1>параметры входа и доступа</1>, а также <3>разрешения</3> для пользователей вашей учётной записи.<br /> <5>Свяжитесь с нами</5>, чтобы внести изменения в вашу подписку.",
  "msg_share_report": "Скопируйте URL-адрес с помощью кнопки ниже, чтобы предоставить доступ к отчёту:",
  "msg_sheet_collaborators": "Здесь не отображаются соавторы, работающие с таблицами в связанных проектах. Обновить настройки предоставления доступа к таблицам и добавить соавторов можно в Smartsheet. <0>Подробнее</0>",
  "msg_signup_loading_transition": "Мы настраиваем вашу бесплатную пробную учётную запись.",
  "msg_sort_by_last_name": "Сортировать по фамилии",
  "msg_specify_budget_in_unit": "Укажите этот бюджет в {{curUnit}}",
  "msg_sso_configuration_info": "Рекомендуемая автоматическая конфигурация упрощает настройку единого входа и по умолчанию поддерживает несколько сертификатов. Ручная конфигурация поддерживает только один сертификат за раз.",
  "msg_sso_enabled": "В настоящее время для вашей учётной записи включён единый вход",
  "msg_sso_info": "Система единого входа для управления паролями и подготовки пользователей помогает компании строже контролировать доступ к Resource Management. В результате в систему смогут безопасно входить нужные пользователи.",
  "msg_sso_login_failure": "К сожалению, вход в Smartsheet Resource Management не выполнен.",
  "msg_sso_not_configured": "Для вашей учётной записи не настроен единый вход.",
  "msg_sso_unconfigurable": "В настоящее время ваша организация использует проверку подлинности Smartsheet, поэтому настроить единый вход непосредственно в Resource Management нельзя. Управление параметрами единого входа осуществляется в Центре администрирования Smartsheet",
  "msg_terms": "Условия",
  "msg_thanks": "Спасибо!",
  "msg_there_are_no_approvals_matching_your_current_filters": "Нет ожидающих утверждения позиций, которые бы соответствовали текущим условиям фильтра.",
  "msg_to_inactive_project": "в неактивный проект",
  "msg_transfer_project_ownership_to_remove_user": "Пользователь {{member.name}} является владельцем проекта и не может быть удалён. Передать права владения можно на вкладке \"Параметры проекта\".",
  "msg_type_and_press_enter_to_add_task": "Введите название задачи, а затем нажмите клавишу \"Ввод\"",
  "msg_cant_edit_remove_unmanaged_resource": "{{member.name}} является неуправляемым ресурсом. Повысьте уровень учётной записи пользователя (в настройках) или удалите его из этого проекта.",
  "msg_cant_add_unmanaged_resource": "{{member.name}} является неуправляемым ресурсом. Чтобы добавить пользователя в этот проект, повысьте уровень его учётной записи в настройках.",
  "msg_upload_failed": "Не удалось загрузить",
  "msg_upload_succeeded": "Загружено",
  "msg_user_default_login_tooltip": "Этот пользователь ещё не выполнял вход с помощью службы единого входа. Для изменения попросите его выйти из системы, а затем войти с помощью службы единого входа.",
  "msg_user_smar_login_tooltip": "Этот пользователь ещё не выполнял вход с помощью проверки подлинности Smartsheet. Для изменения попросите его выйти из системы, а затем войти с помощью проверки подлинности Smartsheet.",
  "msg_wait_while_load_assignments": "Подождите. Идёт загрузка назначений...",
  "msg_welcome_to_resource_management_by_smartsheet": "Добро пожаловать в Smartsheet Resource Management",
  "msg_work_item_description_placeholder": "Опишите рабочую задачу, назначение и т. д.",
  "msg_you_dont_own_these_projects_and_theyre_not_assigned_to_you": "Вы не являетесь владельцем этих проектов, и они не были назначены вам.",
  "msg_saved_form_already_exists": "уже существует. Хотите заменить?",
  "msg_you_do_not_have_admin_access": "Проверьте, есть ли у вас разрешения уровня администратора для работы с нужным рабочим пространством. Вы можете запросить доступ к нему или создать новое целевое рабочее пространство в <0>Smartsheet</0>.",
  "msg_no_matching_results": "Нет результатов, соответствующих условиям",
  "msg_report_filter_list": "Это список \"Фильтрация отчёта\". Выберите данные, которые необходимо включить в отчёт.",
  "msg_report_filter_show_group": "Это группа \"Отображение фильтрации отчёта\". Выберите, какие параметры в каждой категории необходимо отобразить (все или ни одного; проставьте или снимите флажки, если нужно выбрать несколько параметров, или активируйте кнопку объединения, чтобы выбрать только один параметр).",
  "lbl_getting_information": "Получение информации",
  "lbl_actions": "Действия",
  "lbl_delete_phase": "Удалить этап",
  "lbl_chars_left": "до окончания",
  "lbl_set_to": "Задать значение",
  "msg_no_ppl_match": "Нет пользователей, соответствующих указанным критериям",
  "lbl_user_info_summary": "Сводка по пользователю",
  "lbl_saved_reports": "Сохранённые отчёты",
  "lbl_no_active_assignments": "Нет активных назначений.",
  "msg_entries_not_shown": "Не показаны значения для проектов, не имеющих владельца",
  "msg_provide_note": "Добавьте примечание для данного утверждения. Это необходимо для отклонения.",
  "msg_taking_longer_than_expected": "Требуется больше времени, чем ожидалось. Подождите немного",
  "msg_want_to_make_adjustments": "Хотите внести изменения или ускорить работу? Вы можете добавить дополнительные параметры фильтрации, чтобы уменьшить размер отчёта или сосредоточиться на отдельных аспектах.",
  "msg_calculating_time_entries": "Идёт обработка значений времени {{timeEntriesCount}}.",
  "lbl_just_a_few_seconds": "несколько секунд",
  "lbl_less_than_a_min": "менее минуты",
  "lbl_about_a_min": "примерно минуту",
  "msg_add_users_intro": "На этой странице можно добавить новых пользователей или обновить параметры пользователей, уже имеющихся в вашей учётной записи.",
  "msg_add_projects_intro": "На этой странице можно добавить новые проекты или обновить проекты, уже имеющиеся в вашей учётной записи.",
  "msg_more_details_can_be_found": "Более подробные сведения содержатся в <0>справочной статье</0>. Есть вопросы? <1>Свяжитесь</1> с нашей службой поддержки.",
  "lbl_step_1_prepare_your_data": "1. Подготовьте данные",
  "lbl_step_1_content_users": "Примечание. Не изменяйте столбец \"User ID\" (\"Идентификатор пользователя\"), так как он используется для сопоставления обновлений с данными о существующих пользователях.",
  "lbl_step_1_content_projects": "Примечание. Не изменяйте столбец \"Project ID\" (\"Идентификатор проекта\"), так как он используется для сопоставления обновлений с существующими проектами.",
  "lbl_step_1_contents_create_users": "Этот шаблон можно применять для добавления новых пользователей в вашу учётную запись.",
  "lbl_step_1_contents_create_projects": "Этот шаблон можно применять для добавления новых проектов в вашу учётную запись.",
  "lbl_step_1_contents_edit_users": "Экспорт списка всех пользователей в вашей организации с целью обновления их атрибутов.",
  "lbl_step_1_contents_edit_projects": "Экспорт списка всех проектов вашей организации с целью обновления их атрибутов.",
  "lbl_people_lc": "пользователей(-я)",
  "lbl_projects_lc": "проекты",
  "lbl_add_update_projects": "Добавить или обновить проекты",
  "lbl_download_template": "Скачать шаблон",
  "lbl_update_existing": "Обновить существующие",
  "lbl_export_project_list": "Экспортировать список проектов",
  "lbl_step_2_upload_data_users": "2. Загрузите обновлённый список пользователей",
  "lbl_step_2_upload_data_projects": "2. Загрузите обновлённый список проектов",
  "msg_select_csv_file": "Выберите и загрузите файл в формате CSV, чтобы добавить или обновить {{importType}}. После завершения операции вы получите подтверждение по электронной почте.",
  "lbl_send_invitation_new_licensed_users": "Отправка приглашения новым лицензированным пользователям.",
  "lbl_upload": "Отправить",
  "lbl_processing": "Обработка…",
  "lbl_add_new": "Добавить {{importType}}",
  "msg_import_in_progress": "Идёт импорт",
  "msg_errors_during_import": "Ошибки, обнаруженные в ходе импорта",
  "lbl_import_complete": "Импорт завершён",
  "lbl_added_colon": "Добавлено: {{number}}",
  "lbl_updated_colon": "Обновлено: {{number}}",
  "lbl_failed_colon": "Сбой: {{number}}",
  "lbl_download_error_report_csv": "Скачать отчёт об ошибках в формате CSV",
  "msg_working_on_import_background": "Импорт будет выполняться в фоновом режиме. Когда импорт будет завершён, вы получите электронное сообщение. Ссылка на эту страницу приведена в нижней части страницы <0>{{pageTitle}}</0>.",
  "msg_rows_with_errors": "Строки, содержащие ошибки, не были добавлены/обновлены. Вы можете скачать отчёт об ошибках в формате CSV, чтобы устранить их и повторить попытку. Вам могут встретиться и другие ошибки. Перепроверьте все данные перед тем, как загрузить их снова. <0>Подробнее</0> об импорте и обновлении {{importType}}.",
  "msg_completed_processing": "Завершена обработка импортированных данных {{importType}}, которые вы недавно добавили и обновили в рамках своей организации.",
  "lbl_imports_last_30_days": "Импорт за последние 30 дней",
  "lbl_characters": "зн. ",
  "lbl_character": "знак ",
  "lbl_remaining_lc": "осталось",
  "msg_no_items_match_criteria": "Нет элементов, соответствующих указанным критериям",
  "lbl_future_scheduled_lc": "запланировано на будущее",
  "lbl_loading_details": "Загрузка сведений",
  "lbl_try_again": "Повторить попытку",
  "err_must_not_be_blank": "Поле не может быть пустым",
  "err_cannot_be_more_than_255_char": "Используйте не более 255 символов",
  "lbl_view_name": "Имя представления",
  "lbl_project_timelines": "Временная шкала проекта",
  "msg_shorten_time_frame": "Выберите более короткий период, меньшее число пользователей или проектов, а затем загрузите проект снова. Кроме того, вы можете запросить электронное сообщение со ссылкой на отчёт в формате CSV.",
  "msg_underlying_data_requested": "Запрошены исходные данные. Вы получите уведомление по электронной почте, когда они будут подготовлены к скачиванию. Это может занять некоторое время.",
  "msg_no_report_values": "Для данного отчёта отсутствуют значения.",
  "msg_report_only_includes_data": "В этот отчёт включены только те данные, на просмотр которых у вас есть разрешение.",
  "msg_we_have_logged_error": "Мы получили информацию об ошибке и обязательно её рассмотрим. А пока попробуйте снова:",
  "msg_budgets_report_includes_all": "Этот отчёт включает данные обо всех понесённых расходах, расходах, запланированных на будущее, и расходах, включённых в бюджет, за весь период реализации проектов, даты которых пересекаются.",
  "lbl_run_report": "Выполнить отчёт",
  "lbl_request_underlying_data": "Запросить исходные данные",
  "lbl_selected": "Выбрано",
  "lbl_selected_lc": "выбрано",
  "lbl_project_lc": "проект",
  "msg_change_client_name": "Будет изменён клиент для",
  "msg_change_project_type": "Будет изменён тип проекта для",
  "msg_change_project_owner": "Будет изменён владелец проекта для",
  "msg_change_tags": "Будут изменены теги для",
  "msg_change_custom_fields": "Будут изменены настраиваемые поля для",
  "msg_this_will_archive_the": "Будет архивирован текущий элемент:",
  "msg_this_will_add_selected_tags": "Будут добавлены выбранные теги",
  "msg_this_will_remove_selected_tags": "Выбранные теги будут удалены",
  "msg_updating_client": "Обновление клиента для",
  "msg_updating_project_owner": "Обновление владельца проекта для",
  "msg_describe_work_item_placeholder": "Опишите задачу или требуемый результат работы.",
  "msg_show_hide_phase_items": "Скрыть/показать рабочие элементы этапа {{phaseName}}",
  "msg_edit_phase": "Изменить этап {{phaseName}}",
  "msg_show_hide_project_items": "Скрыть/показать рабочие элементы проекта {{projectName}}",
  "msg_edit_project": "Изменить проект {{projectName}}",
  "lbl_hours_lc": "ч",
  "lbl_hours_per_day_abbr": "ч/день",
  "lbl_new": "Новое",
  "lbl_add_team_member_to": "Добавить участника команды в {{parentLabel}}",
  "lbl_add_work_item_to": "Добавить рабочий элемент в {{parentLabel}}",
  "lbl_available_hours_lc": "доступное время (в часах)",
  "lbl_remaining_or_budget_forecast": "осталось или {{budgetForecastPercent}}",
  "lbl_forecasted_over_or_under_budget": "прогнозируемый бюджет {{overUnder}} или {{budgetForecastPercent}}",
  "lbl_under_lc": "излишек",
  "lbl_over_lc": "перерасход",
  "lbl_expense_budget_lc": "бюджет расходов",
  "lbl_project_budget_lc": "бюджет проекта",
  "lbl_expensed_lc": "потрачено",
  "lbl_incurred_lc": "потрачено",
  "lbl_add_description": "Добавить описание",
  "lbl_automatic_configuration": "Автоматическая настройка",
  "lbl_recommended_paren": "(Рекомендовано)",
  "lbl_manual_configuration": "Ручная настройка",
  "lbl_manage_sheet_sharing": "Управление совместным доступом к таблицам",
  "lbl_cert_must_be_PEM": "Сертификат должен иметь PEM-формат (файлы с расширением PEM, CERT, CRT или CER)",
  "msg_auto_provision_auth_users": "Выполнять автоматическую подготовку пользователей вне учётной записи, прошедших проверку подлинности (при создании использовался только адрес электронной почты)",
  "lbl_selected_phases": "Использовать выбранные этапы",
  "lbl_no_display_props": "Нет параметров для отображения",
  "msg_approval_audit_error": "Произошла непредвиденная ошибка. Если проблема сохраняется, обратитесь в службу поддержки и подробно опишите ситуацию: ",
  "msg_download_error": "При попытке скачать файл произошла ошибка: ",
  "lbl_audit_log_req": "Журнал аудита запрошен",
  "msg_audit_log_req": "Ссылка на скачивание появится в таблице \"Недавние запросы\" ниже. Это может занять несколько минут.",
  "lbl_audit_log_expense": "Журналы аудита расходов",
  "lbl_download": "Загрузить",
  "lbl_proj_singular": "проект был",
  "lbl_proj_plural": "проекты были",
  "lbl_zero_proj": "0 проектов",
  "lbl_success_lc": "успешно",
  "lbl_important": "<1>Важно!</1>",
  "lbl_edit_properties": "Изменить свойства",
  "lbl_error": "Ошибка",
  "lbl_change": "Изменить",
  "lbl_select_new": "Выбор новых",
  "lbl_approver_project": "Ответственный за утверждение: ",
  "lbl_successfully": " успешно.",
  "msg_update": "Обновление завершено",
  "lbl_of": "из",
  "msg_update_failed": "Не удалось произвести обновления.",
  "msg_update_lc": "— обновление успешно.",
  "lbl_edit_desc": "Изменить описание",
  "lbl_no_select": "ничего не выбрано",
  "lbl_current_filter_select": " : Текущий выбор ",
  "msg_getting_ready": "Подготовка...",
  "msg_retry_update": "Нажмите \"Повторить\", чтобы повторно произвести обновления, во время которых произошёл сбой.",
  "msg_error_support": "Если сообщение об ошибке возникнет снова, обратитесь в службу поддержки. ",
  "lbl_curr_dropdown_val": "Текущее значение раскрывающегося списка: ",
  "msg_cancel_view": "Отменить и вернуться к списку представлений",
  "msg_updating_tags": "Обновление тегов для",
  "msg_updatings_proj_type": "Обновление типа проекта для",
  "msg_start_your_trial_for_free": "Начните бесплатный пробный период ({{trialDuration}} дн.)",
  "msg_create_free_account": "Создать бесплатную учётную запись",
  "msg_I_agree_to_terms_and_conditions": "Я принимаю условия <0>Пользовательского соглашения</0> и предоставляю Smartsheet Resource Management согласие на обработку моих персональных данных в соответствии с <1>Политикой конфиденциальности</1>",
  "msg_something_went_wrong_signup": "Ой! При попытке входа произошла ошибка. Свяжитесь с нами на <0>странице поддержки</0>",
  "msg_edit_phase_settings": "Изменить настройки этапа",
  "msg_edit_project_settings": "Изменить параметры проекта",
  "msg_shift_phase_dates": "Сдвинуть даты этапа",
  "msg_shift_project_dates": "Сдвинуть даты проекта",
  "lbl_utilization_lc": "использование",
  "lbl_target_utilization_lc": "Целевое использование",
  "msg_organization_using_smar_auth_modifification_info": "В настоящее время ваша организация использует проверку подлинности Smartsheet для входа в Resource Management.",
  "lbl_user_auto_provisioning": "Автоматическая подготовка пользователей",
  "lbl_edit": "Изменить",
  "lbl_permission_level": "Уровень разрешений",
  "msg_activate_smar_auth_info": "Активируйте проверку подлинности, чтобы пользователи могли выполнять вход, используя свои учётные записи Smartsheet. <1>Подробнее</1>.",
  "lbl_activate_smar_auth": "Активировать проверку подлинности Smartsheet",
  "msg_please_link_rm_account_with_smar": "Чтобы проверка подлинности Smartsheet стала возможной, <1>свяжите свою учётную запись Resource Management с учётной записью Smartsheet</1>.",
  "lbl_setup_sso": "Настроить единый вход",
  "lbl_smartsheet_authentication": "Проверка подлинности Smartsheet",
  "lbl_edit_sso": "Изменить параметры единого входа",
  "msg_disabled_filter_info": "Этот параметр недоступен для расширенных отчётов. Создайте <0>новый отчёт</0>, чтобы получить доступ к этому параметру.",
  "msg_people_in_org_have_logged_in_using_sso": "Сотрудники вашей организации уже входили в систему с помощью единого входа. Неправильное изменение параметров единого входа может привести к тому, что пользователи не смогут войти в систему. Чтобы безопасно обновить параметры единого входа, <1>свяжитесь с нами</1> для получения поддержки.",
  "err_issue_enabling_sso": "При попытке активировать аутентификацию с помощью службы единого входа произошла ошибка: {{error}}. Если проблема не исчезнет, обратитесь в службу поддержки.",
  "err_activating_smar_auth": "Ошибка при попытке активировать проверку подлинности Smartsheet",
  "err_issue_updating_smar_auth": "При попытке обновить проверку подлинности Smartsheet произошла ошибка: {{error}}. Если проблема не исчезнет, обратитесь в службу поддержки.",
  "err_issue_enabling_smar_auth": "При попытке активировать проверку подлинности Smartsheet произошла ошибка: {{error}}. Если проблема не исчезнет, обратитесь в службу поддержки.",
  "msg_report_unmanaged_resources_admin": "Чтобы включить в этот отчёт неуправляемые ресурсы, повысьте их уровень в <0>настройках учётной записи</0>.",
  "msg_report_unmanaged_resources": "Чтобы включить в этот отчёт неуправляемые ресурсы, попросите администратора повысить их уровень в настройках учётной записи.",
  "lbl_adjust_refresh_settings": "Настройка параметров обновления данных",
  "lbl_view_advanced_report": "Просмотр расширенного отчёта",
  "lbl_save_and_refresh_data": "Сохранить изменения и обновить данные",
  "lbl_refresh_data": "Обновить данные",
  "lbl_delete_report": "Удалить отчёт",
  "lbl_toggle_reports_control_menu": "Переключение меню управления отчётами",
  "lbl_advanced_report": "Расширенный отчёт",
  "lbl_create_new_advanced_report": "Создание нового расширенного отчёта",
  "msg_open_the_advanced_report_in_smartsheet": "Откройте расширенный отчёт в Smartsheet",
  "lbl_advanced_report_edit_modal_title": "Настройки обновления данных",
  "lbl_advanced_report_modal_title": "Создание расширенного отчёта",
  "msg_advanced_report_modal_description": "Отправка данных в исходную таблицу и в отчёт Smartsheet. Обновить отчёт и настроить его параметры можно на странице \"Отчёты\".",
  "lbl_advanced_report_modal_name_field": "Имя",
  "lbl_advanced_report_modal_destination_field": "Назначение",
  "lbl_advanced_report_modal_data_refresh_field": "Настройки обновления данных",
  "lbl_advanced_report_modal_data_refresh_auto_name": "Авто",
  "lbl_advanced_report_modal_data_refresh_auto_description": "Данные расширенного отчёта обновляются каждые 24 часа",
  "lbl_advanced_report_modal_data_refresh_manual_name": "Вручную",
  "lbl_advanced_report_modal_data_refresh_manual_description": "Данные нужно обновлять вручную на странице \"Отчёты\"",
  "lbl_advanced_report_modal_close_button": "Закрыть",
  "lbl_advanced_report_modal_create_button": "Создать",
  "msg_advanced_report_modal_need_lws": "Попросите администратора распределения ресурсов активировать проверку подлинности Smartsheet, чтобы воспользоваться этой функцией.",
  "lbl_destination_dropdown_select_workspace": "Выберите рабочее пространство",
  "lbl_destination_dropdown_search_for_workspace": "Поиск рабочего пространства",
  "msg_advanced_report_ready_title": "Расширенный отчёт готов!",
  "msg_advanced_report_ready": "Получить доступ к расширенному отчёту и просмотреть его статус можно на странице \"Отчёты\"",
  "lbl_advanced_report_view_btn": "Просмотр расширенного отчёта",
  "msg_advanced_report_creating_title": "Создание расширенного отчёта",
  "msg_advanced_report_creating": "Это может занять несколько минут. Предпросмотр отчёта с демонстрационными данными доступен уже сейчас. Готовый отчёт будет содержать реальные данные.",
  "lbl_advanced_report_preview_btn": "Предпросмотр отчёта...",
  "msg_advanced_report_creation_footer": "Можно нажать кнопку \"Закрыть\". Доступ к этому элементу можно получить в сохранённых отчётах или в выбранном рабочем пространстве.",
  "lbl_advanced_report_creation_footer_link": "Вот несколько советов по составлению расширенных отчётов.",
  "lbl_create_advanced_report": "Создание расширенного отчёта",
  "lbl_edit_settings": "Редактирование настроек отчёта",
  "err_create_report_failed_generic": "При создании вашего отчёта произошла ошибка. Повторите попытку.",
  "err_edit_report_failed_generic": "При изменении вашего отчёта произошла ошибка. Повторите попытку.",
  "err_create_report_failed_invalid_name": "Название отчёта не должно содержать специальных символов и эмодзи",
  "msg_session_expired_title": "Срок действия сеанса истёк",
  "msg_session_expired_description": "Сеанс в этом окне больше не действителен. Нажмите кнопку ниже, чтобы обновить страницу. Возможно, вам придётся войти в систему ещё раз.",
  "lbl_refresh_page": "Обновить страницу",
  "msg_refresh_advanced_report": "Обновите данные через меню, чтобы применить фильтры к расширенному отчёту. Сохранённый отчёт Resource Management также будет обновлён.",
  "msg_advanced_report_too_many_cells": "Не удалось обновить отчёт, поскольку он содержит 500 000 или более ячеек. Измените настройки на панели слева, чтобы уменьшить количество ячеек. <0>Подробнее</0>",
  "msg_advanced_report_too_many_columns": "Не удалось обновить отчёт, поскольку он содержит 400 или более столбцов. Измените настройки на панели слева, чтобы уменьшить количество столбцов. <0>Подробнее</0>",
  "msg_advanced_report_too_many_rows": "Не удалось обновить отчёт, поскольку он содержит 20 000 или более строк. Измените настройки на панели слева, чтобы уменьшить количество строк. <0>Подробнее</0>",
  "msg_advanced_report_auto_refresh_disabled": "Если для этого отчёта установлено автоматическое обновление, этот параметр сменится на обновление вручную, чтобы предотвратить сбои при попытке обновления.",
  "lbl_link_to_smartsheet": "Ссылка на Smartsheet",
  "msg_link_to_smartsheet_tooltip": "Попросите администратора распределения ресурсов активировать проверку подлинности Smartsheet, чтобы воспользоваться этой функцией. <0>Подробнее</0>",
  "msg_new_link_to_smartsheet": "Создайте новую связанную таблицу со всеми данными вашего проекта Resource Management.",
  "msg_connect_to_smartsheet": "При подключении проекта будет создана новая связанная таблица с существующими данными проекта, чтобы вы могли воспользоваться широкими возможностями управления проектами Smartsheet.<0>Подробнее</0>",
  "lbl_connect": "Подключиться",
  "lbl_add_phase_col": "Добавить столбец этапа",
  "err_connect_sheet_to_proj_error": "К сожалению, нам не удалось подключить проект. Повторите попытку позже.",
  "msg_project_has_phases": "У вашего проекта уже есть этапы. Они будут добавлены в вашу новую связанную таблицу.",
  "msg_adding_phases": "Добавление столбца этапа позволит разбить ваш проект на различные этапы. <0>Подробнее</0>",
  "msg_connecting_proj_to_sheet": "Подключение вашего проекта к таблице",
  "msg_be_patient": "Проявите терпение. Это может занять несколько минут.",
  "msg_safe_to_close": "Можно нажать кнопку \"Закрыть\". Вы можете вернуться в любое время, чтобы проверить статус подключения к проекту.",
  "lbl_open_sheet": "Открыть таблицу",
  "lbl_sheet_connected": "Ваша подключённая таблица готова!",
  "msg_sheet_connected": "Ссылку на подключённую таблицу всегда можно найти в кратком описании проекта.",
  "msg_enforce_sign_in_with_smartsheet_title": "Теперь можно войти с помощью учётной записи Smartsheet",
  "msg_enforce_sign_in_with_smartsheet_heading": "Способ входа в систему для вашей организации был обновлён.",
  "msg_enforce_sign_in_with_smartsheet_text": "Теперь вы можете войти в систему с помощью проверки подлинности Smartsheet, что предоставляет лёгкий доступ к Resource Management в Smartsheet и новым функциям, таким как расширенная отчётность. <0>Подробнее</0>",
  "msg_enforce_sign_in_with_smartsheet_create_account_text": "Войдите с использованием электронной почты или <0>создайте учётную запись Smartsheet</0>, если у вас её ещё нет.",
  "msg_enforce_sign_in_with_smartsheet_skip_for_now": "Пропустить сейчас",
  "msg_enforce_sign_in_with_smartsheet_sign_in_with_smartsheet": "Войти с помощью учётной записи Smartsheet",
  "lbl_arms_discovery_modal_title": "У Resource Management новая домашняя страница!",
  "lbl_arms_discovery_modal_lws_text": "Теперь Resource Management доступен в навигационной панели Smartsheet. Централизованно управляйте рабочими группами, отслеживайте загруженность сотрудников и назначайте нужных исполнителей.",
  "lbl_arms_discovery_modal_lws_show_button": "Показать",
  "lbl_arms_discovery_modal_lws_close_button": "Позднее",
  "lbl_arms_discovery_modal_non_lws_text": "Теперь Resource Management доступен в навигационной панели Smartsheet. Попросите администратора распределения ресурсов подключить Resource Management к Smartsheet и активировать проверку подлинности Smartsheet.",
  "lbl_arms_discovery_modal_non_lws_close_button": "Понятно"
}