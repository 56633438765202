{
  "err_activating_sso_auth": "Erreur d’activation de l’authentification unique (SSO)",
  "err_company_name_length": "Le nom de la société doit comporter moins de 255 caractères.",
  "err_duplicate_value": "Cette valeur existe déjà",
  "err_email_length": "L’e-mail doit comporter moins de 255 caractères.",
  "err_failed_retrieve_initial_state_from_local_storage": "Échec de la récupération de l’état initial depuis localStorage",
  "err_fetching_filters": "Désolé… Nous avons rencontré un problème lors de la récupération de ces options de filtre.",
  "err_first_name_contains_url": "Le prénom ne doit pas contenir d’URL.",
  "err_first_name_length": "Le prénom doit comporter moins de 255 caractères.",
  "err_global_modules_invalid_input": "Entrée non valide.  Assurez-vous d’avoir défini un nom de module et qu’une action est au moins est cochée.",
  "err_invalid_email": "Veuillez entrer une adresse e-mail valide",
  "err_last_name_contains_url": "Le nom ne doit pas contenir d’URL.",
  "err_last_name_length": "Le nom doit comporter moins de 255 caractères.",
  "err_no_status_for_status_panel": "Votre équipe n’a défini aucune option de statut. Si vous souhaitez commencer à utiliser ce statut, un administrateur peut ajouter des options de statut dans les paramètres du compte.",
  "err_password_length_40_chars": "Doit comporter entre 8 et 40 caractères.",
  "err_required": "Obligatoire",
  "err_unable_to_load_filter_list": "Impossible de charger la liste des filtres",
  "err_unknown_error": "Erreur inconnue",
  "err_updating_smartsheet_auth": "Erreur de mise à jour de l’authentification Smartsheet",
  "err_whoops_something_did_not_work_as_expected": "Oups ! Un problème inattendu est survenu.",
  "lbl_about_fifteen_seconds": "environ 15 secondes",
  "lbl_about_thirty_seconds": "environ 30 secondes",
  "lbl_accept": "Accepter",
  "lbl_account_switcher": "commutateur de compte",
  "lbl_activate": "Activer",
  "lbl_activated_auth_status": "Activé (compte créé uniquement par e-mail)",
  "lbl_active": "Actif",
  "lbl_activity": "Activité",
  "lbl_actual_hours": "Heures réelles",
  "lbl_add": "Ajouter",
  "lbl_add_group_label_filter": "Ajouter {{groupLabel}} filtre",
  "lbl_assign_to_search_user": "Attribuer à « {{searchInput}} »",
  "lbl_add_lc_members": "Ajouter des membres",
  "lbl_add_members": "Ajouter des membres",
  "lbl_add_person": "Ajouter une personne",
  "lbl_add_tasklist": "Ajouter une liste de tâches",
  "lbl_add_team_member": "Ajouter un membre d’équipe",
  "lbl_add_update_people": "Ajouter/mettre à jour des personnes",
  "lbl_add_work_item": "Ajouter un élément de travail",
  "lbl_added": "Ajouté(e)(s)",
  "lbl_all": "Tout",
  "lbl_all_project_types": "Tous les types de projets",
  "lbl_allocation_heatmap": "Carte des priorités de l’allocation",
  "lbl_allocation_percent": "Pourcentage d’allocation",
  "lbl_amounts": "Montants",
  "lbl_amounts_and_days": "Montants et jours",
  "lbl_amounts_and_days_filter_display_name": "Temps et frais : jours et montants",
  "lbl_amounts_and_hours": "Montants et heures",
  "lbl_apply_changes": "Appliquer les modifications",
  "lbl_approval_access_id": "ID d’accès à l’approbation",
  "lbl_approval_status": "Statut d’approbation",
  "lbl_approvals": "Approbations",
  "lbl_approvals_card_header_project_phase_client": "Projet : Phase – Client",
  "lbl_approve_and_lock": "Approuver et verrouiller",
  "lbl_approved": "Approuvé(e)(s)",
  "lbl_approved_and_locked": "Approuvée et verrouillée",
  "lbl_approved_by": "Approuvée par",
  "lbl_approver": "Approbateur",
  "lbl_approver_view": "Vue de l’approbateur",
  "lbl_approvers": "Approbateurs",
  "lbl_april": "Avril",
  "lbl_archive": "Archiver",
  "lbl_archive_projects": "Archiver les projets",
  "lbl_archived": "Archivé",
  "lbl_archived_label": "archived-label",
  "lbl_archived_on_date": "Date d’archivage : {{archiveDate}}",
  "lbl_archiving": "Archivage",
  "lbl_assign": "Affecter",
  "lbl_assign_group_label": "Affecter {{groupLabel}}",
  "lbl_assign_team_member": "Affecter un membre d’équipe",
  "lbl_assign_to": "Attribuer à",
  "lbl_assignment": "Affectation",
  "lbl_assignment_dates_colon": "Dates d’affectation :",
  "lbl_assignment_details": "Détails de l’affectation",
  "lbl_assignment_name": "Nom de l’affectation",
  "lbl_assignment_status": "Statut de l’affectation",
  "lbl_audit_log": "Journal d’audit",
  "lbl_august": "Août",
  "lbl_authentication": "Authentification",
  "lbl_author": "Auteur",
  "lbl_automatic": "Automatique",
  "lbl_automatic_capitalize": "Automatique",
  "lbl_availability": "Disponibilité",
  "lbl_availability_sort_options": "Options de tri de la disponibilité",
  "lbl_available_hours": "Heures disponibles",
  "lbl_back": "Retour",
  "lbl_back_to_account_settings": "Retour aux paramètres du compte",
  "lbl_back_to_list_of_requests": "Retour à la liste des demandes",
  "lbl_based_on": "Fondé sur",
  "lbl_bill_rate": "Taux de facturation",
  "lbl_billable": "Facturable",
  "lbl_bracket_lc_none": "[aucun]",
  "lbl_bracket_leave": "[quitter]",
  "lbl_bracket_no_approver_assigned": "[Aucun approbateur affecté]",
  "lbl_bracket_non_phase_specific": "[Non spécifique à une phase]",
  "lbl_bracket_none": "[Aucun]",
  "lbl_brief": "Présentation",
  "lbl_budget": "Budget",
  "lbl_budget_amounts_filter_display_name": "Budgets : montants",
  "lbl_budget_days_filter_display_name": "Budgets : jours",
  "lbl_budget_hours_filter_display_name": "Budgets : heures",
  "lbl_budget_report": "Rapport sur le budget",
  "lbl_budgets": "Budgets",
  "lbl_calculating": "calcul en cours",
  "lbl_cancel": "Annuler",
  "lbl_cancelled": "annulé",
  "lbl_capacity": "Capacité",
  "lbl_category": "Catégorie",
  "lbl_cell_phone": "Téléphone portable",
  "lbl_certificate_details": "Détails du certificat",
  "lbl_change_to_unmanaged_confirm": "Cet utilisateur ne sera plus en mesure de suivre et d’approuver le temps et les dépenses, et ne sera plus affiché dans la vue des capacités et dans les rapports.",
  "lbl_change_to_managed": "Changer en Gérée",
  "lbl_change_to_unmanaged": "Changer en Non gérée",
  "lbl_choose_file": "Choisir un fichier",
  "lbl_client": "Client",
  "lbl_client_name": "Nom du client",
  "lbl_close": "Fermer",
  "lbl_company_name": "Nom de l’entreprise",
  "lbl_complete": "Terminé",
  "lbl_confirm": "Confirmer",
  "lbl_confirmed": "Confirmé",
  "lbl_contact_info": "Coordonnées",
  "lbl_contact_support": "Contacter l’assistance",
  "lbl_contractor": "Sous-traitant",
  "lbl_copy": "Copier",
  "lbl_count_of_total": "{{count}} sur {{total}}",
  "lbl_create": "Créer",
  "lbl_create_new_request": "* Créer une nouvelle demande",
  "lbl_current": "Actuel",
  "lbl_current_work": "Travail actuel",
  "lbl_custom_date_range": "Plage de dates personnalisée",
  "lbl_custom_field": "Champ personnalisé",
  "lbl_date": "Date",
  "lbl_date_string_at_time_string": "{{date}} à {{time}}",
  "lbl_date_string": "Chaîne de dates",
  "lbl_dates": "Dates",
  "lbl_dates_for_submitted_time": "Dates pour l’heure de soumission",
  "lbl_day_friday": "Vendredi",
  "lbl_day_monday": "Lundi",
  "lbl_day_saturday": "Samedi",
  "lbl_day_sunday": "Dimanche",
  "lbl_day_thursday": "Jeudi",
  "lbl_day_tuesday": "Mardi",
  "lbl_day_wednesday": "Mercredi",
  "lbl_days": "Jours",
  "lbl_deactivated": "Désactivé",
  "lbl_december": "Décembre",
  "lbl_delete": "Supprimer",
  "lbl_delete_adv_report_modal": "Supprimer le rapport ?",
  "lbl_delete_saved_report_modal": "Supprimer le rapport enregistré ?",
  "lbl_description": "Description",
  "lbl_difference": "Différence",
  "lbl_difference_from_past_scheduled": "Différence par rapport au programme précédent",
  "lbl_disabled": "désactivé",
  "lbl_discipline": "Discipline",
  "lbl_display_colon": "Afficher :",
  "lbl_done": "Terminé",
  "lbl_duration": "Durée",
  "lbl_duration_working_days": "Durée (jours ouvrables)",
  "lbl_edit_profile": "Modifier le profil",
  "lbl_edit_project_information": "Modifier les informations du projet",
  "lbl_edit_projects": "Modifier les projets",
  "lbl_email": "E-mail",
  "lbl_email_must_be_less_than_255_characters.": "L’e-mail doit comporter moins de 255 caractères.",
  "lbl_employee_number": "Numéro d’employé",
  "lbl_enabled": "activé",
  "lbl_end": "Fin",
  "lbl_end_date": "Date de fin",
  "lbl_end_lc_date": "Date de fin",
  "lbl_ends_at": "Se termine le",
  "lbl_enter_a_name": "Saisir un nom",
  "lbl_enter_rejection_reason": "Saisir le motif du rejet",
  "lbl_entry_type": "Type d’entrée",
  "lbl_everyone": "Tout le monde",
  "lbl_everything": "Tout",
  "lbl_expense": "Dépense",
  "lbl_expenses": "Dépenses",
  "lbl_export": "Exporter",
  "lbl_export_people_list": "Exporter la liste des personnes",
  "lbl_export_report_to_csv": "Exporter ce rapport au format CSV",
  "lbl_export_time_entries_for_quickBooks": "Exporter les entrées de temps pour QuickBooks",
  "lbl_export_underlying_data_as_csv": "Exporter les données sous-jacentes au format CSV",
  "lbl_failed": "Échec",
  "lbl_february": "Février",
  "lbl_fees": "Frais",
  "lbl_filter": "Filtre",
  "lbl_filtered": "Filtré",
  "lbl_filters": "Filtres",
  "lbl_first_day_of_work": "Premier jour de travail",
  "lbl_first_group_by": "Grouper d’abord par",
  "lbl_first_name": "Prénom",
  "lbl_first_name_lc": "Prénom",
  "lbl_friday_abbr_uc": "V",
  "lbl_future": "Ultérieur",
  "lbl_future_scheduled": "Planifié ultérieurement",
  "lbl_global_modules": "Modules globaux",
  "lbl_grid_view": "Mode Grille",
  "lbl_hours": "Heures",
  "lbl_hours_filter_display_name": "Temps et frais : heures",
  "lbl_hours_per_day": "Heures par jour",
  "lbl_idp_metadata_url": "URL des métadonnées IdP",
  "lbl_iframe_view_content_require": "L’affichage de ce contenu nécessite une licence Resource Management by Smartsheet.",
  "lbl_in_the_office": "Au bureau",
  "lbl_incurred": "Facturé",
  "lbl_internal": "Interne",
  "lbl_invited": "Invité",
  "lbl_january": "Janvier",
  "lbl_july": "Juillet",
  "lbl_june": "Juin",
  "lbl_just_now": "À l’instant",
  "lbl_last_and_next_ninety_days": "Derniers et prochains 90 jours",
  "lbl_last_day_of_work": "Dernier jour de travail",
  "lbl_last_month": "Le mois dernier",
  "lbl_last_name": "Nom",
  "lbl_last_name_lc": "Nom",
  "lbl_last_quarter": "Le trimestre dernier",
  "lbl_last_thirty_days": "30 derniers jours",
  "lbl_last_week": "La semaine dernière",
  "lbl_learn_lc_more": "En savoir plus",
  "lbl_learn_more": "En savoir plus",
  "lbl_leave": "Quitter",
  "lbl_leave_type": "Type de congé",
  "lbl_licensed": "Sous licence",
  "lbl_licensed_users": "Utilisateurs sous licence",
  "lbl_linked_smartsheet_project": "Projet Smartsheet lié",
  "lbl_loading": "Chargement",
  "lbl_loading_ellipsis": "Chargement…",
  "lbl_loading_project_ellipsis": "Chargement du projet en cours…",
  "lbl_location": "Emplacement",
  "lbl_log_requested": "Journal demandé",
  "lbl_make_active": "Rendre actif",
  "lbl_make_licensed_user": "Convertir en utilisateur sous licence",
  "lbl_manage_members": "Gérer les membres",
  "lbl_managed_resource": "Personnes internes",
  "lbl_manual": "manuel",
  "lbl_manual_capitalize": "Manuel",
  "lbl_march": "Mars",
  "lbl_matching_people": "Personnes correspondantes",
  "lbl_matching_person": "personne correspondante",
  "lbl_matching_placeholders": "Espaces réservés correspondants",
  "lbl_matching_project": "projet correspondant",
  "lbl_matching_projects": "projets correspondants",
  "lbl_may": "Mai",
  "lbl_medium": "Moyenne",
  "lbl_members": "Membres",
  "lbl_mobile": "Mobile",
  "lbl_module": "Module :",
  "lbl_module_name": "Nom du module",
  "lbl_monday_abbr_uc": "L",
  "lbl_month": "Mois",
  "lbl_more_assignments": "plus d’affectations",
  "lbl_must_be_between_8–40_characters.": "Doit comporter entre 8 et 40 caractères.",
  "lbl_my_expenses": "Mes dépenses",
  "lbl_my_projects": "Mes projets",
  "lbl_my_time": "Mon temps",
  "lbl_my_time_and_expenses": "Mon temps et mes dépenses",
  "lbl_my_views": "Mes vues",
  "lbl_new_project": "Nouveau projet",
  "lbl_next_ninety_days": "90 prochains jours",
  "lbl_next_sixty_days": "60 prochains jours",
  "lbl_next_thirty_days": "30 prochains jours",
  "lbl_next_week": "La semaine prochaine",
  "lbl_no_assignments": "Aucune affectation",
  "lbl_no_description_provided": "Aucune description fournie.",
  "lbl_no_imports_found": "Aucune importation trouvée",
  "lbl_non_billable": "Non facturable",
  "lbl_none": "Aucun",
  "lbl_non_matching": "Sans correspondance",
  "lbl_not_using_smar": "N’utilise pas l’authentification Smartsheet",
  "lbl_not_using_sso": "N’utilise pas l’authentification unique (SSO)",
  "lbl_not_yet_invited": "Pas encore invité",
  "lbl_notes": "Remarques",
  "lbl_november": "Novembre",
  "lbl_october": "Octobre",
  "lbl_office": "Bureau",
  "lbl_office_phone": "Téléphone du bureau",
  "lbl_office_status": "Statut de présence",
  "lbl_on_the_road": "En déplacement",
  "lbl_or_select_a_specific_phase": "Ou sélectionner une phase spécifique",
  "lbl_or_select_phases": "Ou sélectionner une ou plusieurs phases",
  "lbl_or_select_specific_phases": "Ou sélectionner une ou plusieurs phases spécifiques",
  "lbl_other_projects": "Autres projets",
  "lbl_out_of_office": "Absent(e)",
  "lbl_out_sick": "En congé maladie",
  "lbl_past": "Précédent",
  "lbl_pending": "En attente",
  "lbl_pending_approval": "En attente d’approbation",
  "lbl_people": "Personnes",
  "lbl_people_filters": "Filtres de personnes",
  "lbl_people_scheduler": "Planificateur de personnes",
  "lbl_people_tags": "Balises de personnes",
  "lbl_percentage": "Pourcentage",
  "lbl_persistent_navigation": "Navigation persistante",
  "lbl_person": "Personne",
  "lbl_phase": "Phase",
  "lbl_phase_dates": "Dates de la phase",
  "lbl_phase_dates_colon": "Dates de la phase :",
  "lbl_phase_four": "Phase quatre",
  "lbl_phase_lc_name": "Nom de la phase",
  "lbl_phase_name": "Nom de la phase",
  "lbl_phase_name_long_enough_to_wrap": "Nom de phase assez long pour renvoyer à la ligne",
  "lbl_phase_no_assignments_worklist": "Cette phase n’a pas d’affectations, mais elle apparaîtra toujours dans votre liste de travail, car elle comporte des entrées de temps.",
  "lbl_phase_one": "Phase une",
  "lbl_phase_status": "Statut de la phase",
  "lbl_phase_three": "Phase trois",
  "lbl_phase_two": "Phase deux",
  "lbl_phases_alert_add": "Les phases ne peuvent être ajoutées qu’à partir de votre <0>feuille connectée</0>.",
  "lbl_phases_dates_edit": "Les dates des phases ne peuvent être modifiées qu’en changeant leurs dates d’attribution ou à partir de votre <0>feuille connectée</0>.",
  "lbl_phases_names_edit": "Les noms des phases ne peuvent être modifiés qu’à partir de votre <0>feuille connectée</0>.",
  "lbl_phases_delete": "Les phases ne peuvent être supprimées qu’en retirant toutes leurs attributions ou à partir de votre <0>feuille connectée</0>.",
  "lbl_placeholder": "Espace réservé",
  "lbl_placeholders": "Espaces réservés",
  "lbl_please_sign_in": "Veuillez vous connecter pour continuer",
  "lbl_plus_allocation": "+ Allocation",
  "lbl_portfolio_editor": "Éditeur de portefeuille",
  "lbl_portfolio_reporter": "Rapporteur de portefeuille",
  "lbl_portfolio_viewer": "Spectateur de portefeuille",
  "lbl_post": "Publier",
  "lbl_project": "Projet",
  "lbl_project_access": "Accès au projet",
  "lbl_project_code": "Code du projet",
  "lbl_project_dates": "Dates du projet",
  "lbl_project_dates_colon": "Dates du projet :",
  "lbl_project_description": "Description du projet",
  "lbl_project_duration": "Durée du projet",
  "lbl_project_editor": "Éditeur de projet",
  "lbl_project_filters": "Filtres de projet",
  "lbl_project_members": "Membres du projet",
  "lbl_project_name": "Nom du projet",
  "lbl_project_owner": "Propriétaire du projet",
  "lbl_project_owners": "Propriétaires de projets",
  "lbl_project_portfolio": "Portefeuille de projets",
  "lbl_project_properties": "Propriétés du projet",
  "lbl_project_settings": "Paramètres du projet",
  "lbl_project_state": "État du projet",
  "lbl_project_tags": "Balises du projet",
  "lbl_project_title": "Intitulé du projet",
  "lbl_project_type": "Type de projet",
  "lbl_projects": "Projets",
  "lbl_purchase_more": "acheter plus",
  "lbl_recents": "Récents",
  "lbl_recent_requests": "Demandes récentes",
  "lbl_refresh_status": "Actualiser le statut",
  "lbl_refresh_type": "Actualiser le type",
  "lbl_refreshing_data": "Actualisation des données…",
  "lbl_reject": "Refuser",
  "lbl_rejected": "Refusé",
  "lbl_remaining": "Restant",
  "lbl_remove": "Supprimer",
  "lbl_report_data_refresh_status_error": "Échec de l’actualisation des données le {{date}} à {{time}}",
  "lbl_report_data_refresh_status_error_please_try_again": "Une erreur est survenue lors de la création de votre rapport avancé. Veuillez réessayer. Si ce problème persiste, veuillez contacter l’assistance.",
  "lbl_report_data_refresh_status_loading": "Mise à jour de votre rapport avancé",
  "lbl_report_data_refresh_status_success": "Dernière actualisation le {{date}} à {{time}}",
  "lbl_report_data_refresh_status_warning_auto": "Les modifications seront synchronisées lors de la prochaine actualisation des données",
  "lbl_report_data_refresh_status_warning_manual": "Actualiser le rapport avancé pour synchroniser les modifications",
  "lbl_report_name_colon": "Nom du rapport :",
  "lbl_report_title_by_criteria": "{{report}} par {{criteria}}",
  "lbl_reports": "Rapports",
  "lbl_request_an_audit_log": "Demander un journal d’audit",
  "lbl_request_audit_log": "Demander le journal d’audit",
  "lbl_requester": "Demandeur",
  "lbl_resend_invitation": "Renvoyer l’invitation",
  "lbl_reset": "Réinitialiser",
  "lbl_reset_filters": "Réinitialiser les filtres",
  "lbl_reset_password": "Réinitialiser le mot de passe",
  "lbl_reset_password_for": "Réinitialiser le mot de passe pour",
  "lbl_reset_report": "Réinitialiser le rapport",
  "lbl_resource-request_test_page": "Page de test des demandes de ressources",
  "lbl_resource_management_by_smartsheet": "Resource Management by Smartsheet",
  "lbl_resourcing_admin": "Administrateur Resource Management",
  "lbl_retry": "Réessayer",
  "lbl_cannot_connect_to_smar": "Désolé, nous n’avons pas pu connecter le projet à Smartsheet.",
  "lbl_connecting_to_smar": "Connexion à Smartsheet",
  "lbl_close_banner": "Fermer la bannière",
  "lbl_role": "Rôle",
  "lbl_saturday_abbr_uc": "S",
  "lbl_save": "Enregistrer",
  "lbl_save_changes": "Enregistrer les modifications",
  "lbl_save_current_view": "Enregistrer la vue actuelle",
  "lbl_save_this_report_to_your_personal_page": "Enregistrer ce rapport sur votre page personnelle",
  "lbl_saving": "Enregistrement",
  "lbl_schedule": "Programmer",
  "lbl_scheduled": "Programmé",
  "lbl_search": "Recherche",
  "lbl_search_ellipsis": "Recherche…",
  "lbl_select_date_range_to_see_entries": "Sélectionner une plage de dates pour voir toutes les entrées dans cette période",
  "lbl_select_people_to_add_to_project": "Sélectionnez les personnes que vous souhaitez ajouter à ce projet.",
  "lbl_select_phases": "Sélectionner une ou plusieurs phases",
  "lbl_select_project": "Sélectionner un projet",
  "lbl_select_project_or_phase": "Sélectionner un projet ou une phase",
  "lbl_select_project_owner": "Sélectionner le propriétaire d’un projet",
  "lbl_select_team_member": "Sélectionner un membre d’équipe :",
  "lbl_select_the_entire_project": "Sélectionner le projet entier",
  "lbl_send": "Envoyer",
  "lbl_send_invitation": "Envoyer une invitation",
  "lbl_september": "Septembre",
  "lbl_set_a_status": "Définir un statut",
  "lbl_set_allocation": "Définir une allocation :",
  "lbl_set_to_today": "Affecter à aujourd’hui",
  "lbl_share": "Partager",
  "lbl_shared_views": "Vues partagées",
  "lbl_show": "Afficher",
  "lbl_show_no_phases": "Afficher : aucune phase",
  "lbl_sign_out_slo_target_url": "URL cible de la déconnexion unique (SLO)",
  "lbl_signin_sso_target_url": "URL cible de la connexion unique (SSO)",
  "lbl_signup_password": "Mot de passe (plus de 8 caractères)",
  "lbl_skipped": "Éléments ignorés",
  "lbl_skipped_colon": "Éléments ignorés : {{number}}",
  "lbl_small": "Petit",
  "lbl_something_went_wrong_ellipsis": "Un problème s’est produit…",
  "lbl_sort_by": "Trier par",
  "lbl_sso_for_resource_management": "Authentification unique (SSO) pour Resource Management",
  "lbl_start": "Début",
  "lbl_start_date": "Date de début",
  "lbl_start_lc_date": "Date de début",
  "lbl_starts_at": "Commence le",
  "lbl_status": "Statut",
  "lbl_status_cancelled": "ANNULÉ",
  "lbl_status_failed": "ÉCHEC",
  "lbl_status_message": "Message de statut",
  "lbl_status_queued": "MIS EN FILE D’ATTENTE",
  "lbl_status_running": "EXÉCUTION",
  "lbl_status_succeeded": "RÉUSSI",
  "lbl_submitted_time_for_approval": "Heure de soumission pour approbation",
  "lbl_sunday_abbr_uc": "D",
  "lbl_switch_to_another_account": "Basculer vers un autre compte :",
  "lbl_tag": "Balise",
  "lbl_tags": "Balises",
  "lbl_target_utilization": "Objectif d’utilisation",
  "lbl_tasks": "Tâches",
  "lbl_team_member": "Membre d’équipe",
  "lbl_team_member_type": "Type de membre d’équipe",
  "lbl_tentative": "Provisoire",
  "lbl_terms": "modalités",
  "lbl_the_selected_project_was": "Le projet sélectionné était ",
  "lbl_then_group_by": "Grouper ensuite par",
  "lbl_these_results_only_include_projects_that_you_have_permission_to_see": "Ces résultats incluent uniquement les projets que vous êtes autorisés à voir.",
  "lbl_this_month": "Ce mois-ci",
  "lbl_this_quarter": "Ce trimestre",
  "lbl_this_week": "Cette semaine",
  "lbl_this_year": "Cette année",
  "lbl_thursday_abbr_uc": "J",
  "lbl_time": "Temps",
  "lbl_time_and_expenses": "Temps et dépenses",
  "lbl_time_and_fee_categories": "Catégories Temps et frais",
  "lbl_time_and_fees": "Temps et frais",
  "lbl_time_and_fees_report": "Rapport sur le temps et les frais",
  "lbl_time_approval_dashboard": "Tableau de bord d’approbation du temps",
  "lbl_time_budget_unit_of_measurement": "Unité de mesure du budget de temps",
  "lbl_time_fees_amounts_and_hours_filter_display_name": "Temps et frais : heures et montants",
  "lbl_time_fees_amounts_display_name": "Temps et frais : montants",
  "lbl_time_fees_days_filter_display_name": "Temps et frais : jours",
  "lbl_time_frame": "Calendrier",
  "lbl_time_tracking": "Suivi du temps",
  "lbl_today": "Aujourd’hui",
  "lbl_tomorrow": "Demain",
  "lbl_total": "Total",
  "lbl_tuesday_abbr_uc": "M",
  "lbl_type": "Type",
  "lbl_unarchive": "Désarchiver",
  "lbl_unarchive_project": "Désarchiver le projet",
  "lbl_unassigned": "Non affecté",
  "lbl_unconfirmed": "Non confirmé",
  "lbl_unmanaged": "Non gérée",
  "lbl_unmanaged_resource": "Ressource non gérée",
  "lbl_unmanaged_resources": "Ressources non gérées",
  "lbl_unlock": "Déverrouiller",
  "lbl_upcoming": "À venir",
  "lbl_update": "Mettre à jour",
  "lbl_update_status": "Mettre à jour le statut : ",
  "lbl_updated": "Actualisé(e)(s)",
  "lbl_updating": "Mise à jour",
  "lbl_utilization": "Utilisation",
  "lbl_utilization_target": "Utilisation cible",
  "lbl_vacation": "Vacances",
  "lbl_values": "Valeurs",
  "lbl_view": "Afficher",
  "lbl_view_resourcing_schedule": "Voir le calendrier des ressources",
  "lbl_wednesday_abbr_uc": "M",
  "lbl_week": "Semaine",
  "lbl_work": "Travail",
  "lbl_work_email": "E-mail de travail",
  "lbl_work_item": "Élément de travail",
  "lbl_work_status": "Statut du travail",
  "lbl_working_from_home": "Travail à domicile",
  "lbl_worklist": "Liste de travail",
  "lbl_your_projects_includes_the_following_properties": "Vos projets : inclut les propriétés suivantes",
  "msg_account_owner_change_attempt": "Changez le propriétaire du compte dans les paramètres du compte avant d’archiver cet utilisateur.",
  "msg_activated_smartsheet_auth": "L’authentification Smartsheet sera activée. Cette méthode d’authentification prend effet immédiatement et ne peut pas être désactivée",
  "msg_add_assignment_to_project": "Ajouter une affectation au projet",
  "msg_add_notes_placeholder": "Ajoutez des remarques, des détails, des liens, etc.",
  "msg_add_phase_to_project": "Ajouter une phase au projet",
  "msg_add_project_description": "Ajouter une description du projet",
  "msg_add_save_current_view": "Ajouter un enregistrement de la vue actuelle",
  "msg_add_team_member_to_project": "Ajouter un membre d’équipe au projet",
  "msg_app_project_members_disabled_tooltip": "Vous êtes propriétaire de ces projets ou ils vous sont attribués.",
  "msg_app_project_members_enabled_tooltip": "Vous êtes propriétaire de ces projets, vous en êtes membre ou ils vous sont attribués.",
  "msg_audit_log_info": "Important : un journal d’audit vous donne un aperçu précis au moment où vous le demandez et peut devenir obsolète si des modifications sont apportées aux entrées de temps ou de dépenses après la génération du journal demandé. Pour obtenir les informations les plus récentes, demandez un nouveau journal ci-dessus.",
  "msg_auto_provision_checkbox": "Lorsque le provisionnement automatique des utilisateurs est actif, il attribue automatiquement des licences et le niveau de permission sélectionné aux utilisateurs. Cela permet également aux utilisateurs de contourner le processus d’invitation par e-mail.",
  "msg_bracket_search_to_add_filters": "[Rechercher pour ajouter des filtres]",
  "msg_cannot_archive_account_owner": "Vous ne pouvez pas archiver le propriétaire du compte",
  "msg_cert_requirement": "Veuillez utiliser un fichier codé en PEM avec une extension de fichier CER, CRT ou PEM.",
  "msg_cert_upload_complete": "Chargement du certificat terminé",
  "msg_checkbox_list_aria": "Cocher/décocher pour sélectionner plusieurs options ou cliquer sur un bouton de sélection pour n’en choisir qu’une seule",
  "msg_choose_permission_type": "Choisir le type de permission de l’utilisateur",
  "msg_choose_property_to_sort_by": "Sélectionnez le type de propriété par laquelle effectuer le tri.",
  "msg_close": "Fermer",
  "msg_close_and_redirect_to_profile_page": "Fermer et rediriger vers la page de profil",
  "msg_count_matches_found": "{{count}} correspondances trouvées",
  "msg_copy_url_to_share_report": "Copiez l’URL ci-dessous pour partager ce rapport :",
  "msg_customize_report_prompt": "Personnalisez votre rapport à l’aide des options proposées à gauche. Lorsque vous êtes prêt, choisissez une option ci-dessous.",
  "msg_delete_adv_report_modal": "Cette action interrompra la mise à jour des données du rapport, mais ne supprimera pas le rapport sous-jacent et la feuille source dans l’espace de travail de destination.",
  "msg_delete_assignment": "Supprimer l’affectation",
  "msg_delete_saved_report": "Supprimer le rapport enregistré",
  "msg_delete_saved_report_modal": "Cette action ne peut pas être annulée, mais vous pouvez créer un nouveau rapport avec les mêmes options.",
  "msg_delete_view": "Supprimer la vue",
  "msg_delete_work_item_warning": "Voulez-vous vraiment supprimer définitivement cet élément de travail ? Toutes les tâches et affectations associées seront supprimées. Cette action est irréversible.",
  "msg_edit_allocation": "Modifier l’allocation",
  "msg_edit_end_date": "Modifier la date de fin",
  "msg_edit_start_date": "Modifier la date de début",
  "msg_edit_status": "Modifier le statut",
  "msg_edit_team_member": "Modifier le membre d’équipe",
  "msg_edit_work_item": "Modifier l’élément de travail",
  "msg_enable_bulk_editing_requirements": "Pour activer la modification en masse, assurez-vous que le filtre Propriétaire du projet est défini sur vous-même et que le filtre État du projet est défini sur Actif.",
  "msg_enable_module_for_existing_organizations": "Activer le module pour les organisations existantes",
  "msg_enable_module_for_new_organizations": "Activer le module pour les nouvelles organisations",
  "msg_filter_is_locked_to_the_projects_you_have_permission_to_report_on": "Ce filtre est limité aux projets pour lesquels vous avez l’autorisation d’établir un rapport.",
  "msg_filter_is_locked_to_the_projects_you_own_or_are_assigned_to": "Ce filtre est limité aux projets que vous possédez ou auxquels vous êtes affecté.",
  "msg_grid_view_aria": "Mode Grille : activez cette option pour choisir le mode Grille.",
  "msg_hold_on_loading_ellipsis": "Un instant, chargement en cours…",
  "msg_infinite_loader_aria_label": "Cocher/décocher pour sélectionner plusieurs options ou cliquer sur un bouton de sélection pour n’en choisir qu’une seule",
  "msg_invalid_cert": "Désolé, le certificat que vous avez sélectionné n’est pas un type de fichier valide.",
  "msg_list_view_and_project_field_selector": "Mode Liste et sélecteur de champs de projet : activez cette option pour choisir le mode Liste et sélectionner les champs de projet disponibles.",
  "msg_loading_approval_status": "Chargement du statut d’approbation…",
  "msg_loading_page": "Page de chargement",
  "msg_loading_project_timeline_ellipsis": "Chargement du planning de projet…",
  "msg_loading_project_users_ellipsis": "Chargement des utilisateurs du projet…",
  "msg_make_bulk_project_archive_warning": "L’archivage de ces projets mettra fin aux affectations en cours et supprimera les affectations futures. Cette action est irréversible.",
  "msg_make_bulk_project_edit_confirmation": "Êtes-vous sûr de vouloir procéder à cette modification ?",
  "msg_make_bulk_project_edit_warning": "Cette opération peut prendre plusieurs minutes et ne peut pas être annulée.",
  "msg_manage_project_membership_and_project_access_levels_based_on_their_account_permissions": "Gérez les affectations au projet et les niveaux d’accès au projet. Les niveaux d’accès sont exclusifs à ce projet. Certaines personnes peuvent apporter des modifications sans affectation en fonction des <1>autorisations de leur compte</1>.",
  "msg_need_a_minute_ellipsis": "Oups ! Un instant…",
  "msg_new_work_item_title": "Intitulé du nouvel élément de travail",
  "msg_no_assignments": "Aucune affectation",
  "msg_no_matches_found": "Aucune correspondance trouvée",
  "msg_no_people_assigned_to_project": "Aucune personne n’est affectée à ce projet.",
  "msg_no_phases_to_display": "Aucune phase à afficher",
  "msg_no_projects_match_your_criteria": "Aucun projet ne correspond à vos critères",
  "msg_no_propertyies_to_display": "Aucune propriété à afficher",
  "msg_no_recent_requests": "Aucune demande récente",
  "msg_no_results_to_display": "Aucun résultat à afficher",
  "msg_no_work_for_this_project": "Il n’y a pas de travail en cours pour ce projet.",
  "msg_not_available": "non disponible",
  "msg_one_match_found": "1 correspondance trouvée",
  "msg_owners_of_projects_permission_to_report_on": "Les propriétaires des projets pour lesquels vous avez l’autorisation d’établir un rapport.",
  "msg_people_field_selector": "Sélecteur de champs de personnes : activez cette option pour sélectionner les champs de données disponibles.",
  "msg_people_settings_info": "Les utilisateurs sous licence peuvent suivre le temps et les tâches sur des affectations planifiées et peuvent éventuellement être invités à se connecter. Les Personnes internes peuvent être programmées sur des projets, mais ne peuvent pas suivre le temps et n’ont pas d’accès de connexion.",
  "msg_people_list_licensed_tooltip": "En fonction des autorisations, les utilisateurs sous licence peuvent suivre et approuver le temps, planifier des projets, gérer le personnel, modifier les profils utilisateur et générer des rapports.",
  "msg_people_list_managed_tooltip": "Mettez à niveau les ressources gérées pour bénéficier des fonctionnalités de suivi du temps.",
  "msg_people_list_unmanaged_tooltip": "Mettez à niveau les ressources non gérées pour les inclure dans la vue des capacités et dans les rapports.",
  "msg_people_list_archived_tooltip": "Les affectations des utilisateurs archivés restent sur la page du projet pour être réaffectées à une autre personne ou à un autre espace réservé.",
  "msg_phase_picker_copy": "Copier dans le projet ou la phase",
  "msg_print_brief_page": "Imprimer la page de présentation",
  "msg_profile_banner": "Bannière du profil",
  "msg_profile_information": "Informations sur le profil",
  "msg_profile_navigation": "Navigation dans le profil",
  "msg_profile_navigation_menu": "Menu de navigation du profil",
  "msg_project_members_tooltip": "Vous êtes propriétaire de ces projets, vous en êtes membre ou ils vous sont attribués.",
  "msg_project_navigation": "Navigation dans le projet",
  "msg_project_members_description": "L’appartenance au projet est propre à ce projet. Certains utilisateurs peuvent accéder à ce projet sans en être membre en fonction de leurs <0>autorisations de compte</0>.",
  "msg_reassign_search_placeholder": "Rechercher un nom, une discipline, etc.",
  "msg_refine_search_to_see_more_users": "Affiner la recherche pour voir plus d’utilisateurs correspondants",
  "msg_rejection_reason_required": "Un motif est obligatoire pour le rejet.",
  "msg_remove_archived_project_state_to_enable_editing": "Supprimez la mention « Archivé » du filtre État du projet pour activer la modification.",
  "msg_remove_assignment_confirmation": "Voulez-vous vraiment supprimer cette affectation ?",
  "msg_report_data_refresh_err_detail_cell_limit_exceeded": "Nous n’avons pas pu actualiser les données de votre rapport, car il contient 3 000 cellules de plus que la limite. Réduisez la taille de votre rapport en ajustant les options dans le panneau de gauche. <1>En savoir plus.</1>",
  "msg_report_data_refresh_failed_due_to_column_name_change": "Une ou plusieurs colonnes de votre flux de travail ont changé de nom ou ont été supprimées. Veuillez mettre à jour la mise en correspondance de vos colonnes et réessayez.",
  "msg_report_data_refresh_failed_sheet_deleted": "Une erreur s’est produite lors de l’actualisation de votre rapport avancé, car la feuille associée a été supprimée. Veuillez récupérer la feuille concernée ou créer un nouveau rapport avancé.",
  "msg_report_data_refresh_err_detail_generic": "Une erreur est survenue lors de l’actualisation des données de votre rapport avancé. Veuillez créer un nouveau rapport avancé.",
  "msg_report_data_refresh_err_detail_row_limit_exceeded": "Nous n’avons pas pu actualiser les données de votre rapport, car il contient 300 lignes de plus que la limite. Réduisez la taille de votre rapport en ajustant les options dans le panneau de gauche. <1>En savoir plus.</1>",
  "msg_report_data_refresh_err_report_no_data": "Votre rapport ne contient aucune donnée. Veuillez réexécuter votre rapport en ajustant les options dans le panneau de gauche.",
  "msg_report_data_refresh_err_report_no_data_tooltip": "Avertissement : votre rapport avancé n’a pas été mis à jour, car la dernière actualisation n’a renvoyé aucun résultat.",
  "msg_report_data_refresh_err_detail_sheet_access": "Une erreur est survenue lors de l’actualisation des données de votre rapport avancé. Veuillez actualiser les autorisations de la feuille ou créer un nouveau rapport avancé.",
  "msg_report_is_too_big_to_load": "Désolé, ce rapport est trop volumineux pour être chargé",
  "msg_save_report_reports_page": "Enregistrer ce rapport sur la page Rapports",
  "msg_search_contacts": "Rechercher des contacts",
  "msg_settings_people_manage_info": "Gérez l’<1>accès à la connexion</1> et les <3>autorisations</3> pour les personnes de votre compte.<br /><5> Contactez-nous ici</5> pour modifier votre abonnement.",
  "msg_share_report": "Copiez l’URL via le bouton de copie ci-dessous pour partager ce rapport :",
  "msg_sheet_collaborators": "Les collaborateurs de feuilles sur des projets liés ne sont pas affichés ici. Pour mettre à jour le partage de feuilles ou ajouter des collaborateurs de feuilles, veuillez gérer ces paramètres dans Smartsheet. <0>En savoir plus</0>",
  "msg_signup_loading_transition": "Nous configurons votre compte d’essai gratuit.",
  "msg_sort_by_last_name": "Trier par : nom",
  "msg_specify_budget_in_unit": "Spécifier ce budget en {{curUnit}}",
  "msg_sso_configuration_info": "La configuration automatique recommandée simplifie la configuration de l’authentification unique (SSO) et prend en charge plusieurs certificats par défaut. La configuration manuelle ne prend en charge qu’un seul certificat à la fois.",
  "msg_sso_enabled": "L’authentification unique (SSO) est actuellement activée pour votre compte",
  "msg_sso_info": "L’utilisation de l’authentification unique (SSO) pour la gestion des mots de passe et le provisionnement des utilisateurs permet à l’entreprise de mieux contrôler l’accès à Resource Management et de s’assurer que les bonnes personnes se connectent en toute sécurité au service.",
  "msg_sso_login_failure": "Désolé, nous n’avons pas pu vous connecter à Resource Management by Smartsheet.",
  "msg_sso_not_configured": "L’authentification unique (SSO) n’a pas été configurée pour votre compte",
  "msg_sso_unconfigurable": "L’authentification unique (SSO) ne peut pas être configurée directement dans Resource Management, car votre organisation utilise actuellement l’authentification Smartsheet. Les paramètres SSO peuvent être gérés dans le centre d’administration de Smartsheet",
  "msg_terms": "Modalités",
  "msg_thanks": "Merci !",
  "msg_there_are_no_approvals_matching_your_current_filters": "Aucune approbation ne correspond à vos filtres actuels.",
  "msg_to_inactive_project": "à un projet inactif",
  "msg_transfer_project_ownership_to_remove_user": "{{member.name}} est le propriétaire du projet et ne peut être supprimé. Transférez la propriété dans l’onglet Paramètres du projet.",
  "msg_type_and_press_enter_to_add_task": "Effectuez une saisie et appuyez sur Entrée pour ajouter une tâche",
  "msg_cant_edit_remove_unmanaged_resource": "{{member.name}} est une ressource non gérée. Effectuez une mise à niveau dans les paramètres du compte pour la modifier ou la retirer de ce projet.",
  "msg_cant_add_unmanaged_resource": "{{member.name}} est une ressource non gérée. Effectuez une mise à niveau dans les paramètres du compte pour l’ajouter à ce projet.",
  "msg_upload_failed": "Échec du chargement",
  "msg_upload_succeeded": "Chargement terminé",
  "msg_user_default_login_tooltip": "Cette personne ne s’est pas encore connectée à l’aide de l’authentification unique (SSO). Pour basculer, demandez-lui de se déconnecter et de se reconnecter via SSO.",
  "msg_user_smar_login_tooltip": "Cette personne ne s’est pas encore connectée à l’aide de l’authentification Smartsheet. Pour basculer, demandez-lui de se déconnecter et de se reconnecter via l’authentification Smartsheet.",
  "msg_wait_while_load_assignments": "Veuillez patienter pendant que nous chargeons les affectations",
  "msg_welcome_to_resource_management_by_smartsheet": "Bienvenue dans Resource Management by Smartsheet",
  "msg_work_item_description_placeholder": "Décrivez le travail, l’affectation, etc.",
  "msg_you_dont_own_these_projects_and_theyre_not_assigned_to_you": "Ces projets ne vous appartiennent pas ou ne vous sont pas attribués.",
  "msg_saved_form_already_exists": "existe déjà. Voulez-vous le remplacer ?",
  "msg_you_do_not_have_admin_access": "Assurez-vous d’avoir des autorisations d’administrateur sur l’espace de travail que vous recherchez. Vous pouvez demander l’accès ou créer un nouvel espace de travail de destination dans <0>Smartsheet</0>.",
  "msg_no_matching_results": "Aucun résultat correspondant",
  "msg_report_filter_list": "Vous êtes dans la liste des filtres de rapport. Choisissez les données à inclure dans votre rapport.",
  "msg_report_filter_show_group": "Vous êtes dans le groupe d’affichage des filtres de rapport. Choisissez les options de chaque catégorie que vous souhaitez afficher (toutes ou aucune) en cochant/décochant les cases correspondantes en cas de choix multiple ou en activant un bouton d’ajout pour n’en choisir qu’une seule.",
  "lbl_getting_information": "Obtention d’informations",
  "lbl_actions": "Actions",
  "lbl_delete_phase": "Supprimer la phase",
  "lbl_chars_left": "restants",
  "lbl_set_to": "Affecter la valeur",
  "msg_no_ppl_match": "Aucune personne ne correspond à vos critères",
  "lbl_user_info_summary": "Synthèse des informations sur l’utilisateur",
  "lbl_saved_reports": "Rapports enregistrés",
  "lbl_no_active_assignments": "Aucune affectation active.",
  "msg_entries_not_shown": "Les entrées liées aux projets sans propriétaire ne sont pas affichées",
  "msg_provide_note": "Ajoutez une note concernant cette approbation. Cette action est obligatoire pour le rejet.",
  "msg_taking_longer_than_expected": "Cela prend plus de temps que prévu… Un instant",
  "msg_want_to_make_adjustments": "Vous souhaitez procéder à des ajustements ou accélérer les choses ? Vous pouvez continuer à utiliser les filtres pour affiner ou réduire la portée du rapport.",
  "msg_calculating_time_entries": "Calcul en cours de ~{{timeEntriesCount}} entrées de temps.",
  "lbl_just_a_few_seconds": "quelques secondes seulement",
  "lbl_less_than_a_min": "moins d’une minute",
  "lbl_about_a_min": "une minute environ",
  "msg_add_users_intro": "Utilisez cette page pour ajouter de nouvelles personnes ou mettre à jour les personnes existantes dans votre compte.",
  "msg_add_projects_intro": "Utilisez cette page pour ajouter de nouveaux projets ou mettre à jour les projets existants dans votre compte.",
  "msg_more_details_can_be_found": "Pour en savoir plus, consultez notre <0>rubrique d’aide</0>. Des questions ? <1>Contactez</1> notre équipe d’assistance.",
  "lbl_step_1_prepare_your_data": "1. Préparez vos données",
  "lbl_step_1_content_users": "Remarque : ne modifiez pas la colonne d’ID d’utilisateur, utilisée pour mettre en correspondance les mises à jour avec des personnes existantes.",
  "lbl_step_1_content_projects": "Remarque : ne modifiez pas la colonne d’ID de projet, utilisée pour mettre en correspondance les mises à jour avec les projets existants.",
  "lbl_step_1_contents_create_users": "Utilisez ce modèle pour ajouter de nouvelles personnes à votre compte.",
  "lbl_step_1_contents_create_projects": "Utilisez ce modèle pour ajouter de nouveaux projets à votre compte.",
  "lbl_step_1_contents_edit_users": "Exportez une liste de tous les collaborateurs de votre organisation pour mettre à jour leurs attributs",
  "lbl_step_1_contents_edit_projects": "Exportez une liste de tous les projets de votre organisation pour mettre à jour leurs attributs.",
  "lbl_people_lc": "personnes",
  "lbl_projects_lc": "projets",
  "lbl_add_update_projects": "Ajouter/mettre à jour des projets",
  "lbl_download_template": "Télécharger le modèle",
  "lbl_update_existing": "Mettre à jour les données existantes pour les",
  "lbl_export_project_list": "Exporter la liste des projets",
  "lbl_step_2_upload_data_users": "2. Chargez la liste mise à jour de vos collaborateurs",
  "lbl_step_2_upload_data_projects": "2. Chargez la liste mise à jour de vos projets",
  "msg_select_csv_file": "Sélectionnez et chargez votre fichier CSV mis à jour pour ajouter ou mettre à jour des {{importType}}. Un e-mail de confirmation sera envoyé une fois l’opération terminée.",
  "lbl_send_invitation_new_licensed_users": "Envoyez une invitation aux nouveaux utilisateurs sous licence.",
  "lbl_upload": "Charger",
  "lbl_processing": "En cours de traitement…",
  "lbl_add_new": "Ajouter un(e) autre {{importType}}",
  "msg_import_in_progress": "Importation en cours",
  "msg_errors_during_import": "Erreurs trouvées lors de l’importation",
  "lbl_import_complete": "Import terminé",
  "lbl_added_colon": "Ajouté(e) : {{number}}",
  "lbl_updated_colon": "Mis(e) à jour : {{number}}",
  "lbl_failed_colon": "Échec : {{number}}",
  "lbl_download_error_report_csv": "Télécharger le rapport d’erreur au format CSV",
  "msg_working_on_import_background": "Nous travaillons sur cette importation en arrière-plan. Vous recevrez un e-mail une fois l’importation terminée. Un lien vers cette page se trouve au bas de la page <0>{{pageTitle}}</0>.",
  "msg_rows_with_errors": "Les lignes comportant des erreurs n’ont pas été ajoutées ou mises à jour. Vous pouvez télécharger un rapport au format CSV contenant ces erreurs afin de les corriger et de réessayer. D’autres erreurs peuvent exister. Vérifiez toutes les données avant de les charger à nouveau. <0>En savoir plus</0> sur l’importation et la mise à jour des {{importType}}.",
  "msg_completed_processing": "Nous avons terminé de traiter les {{importType}} que vous avez récemment ajouté(e)s et mis à jour dans votre organisation.",
  "lbl_imports_last_30_days": "Importations des 30 derniers jours",
  "lbl_characters": "caractères ",
  "lbl_character": "caractère ",
  "lbl_remaining_lc": "restant",
  "msg_no_items_match_criteria": "Aucun élément ne correspond à vos critères",
  "lbl_future_scheduled_lc": "planifié ultérieurement",
  "lbl_loading_details": "Chargement des détails en cours…",
  "lbl_try_again": "Réessayer",
  "err_must_not_be_blank": "Ne doit pas être vide",
  "err_cannot_be_more_than_255_char": "Ne peut pas comporter plus de 255 caractères",
  "lbl_view_name": "Nom de la vue",
  "lbl_project_timelines": "Échéanciers de projet",
  "msg_shorten_time_frame": "Veuillez raccourcir le délai ou filtrer selon un nombre réduit de personnes ou de projets, puis essayez de recharger le rapport. Vous pouvez également demander à recevoir un e-mail contenant un lien vers ce rapport au format CSV.",
  "msg_underlying_data_requested": "Données sous-jacentes demandées. Nous vous enverrons un e-mail une fois que vous pourrez procéder au téléchargement. Cette opération peut prendre du temps.",
  "msg_no_report_values": "Aucune valeur pour ce rapport.",
  "msg_report_only_includes_data": "Ce rapport ne contient que les données que vous êtes autorisé à consulter.",
  "msg_we_have_logged_error": "Nous avons enregistré l’erreur et allons l’examiner. En attendant, essayez de repartir sur de nouvelles bases :",
  "msg_budgets_report_includes_all": "Ce rapport comprend toutes les données relatives aux dépenses effectuées, aux prévisions futures et au budget pour toute la durée des projets dont les dates se chevauchent :",
  "lbl_run_report": "Exécuter le rapport",
  "lbl_request_underlying_data": "Demander les données sous-jacentes",
  "lbl_selected": "Sélectionné(s)",
  "lbl_selected_lc": "sélectionné(e)(s)",
  "lbl_project_lc": "projet",
  "msg_change_client_name": "Cette action modifiera le client pour le(s)",
  "msg_change_project_type": "Cette action modifiera le type de projet pour le(s)",
  "msg_change_project_owner": "Cette action modifiera le propriétaire de projet pour le(s)",
  "msg_change_tags": "Cette action modifiera la ou les balises pour le(s)",
  "msg_change_custom_fields": "Cette action modifiera les champs personnalisés pour le(s)",
  "msg_this_will_archive_the": "Cette opération archivera le(s)",
  "msg_this_will_add_selected_tags": "Cette opération ajoutera la ou les balises sélectionnées",
  "msg_this_will_remove_selected_tags": "Cette opération supprimera la ou les balises sélectionnées",
  "msg_updating_client": "Mise à jour du client pour",
  "msg_updating_project_owner": "Mise à jour du propriétaire du projet pour",
  "msg_describe_work_item_placeholder": "Décrivez le travail, les résultats ou les livrables attendus",
  "msg_show_hide_phase_items": "Afficher/Masquer les éléments de travail de la phase {{phaseName}}",
  "msg_edit_phase": "Modifier la phase {{phaseName}}",
  "msg_show_hide_project_items": "Afficher/Masquer les éléments de travail du projet {{projectName}}",
  "msg_edit_project": "Modifier le projet {{projectName}}",
  "lbl_hours_lc": "heures",
  "lbl_hours_per_day_abbr": "h/j",
  "lbl_new": "Nouvelle",
  "lbl_add_team_member_to": "Ajouter un membre d’équipe à {{parentLabel}}",
  "lbl_add_work_item_to": "Ajouter un élément de travail à {{parentLabel}}",
  "lbl_available_hours_lc": "heures disponibles",
  "lbl_remaining_or_budget_forecast": "restant ou {{budgetForecastPercent}}",
  "lbl_forecasted_over_or_under_budget": "budget prévisionnel {{overUnder}} ou {{budgetForecastPercent}}",
  "lbl_under_lc": "d’avance",
  "lbl_over_lc": "de dépassement",
  "lbl_expense_budget_lc": "de budget de dépenses",
  "lbl_project_budget_lc": "budget du projet",
  "lbl_expensed_lc": "dépensé(s)",
  "lbl_incurred_lc": "facturé(e)s",
  "lbl_add_description": "Ajouter une description",
  "lbl_automatic_configuration": "Configuration automatique",
  "lbl_recommended_paren": "(Recommandé)",
  "lbl_manual_configuration": "Configuration manuelle",
  "lbl_manage_sheet_sharing": "Gérer le partage de feuilles",
  "lbl_cert_must_be_PEM": "Le certificat doit être codé en PEM (fichiers .pem, .cert, .crt, .cer)",
  "msg_auto_provision_auth_users": "Provisionner automatiquement les utilisateurs authentifiés qui ne sont pas dans le compte (créés avec l’e-mail uniquement)",
  "lbl_selected_phases": "Utiliser les phases sélectionnées",
  "lbl_no_display_props": "Aucune propriété à afficher",
  "msg_approval_audit_error": "Désolé… Nous avons rencontré un problème. Si le problème persiste, veuillez contacter l’assistance à l’adresse suivante ",
  "msg_download_error": "Une erreur s’est produite lors du téléchargement du fichier : ",
  "lbl_audit_log_req": "Journal d’audit demandé",
  "msg_audit_log_req": "Un lien de téléchargement apparaîtra dans le tableau des demandes récentes ci-dessous. Cela peut prendre quelques minutes.",
  "lbl_audit_log_expense": "Journaux d’audit des dépenses",
  "lbl_download": "Télécharger",
  "lbl_proj_singular": "projet a été",
  "lbl_proj_plural": "projets ont été",
  "lbl_zero_proj": "0 projet",
  "lbl_success_lc": "réussi",
  "lbl_important": "<1>Important :</1>",
  "lbl_edit_properties": "Modifier les propriétés",
  "lbl_error": "Erreur",
  "lbl_change": "Changer",
  "lbl_select_new": "Sélectionner un nouveau",
  "lbl_approver_project": "Approbateur : ",
  "lbl_successfully": " avec succès.",
  "msg_update": "Actualisation terminée",
  "lbl_of": "sur",
  "msg_update_failed": "Échec des mises à jour.",
  "msg_update_lc": "mis à jour avec succès.",
  "lbl_edit_desc": "Modifier la description",
  "lbl_no_select": "aucune sélection",
  "lbl_current_filter_select": " : défini actuellement sur ",
  "msg_getting_ready": "Préparation en cours…",
  "msg_retry_update": "Cliquez sur Réessayer pour corriger les problèmes de mise à jour.",
  "msg_error_support": "Si cette erreur persiste, contactez l’assistance. ",
  "lbl_curr_dropdown_val": "La valeur actuelle de la liste déroulante est ",
  "msg_cancel_view": "Annuler et revenir à la liste d’affichage",
  "msg_updating_tags": "Mise à jour des balises pour",
  "msg_updatings_proj_type": "Mise à jour du type de projet pour",
  "msg_start_your_trial_for_free": "Commencez votre essai gratuit de {{trialDuration}} jours",
  "msg_create_free_account": "Créer un compte gratuit",
  "msg_I_agree_to_terms_and_conditions": "J’accepte les <0>modalités</0> et que Resource Management by Smartsheet traite mes données à caractère personnel conformément à la <1>politique de confidentialité</1>",
  "msg_something_went_wrong_signup": "Oups ! Une erreur s’est produite lors de votre inscription. Contactez-nous via notre <0>page d’assistance</0>",
  "msg_edit_phase_settings": "Modifier les paramètres de la phase",
  "msg_edit_project_settings": "Modifier les paramètres du projet",
  "msg_shift_phase_dates": "Décaler les dates de la phase",
  "msg_shift_project_dates": "Décaler les dates du projet",
  "lbl_utilization_lc": "utilisation",
  "lbl_target_utilization_lc": "objectif d’utilisation",
  "msg_organization_using_smar_auth_modifification_info": "Votre organisation utilise actuellement l’authentification Smartsheet pour se connecter à Resource Management.",
  "lbl_user_auto_provisioning": "Provisionnement automatique des utilisateurs",
  "lbl_edit": "Modifier",
  "lbl_permission_level": "Niveau de permission",
  "msg_activate_smar_auth_info": "Activez cette option pour autoriser les utilisateurs à se connecter avec leur compte Smartsheet. <1>En savoir plus</1>.",
  "lbl_activate_smar_auth": "Activer l’authentification Smartsheet",
  "msg_please_link_rm_account_with_smar": "Pour autoriser l’authentification Smartsheet, <1>associez votre compte Resource Management à votre compte Smartsheet.</1>",
  "lbl_setup_sso": "Configurer l’authentification unique (SSO)",
  "lbl_smartsheet_authentication": "Authentification Smartsheet",
  "lbl_edit_sso": "Modifier les paramètres d’authentification unique",
  "msg_disabled_filter_info": "Cette option n’est pas disponible pour les rapports avancés. Créez un <0>nouveau rapport</0> pour accéder à cette option.",
  "msg_people_in_org_have_logged_in_using_sso": "Les collaborateurs au sein de votre organisation utilisent déjà l’authentification unique (SSO) pour se connecter. Si vous ne modifiez pas vos paramètres SSO correctement, les utilisateurs risquent de ne pas pouvoir se connecter. Pour mettre à jour vos paramètres SSO en toute sécurité, <1>contactez-nous</1> pour obtenir de l’aide.",
  "err_issue_enabling_sso": "Un problème est survenu lors de l’activation de l’authentification unique : {{error}}. Si ce problème persiste, veuillez contacter l’assistance.",
  "err_activating_smar_auth": "Erreur d’activation de l’authentification Smartsheet",
  "err_issue_updating_smar_auth": "Un problème est survenu lors de la mise à jour de l’authentification Smartsheet : {{error}}. Si ce problème persiste, veuillez contacter l’assistance.",
  "err_issue_enabling_smar_auth": "Un problème est survenu lors de l’activation de l’authentification Smartsheet : {{error}}. Si ce problème persiste, veuillez contacter l’assistance.",
  "msg_report_unmanaged_resources_admin": "Pour inclure les ressources non gérées dans ce rapport, mettez-les à niveau dans les <0>Paramètres du compte</0>.",
  "msg_report_unmanaged_resources": "Pour inclure les ressources non gérées dans ce rapport, demandez à votre administrateur de les mettre à niveau dans les Paramètres du compte.",
  "lbl_adjust_refresh_settings": "Ajuster les paramètres d’actualisation des données",
  "lbl_view_advanced_report": "Afficher le rapport avancé",
  "lbl_save_and_refresh_data": "Enregistrer les modifications et actualiser les données",
  "lbl_refresh_data": "Actualiser les données",
  "lbl_delete_report": "Supprimer le rapport",
  "lbl_toggle_reports_control_menu": "Activer/désactiver le menu de contrôle des rapports",
  "lbl_advanced_report": "Rapport avancé",
  "lbl_create_new_advanced_report": "Créer un nouveau rapport avancé",
  "msg_open_the_advanced_report_in_smartsheet": "Ouvrir le rapport avancé dans Smartsheet",
  "lbl_advanced_report_edit_modal_title": "Paramètres d’actualisation des données",
  "lbl_advanced_report_modal_title": "Créer un rapport avancé",
  "msg_advanced_report_modal_description": "Envoyez vos données vers une feuille source et un rapport Smartsheet. Vous pouvez mettre à jour le rapport et ajuster les paramètres à partir de la page Rapports.",
  "lbl_advanced_report_modal_name_field": "Nom",
  "lbl_advanced_report_modal_destination_field": "Destination",
  "lbl_advanced_report_modal_data_refresh_field": "Paramètre d’actualisation des données",
  "lbl_advanced_report_modal_data_refresh_auto_name": "Automatique",
  "lbl_advanced_report_modal_data_refresh_auto_description": "Les données avancées du rapport sont actualisées toutes les 24 heures",
  "lbl_advanced_report_modal_data_refresh_manual_name": "Manuel",
  "lbl_advanced_report_modal_data_refresh_manual_description": "Les données doivent être actualisées manuellement depuis la page Rapport",
  "lbl_advanced_report_modal_close_button": "Fermer",
  "lbl_advanced_report_modal_create_button": "Créer",
  "msg_advanced_report_modal_need_lws": "Demandez à votre administrateur Resource Management d’activer l’authentification Smartsheet pour utiliser cette fonctionnalité.",
  "lbl_destination_dropdown_select_workspace": "Sélectionner un espace de travail",
  "lbl_destination_dropdown_search_for_workspace": "Rechercher un espace de travail",
  "msg_advanced_report_ready_title": "Votre rapport avancé est prêt !",
  "msg_advanced_report_ready": "Vous pouvez toujours accéder au statut de votre rapport avancé et le consulter à partir de la page Rapport",
  "lbl_advanced_report_view_btn": "Afficher le rapport avancé",
  "msg_advanced_report_creating_title": "Création de votre rapport avancé",
  "msg_advanced_report_creating": "Cela peut prendre quelques minutes. Vous pouvez obtenir un aperçu du rapport avec des exemples de données dès maintenant, et le mettre à jour avec vos données réelles une fois prêt.",
  "lbl_advanced_report_preview_btn": "Aperçu du rapport…",
  "msg_advanced_report_creation_footer": "Vous pouvez fermer le rapport en toute sécurité. Lorsque vous souhaiterez y revenir, vous pourrez le retrouver dans vos rapports enregistrés ou dans l’espace de travail sélectionné.",
  "lbl_advanced_report_creation_footer_link": "Découvrez des conseils sur les rapports avancés.",
  "lbl_create_advanced_report": "Créer un rapport avancé",
  "lbl_edit_settings": "Modifier les paramètres du rapport",
  "err_create_report_failed_generic": "Une erreur est survenue lors de la création de votre rapport. Veuillez réessayer.",
  "err_edit_report_failed_generic": "Une erreur est survenue lors de la modification de votre rapport. Veuillez réessayer.",
  "err_create_report_failed_invalid_name": "Le nom du rapport ne doit contenir aucun caractère spécial ni émoji",
  "msg_session_expired_title": "Votre session a expiré",
  "msg_session_expired_description": "Votre session dans cette fenêtre n’est plus valide. Cliquez sur le bouton ci-dessous pour actualiser la page. Vous devrez peut-être vous reconnecter.",
  "lbl_refresh_page": "Actualiser la page",
  "msg_refresh_advanced_report": "Actualisez les données à partir du menu pour appliquer vos filtres au rapport avancé. Votre rapport RM enregistré sera également mis à jour.",
  "msg_advanced_report_too_many_cells": "Ce rapport n’a pas pu être actualisé car il comporte 500 000 cellules ou plus. Veuillez ajuster les paramètres dans le panneau de gauche pour réduire le nombre de cellules. <0>En savoir plus</0>",
  "msg_advanced_report_too_many_columns": "Ce rapport n’a pas pu être actualisé car il comporte 400 colonnes ou plus. Veuillez ajuster les paramètres dans le panneau de gauche pour réduire le nombre de colonnes. <0>En savoir plus</0>",
  "msg_advanced_report_too_many_rows": "Ce rapport n’a pas pu être actualisé car il comporte 20 000 lignes ou plus. Veuillez ajuster les paramètres dans le panneau de gauche pour réduire le nombre de lignes. <0>En savoir plus</0>",
  "msg_advanced_report_auto_refresh_disabled": "Si le paramètre d’actualisation de ce rapport est défini sur Automatique, il passe sur Manuel pour éviter tout nouvel échec de l’actualisation.",
  "lbl_link_to_smartsheet": "Lien vers Smartsheet",
  "msg_link_to_smartsheet_tooltip": "Demandez à votre administrateur Resource Management d’activer l’authentification Smartsheet pour utiliser cette fonctionnalité. <0>En savoir plus</0>",
  "msg_new_link_to_smartsheet": "Créez une nouvelle feuille liée avec toutes les données de votre projet Resource Management.",
  "msg_connect_to_smartsheet": "La connexion de votre projet créera une nouvelle feuille connectée avec vos données de projet existantes pour vous puissiez tirer parti des puissantes fonctionnalités de gestion de projet de Smartsheet. <0>En savoir plus</0>",
  "lbl_connect": "Connecter",
  "lbl_add_phase_col": "Ajouter une colonne de phase",
  "err_connect_sheet_to_proj_error": "Désolé, nous n’avons pas pu connecter le projet. Veuillez réessayer ultérieurement.",
  "msg_project_has_phases": "Votre projet comporte déjà des phases. Elles seront ajoutées à votre nouvelle feuille connectée.",
  "msg_adding_phases": "L’ajout d’une colonne de phase vous permettra de diviser votre projet en différentes phases ou étapes. <0>En savoir plus</0>",
  "msg_connecting_proj_to_sheet": "Connexion de votre projet à une feuille",
  "msg_be_patient": "Veuillez patienter, cela peut prendre quelques minutes.",
  "msg_safe_to_close": "Vous pouvez fermer le rapport en toute sécurité. Vous pouvez revenir vérifier à tout moment le statut de connexion de votre projet.",
  "lbl_open_sheet": "Ouvrir la feuille",
  "lbl_sheet_connected": "Votre feuille connectée est prête !",
  "msg_sheet_connected": "Vous pouvez toujours trouver un lien vers votre feuille connectée dans le brief du projet.",
  "msg_enforce_sign_in_with_smartsheet_title": "Vous pouvez désormais vous connecter via Smartsheet",
  "msg_enforce_sign_in_with_smartsheet_heading": "La méthode de connexion de votre organisation a été mise à jour.",
  "msg_enforce_sign_in_with_smartsheet_text": "Vous pouvez désormais vous connecter à l’aide de l’authentification Smartsheet, un moyen fluide d’accéder à Resource Management dans Smartsheet et à de nouvelles fonctionnalités telles que la création de rapports avancés. <0>En savoir plus</0>",
  "msg_enforce_sign_in_with_smartsheet_create_account_text": "Utilisez votre adresse e-mail ou <0>créez un compte Smartsheet</0> si vous n’en avez pas déjà un.",
  "msg_enforce_sign_in_with_smartsheet_skip_for_now": "Ignorer pour le moment",
  "msg_enforce_sign_in_with_smartsheet_sign_in_with_smartsheet": "Connexion via Smartsheet",
  "lbl_arms_discovery_modal_title": "Resource Management a déménagé !",
  "lbl_arms_discovery_modal_lws_text": "Resource Management est désormais disponible dans la barre de navigation de Smartsheet. Gérez des équipes, suivez les capacités et affectez les bonnes personnes au travail, le tout en un seul endroit.",
  "lbl_arms_discovery_modal_lws_show_button": "En savoir plus",
  "lbl_arms_discovery_modal_lws_close_button": "Peut-être plus tard",
  "lbl_arms_discovery_modal_non_lws_text": "Resource Management est désormais disponible dans la barre de navigation de Smartsheet. Demandez à votre administrateur de connecter Resource Management à Smartsheet pour activer l’authentification Smartsheet.",
  "lbl_arms_discovery_modal_non_lws_close_button": "Bien reçu"
}